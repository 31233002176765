<template>
    <div class="htlb">
        <div class="htlb_top">
            <div class="left">
                <div class="left_top">
                    <el-card style="width: 200px;height: 200px;">
                        <div class="box1">历史派单总数（单）</div>
                        <div class="p1">{{ topData.total }} </div>
                    </el-card>
                    <el-card style="width: 250px;height: 200px;">
                        <div class="top_box1">今日派单数量（单）</div>
                        <div class="top_p1" @click="goKhgl" style="cursor:pointer ;">{{ topData.today }} <span>4%↑</span>
                        </div>
                        <div class="hr"></div>
                        <div class="bottom_box1">昨日派单数量（单）</div>
                        <div class="bottom_p1">{{ topData.yesterday }}</div>
                    </el-card>
                    <el-card style="width: 250px;height: 200px;">
                        <div class="top_box1">本周派单数量（单）</div>
                        <div class="top_p1" @click="goKhgl2" style="cursor:pointer ;">{{ topData.week }} <span>4%↑</span>
                        </div>
                        <div class="hr"></div>
                        <div class="bottom_box1">上周派单数量（单）</div>
                        <div class="bottom_p1">{{ topData.last_week }}</div>
                    </el-card>
                    <el-card style="width: 250px;height: 200px;">
                        <div class="top_box1">本月派单数量（单）</div>
                        <div class="top_p1" @click="goKhgl3" style="cursor:pointer ;">{{ topData.month }} <span>4%↑</span>
                        </div>
                        <div class="hr"></div>
                        <div class="bottom_box1">上月派单数量（单）</div>
                        <div class="bottom_p1">{{ topData.last_month }}</div>
                    </el-card>
                </div>
                <div class="left_center1">
                    <el-card>
                        <div>
                            <h4>接单部门占比</h4>
                        </div>
                        <div id="bing"></div>
                        <el-date-picker v-model="value2" value-format="yyyy-MM-dd" type="month" placeholder="选择日期"
                            @change="getDataTime()">
                        </el-date-picker>
                    </el-card>
                </div>
                <div class="left_center2">
                    <el-card>
                        <h4>全年派单趋势</h4>
                        <div id="zhe1"></div>
                    </el-card>
                </div>
            </div>
            <div class="right">
                <el-card>
                    <h4 style="margin-bottom: 10px;">本月接单人数量占比</h4>
                    <div class="right_btn">
                        <div :class="typeIndex == index ? 'type1' : 'type'" v-for=" (item, index) in areaInfo" :key="index">
                            <div @click="changeType(item.id, index)">{{ item.name }}</div>
                        </div>
                    </div>
                    <el-table :data="tableData" style="width: 100%">
                        <el-table-column label="姓名">
                            <template slot-scope="scope">
                                <el-popover trigger="hover" placement="top">
                                    <p>{{ scope.row.nickname }}</p>
                                    <div slot="reference" class="name-wrapper">
                                        {{ scope.row.nickname }}
                                    </div>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column label="所属部门">
                            <template slot-scope="scope">
                                <el-popover trigger="hover" placement="top">
                                    <p>{{ scope.row.bumen }}</p>
                                    <div slot="reference" class="name-wrapper">
                                        {{ scope.row.bumen }}
                                    </div>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column label="接单数量">
                            <template slot-scope="scope">
                                <el-popover trigger="hover" placement="top">
                                    <p>{{ scope.row.jiedian_num }}</p>
                                    <div slot="reference" class="name-wrapper">
                                        {{ scope.row.jiedian_num }}
                                    </div>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column label="占比">
                            <template slot-scope="scope">
                                <el-popover trigger="hover" placement="top">
                                    <p>{{ scope.row.zhanbi }}</p>
                                    <div slot="reference" class="name-wrapper">
                                        {{ scope.row.zhanbi }}
                                    </div>
                                </el-popover>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </div>
        </div>
        <div class="htlb_bottom">
            <el-card>
                <div class="line_bottom">
                    <select name="" id="">
                        <option value="">月派单趋势(各地区)</option>
                        <option value="">月派单趋势(总和)</option>
                    </select>
                    <div class="block">
                        <el-date-picker v-model="value3" type="month" value-format="yyyy-MM-dd" placeholder="选择日期"
                            @change="getDataTime2()">
                        </el-date-picker>
                    </div>
                </div>
                <!-- <line-chart :chart-data="lineChartData" /> -->
                <div id="bottom_line"></div>
            </el-card>
        </div>

    </div>
</template>

<script>
// import LineChart from './components/LineChart.vue'
// import LineChart from './components/LineChart.vue'
import { getdashboard, yykb_list } from '../../api/yunyingguanli/yykb'
import { department, } from '../../api/renyuanguanli/bumenlist';
import { getAreaPersonInfo } from '../../api/jishu/jishu';
export default {
    // components: {
    //     LineChart,
    // },
    data() {
        return {
            tableData: [],
            value: '',
            value2: '',
            typeIndex: 0,
            AreaID: 0,
            value3: '',
            areaInfo: [],
            topData: [],
            centerData: [],
            createtimeArr: [],
            totalArr: [],
            pieData: [],
            bottomBJData: [],


            szbottomCreatetime: [],
            szbottomTotal: [],

            hzbottomCreatetime: [],
            hzbottomTotal: [],

            hnbottomCreatetime: [],
            hnbottomTotal: []


        }
    },
    mounted() {
        this.getData()
        this.getdepartmentdata()
        this.typeIndex = 0
        // this.getdepartmentdataInfo()
        // this.myEchartsBottom()
    },
    methods: {
        // 跳转到客户列表
        goKhgl() {
            this.$router.push({
                path: `/Index/Khlb`,
                query: {
                    type: 1
                }
            })
        },
        // 跳转到客户列表
        goKhgl2() {
            this.$router.push({
                path: `/Index/Khlb`,
                query: {
                    type: 2
                }
            })
        },
        // 跳转到客户列表
        goKhgl3() {
            this.$router.push({
                path: `/Index/Khlb`,
                query: {
                    type: 3
                }
            })
        },
        // 获取各部门地区
        async getdepartmentdata() {
            let reqData = {
                id: 0
            }
            let res = await department(reqData)
            console.log(res);
            this.areaInfo = res.data.data
            console.log(this.areaInfo);
            // console.log(res.data.data[3].id);
            // this.getData(res.data.data[3].id)
        },
        changeType(id, index) {
            console.log(id);
            this.AreaID = id
            this.typeIndex = index
            this.getdepartmentdataInfo()
        },
        myEchartsbing() {
            var chartDom = document.getElementById('bing');
            var myChart = this.$echarts.init(chartDom);
            var option;
            option = {
                title: {
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        type: 'pie',
                        radius: '50%',
                        data: this.pieData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };

            option && myChart.setOption(option);

        },
        myEchartszhe1() {
            console.log(111);
            var chartDom = document.getElementById('zhe1');
            var myChart = this.$echarts.init(chartDom);
            var option;
            console.log(this.totalArr);
            option = {
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.createtimeArr
                },
                yAxis: {
                    type: 'value',

                },
                series: [
                    {
                        data: this.totalArr,
                        type: 'line',
                        areaStyle: {}
                    }
                ]
            };


            option && myChart.setOption(option);
        },
        myEchartsBottom() {
            var chartDom = document.getElementById('bottom_line');
            var myChart = this.$echarts.init(chartDom);
            var option;
            const colors = ['#5470C6', '#EE6666', ' #66FF99', ' #66CC00'];
            option = {
                color: colors,
                tooltip: {
                    trigger: 'none',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                legend: {},
                grid: {
                    top: 70,
                    bottom: 50
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            onZero: false,
                            lineStyle: {
                                color: colors[1]
                            }
                        },
                        axisPointer: {
                            label: {
                                formatter: function (params) {
                                    return (
                                        'Precipitation  ' +
                                        params.value +
                                        (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                                    );
                                }
                            }
                        },
                        // prettier-ignore
                        data: ['1号', '2号', '3号', '4号', '5号', '6号', '7号', '8号', '9号', '10号', '11号', '12号', '13号', '14号', '15号', '16号', '17号', '18号', '19号', '20号', '21号', '22号', '23号', '24号', '25号', '26号', '27号', '28号', '29号', '30号'],
                    },
                    {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            onZero: false,
                            lineStyle: {
                                color: colors[0]
                            }
                        },
                        axisPointer: {
                            label: {
                                formatter: function (params) {
                                    return (
                                        'Precipitation  ' +
                                        params.value +
                                        (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                                    );
                                }
                            }
                        },
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '北京云蓬',
                        type: 'line',
                        xAxisIndex: 1,
                        smooth: true,
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.bottomTotal
                    },
                    {
                        name: '深圳云蓬',
                        type: 'line',
                        smooth: true,
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.szbottomTotal
                    },
                    {
                        name: '杭州云蓬',
                        type: 'line',
                        smooth: true,
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.hzbottomTotal
                    },
                    {
                        name: '郑州云蓬',
                        type: 'line',
                        smooth: true,
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.hnbottomTotal
                    }
                ]
            };

            option && myChart.setOption(option);
        },
        // 获取数据
        async getData() {
            let reqData = {
                dis: 1,
                catchArea: this.AreaID,
                dayMonth: this.value2,
                distribute: this.value3,
                aid: ''
            }
            let res = await getdashboard(reqData)
            console.log(res);
            if (res.data.code == 5) {
                this.$message({
                    type: 'error',
                    message: '暂无权限访问!'
                });
                this.$router.push({ path: "/Index/kongbai" });
            }
            this.topData = res.data.data
            this.centerData = res.data.data.distribute
            console.log(this.centerData);
            // 获取全年派单趋势折线图的数据
            let createtimeNewArr = []
            let totalNewArr = []
            for (var i = 0; i < this.centerData.length; i++) {
                createtimeNewArr.push(this.centerData[i].createtimes)
                totalNewArr.push(this.centerData[i].total)
            }
            this.createtimeArr = createtimeNewArr
            this.totalArr = totalNewArr
            // 获取饼状图数据
            let arr = res.data.data.departmentCatch
            console.log(arr);
            arr.forEach((item, index) => {
                this.pieData[index] = {
                    name: item.name,
                    value: item.count
                }
            })
            console.log(this.pieData);
            // 获取最下面折线图数据
            // 北京
            this.bottomBJData = res.data.data.dayTrend.bj
            let bottomNewCreatetime = []
            let bottomNewTotal = []
            this.bottomBJData.forEach((item, index) => {
                bottomNewCreatetime.push(item.createtimes)
                bottomNewTotal.push(item.total)
            })
            this.bottomCreatetime = bottomNewCreatetime
            this.bottomTotal = bottomNewTotal
            console.log(this.bottomTotal);
            // 深圳
            let bottomSZData = res.data.data.dayTrend.sz
            console.log('哈哈哈哈', bottomSZData);
            let szbottomNewCreatetime = []
            let szbottomNewTotal = []
            bottomSZData.forEach((item, index) => {
                szbottomNewCreatetime.push(item.createtimes)
                szbottomNewTotal.push(item.total)
            })
            this.szbottomCreatetime = szbottomNewCreatetime
            this.szbottomTotal = szbottomNewTotal
            // 杭州
            let hzbottomHZData = res.data.data.dayTrend.hz
            console.log('哈哈哈哈', bottomSZData);
            let hzbottomNewCreatetime = []
            let hzbottomNewTotal = []
            hzbottomHZData.forEach((item, index) => {
                hzbottomNewCreatetime.push(item.createtimes)
                hzbottomNewTotal.push(item.total)
            })
            this.hzbottomCreatetime = hzbottomNewCreatetime
            this.hzbottomTotal = hzbottomNewTotal
            // 河南
            let bottomHNData = res.data.data.dayTrend.hn


            console.log('哈哈哈哈', bottomSZData);
            let hnbottomNewCreatetime = []
            let hnbottomNewTotal = []
            bottomHNData.forEach((item, index) => {
                hnbottomNewCreatetime.push(item.createtimes)
                hnbottomNewTotal.push(item.total)
            })
            this.hnbottomCreatetime = hnbottomNewCreatetime
            this.hnbottomTotal = hnbottomNewTotal

            console.log(bottomHNData);
            this.myEchartszhe1()
            this.myEchartsbing()
            this.myEchartsBottom()
        },
        // 选择日期获取饼状图数据
        getDataTime() {
            console.log(this.value2);
            this.getData()
        },
        // 选择日期获取折线图数据
        getDataTime2() {
            console.log(this.value3);
            this.getData()
        },
        // 获取部门数据
        async getdepartmentdataInfo() {
            let reqData = {
                page: 1,
                department_id: this.AreaID,
                times: 1
            }
            let res = await yykb_list(reqData)
            console.log(res);
            this.tableData = res.data.data
            console.log(this.tableData);
        }
    }
}
</script>

<style lang="less" scoped>
.box-card {
    display: flex;
    justify-content: space-between;
}

.el-card__body {
    padding: 0 10px;
}

.card_div {
    display: flex;
    justify-content: space-around;
    text-align: right;

    p {
        padding: 0 10px;
    }
}

.block {
    text-align: right;
    line-height: 78px;
}

.htlb {
    overflow-y: auto;
    height: 877px;
}

.card_top {
    display: flex;
    justify-content: space-between;

    p {
        margin: 0 20px;

        span {
            padding-right: 17px;
        }
    }
}

.card_bottom {
    display: flex;

    p {
        margin: 10px 25px;

        span {
            padding-right: 10px;
        }
    }
}

.name-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
}

/deep/.el-table th.el-table__cell>.cell,
.el-table th.el-table__cell>.cell {
    font-size: 12px;
}

.el-checkbox__label {
    font-size: 12px;
}

.htlb_top {
    display: flex;
    justify-content: space-between;

    .left {
        width: 1100px;

        .left_top {
            display: flex;
            justify-content: space-between;
        }

        .left_center1 {
            margin-top: 20px;

            /deep/.el-card__body {
                display: flex;
                justify-content: space-between;
            }
        }

        .left_center2 {
            margin-top: 20px;
        }
    }

    .left_top .el-card:nth-child(1) {
        width: 292px;
        height: 210px;
        background: linear-gradient(90deg, #FF9FBD 0%, #FF6F93 100%);
        box-shadow: 0px 20px 30px -10px rgba(255, 113, 149, 0.5);
        border-radius: 10px 10px 10px 10px;
        opacity: 1;

        .box1 {
            width: 162px;
            height: 24px;
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 21px;
            -webkit-background-clip: text;
            margin-top: 28px;
            text-align: center;
            margin-top: 50px;
            margin-left: 15px;
        }

        .p1 {
            width: 141px;
            height: 63px;
            font-size: 48px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 56px;
            -webkit-background-clip: text;
            margin-left: 15px;
        }
    }

    .left_top .el-card {
        width: 292px;
        height: 210px;
        background: linear-gradient(90deg, #D4AAFF 0%, #B47CFF 100%);
        box-shadow: 0px 20px 30px -10px rgba(181, 126, 255, 0.5);
        border-radius: 10px 10px 10px 10px;
        opacity: 1;

        .top_box1 {
            width: 162px;
            height: 24px;
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 21px;
            -webkit-background-clip: text;
        }

        .hr {
            width: 220px;
            height: 0px;
            opacity: 1;
            margin-left: 5px;
            border: 1px solid rgba(255, 255, 255, 0.3);
        }

        .top_p1 {
            width: 141px;
            height: 63px;
            font-size: 48px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 56px;
            -webkit-background-clip: text;
            margin-left: 35px;
            position: relative;

            span {
                width: 36px;
                height: 24px;
                font-size: 18px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 21px;
                -webkit-background-clip: text;
                position: absolute;
                top: 30px;
                right: -50px;
            }
        }

        .bottom_box1 {
            width: 162px;
            height: 24px;
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 21px;
            -webkit-background-clip: text;
            margin-top: 10px;
        }

        .bottom_p1 {
            width: 141px;
            height: 63px;
            font-size: 48px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 56px;
            margin-left: 35px;
        }
    }

    .left_top .el-card:nth-child(3) {
        width: 292px;
        height: 210px;
        background: linear-gradient(90deg, #84DFBC 0%, #57C694 100%);
        box-shadow: 0px 20px 30px -10px rgba(89, 199, 150, 0.5);
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
    }

    .left_top .el-card:nth-child(4) {
        width: 292px;
        height: 210px;
        background: linear-gradient(90deg, #E5DE65 0%, #E4C748 100%);
        box-shadow: 0px 20px 30px -10px rgba(228, 200, 73, 0.5);
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
    }

    .right {
        width: 550px;
        margin-right: 20px;

        /deep/.el-card__body {
            display: flex;
            flex-direction: column;
        }
    }
}

.el-date-editor.el-input {
    width: 120px;
}

#bing {
    width: 600px;
    height: 200px;
}

#zhe1 {
    width: 1100px;
    height: 250px;
}

.htlb_bottom {
    margin-top: 20px;

    select {
        border: none;
    }

    .line_bottom {
        display: flex;
        justify-content: space-between;
    }
}

.el-date-editor.el-input[data-v-658c7b26] {
    height: 40px;
}

.type {
    width: 24%;
    height: 100%;
    text-align: center;
    line-height: 40px;
    background-color: #ecf5ff;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
}

.type1 {
    background-color: #409EFF;
    width: 24%;
    height: 100%;
    text-align: center;
    line-height: 40px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
}

.right_btn {
    // width: 300px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#bottom_line {
    width: 100%;
    height: 600px;
    border: 1px solid red;
}
</style>