import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        tabNav: [[0, 0]]
    },
    mutations: {
        ADD_NAV(state, data) {
            state.tabNav.push(data);
        }
    },
    actions: {},
    modules: {}
})
