<template>
    <div style="z-index: 10000;margin-right: 20px;">
        <div class="card_address">
            <div class="select_area">选择地区</div>
            <div :class="typeIndex == index ? 'type1' : 'type'" v-for=" (item, index) in areaInfo" :key="index">
                <div @click="changeType(item.id, index)">{{ item.name }}</div>
            </div>
        </div>
        <el-card class="box-card" style="margin-top: 20px;">
            <div style="display: flex;">
                <p><el-input placeholder="请输入客户名称或联系方式" v-model="input2" class="input-with-select">
                        <el-button slot="append" icon="el-icon-search" @click="searchBtnInfo()"></el-button>
                    </el-input></p>
                <p><el-button type="primary" style="margin-left: 10px;" @click="clearinput()">重置</el-button></p>
            </div>
            <div class="card_div">
                <p><el-button type="primary" plain size="medium">导入</el-button></p>
                <p><el-button type="primary" plain size="medium">导出</el-button></p>
                <p><el-button type="primary" plain size="medium" @click="centerDialogVisible = true">顺序规则</el-button>
                </p>
                <p><el-button type="primary" plain size="medium" @click="addcenterDialogVisible = true">添加用户</el-button>
                </p>
                <p><el-button plain size="medium" class="scxz" :disabled="multipleSelection.length == 0"
                        @click="deleteData">删除选中</el-button>
                </p>
            </div>
        </el-card>
        <el-card>
            <div style="padding:10px;" class="card_name">
                <div id="card_left" :style="{ 'max-height': status ? textHeight : '' }" :class="{ statusText: status }"
                    class="titleText" ref="desc">
                    <div class="left_top">
                        <p><span>客户筛选</span>
                            <el-select v-model="filter" placeholder="请选择" value-key="filter" @change="selectFilter">
                                <el-option v-for="item in options1" :key="item.filter" :label="item.name"
                                    :value="item.filter">
                                </el-option>
                            </el-select>
                        </p>
                        <p><span>客户级别</span>
                            <el-select v-model="level" placeholder="请选择" value-key="filter" @change="selectLevel">
                                <el-option v-for="item in options2" :key="item.level" :label="item.name1"
                                    :value="item.level">
                                </el-option>
                            </el-select>
                        </p>
                        <p><span>项目类型</span>
                            <el-select v-model="project_type" placeholder="请选择" value-key="id" @change="selectproject_type">
                                <el-option v-for="item in options3" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </p>
                        <p><span>客户来源</span>
                            <el-select v-model="from" placeholder="请选择" value-key="id" @change="selectfrom">
                                <el-option v-for="item in options4" :key="item.from" :label="item.name" :value="item.from">
                                </el-option>
                            </el-select>
                        </p>
                    </div>
                    <div class="left_bottom">
                        <p><span>&emsp;创建人</span>
                            <el-select v-model="creator" placeholder="请选择" value-key="id" @change="selectcreator">
                                <el-option v-for="item in options5" :key="item.id" :label="item.nickname" :value="item.id">
                                </el-option>
                            </el-select>
                        </p>
                        <p><span>创建时间</span>
                            <el-date-picker v-model="createTime" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels @change="getDate1()"
                                value-format="timestamp" :picker-options="{
                                        disabledDate(time) { // 设置禁用状态，参数为当前日期，要求返回 Boolean
                                            return time.getTime() > Date.now();
                                        }
                                    }">
                            </el-date-picker>
                        </p>
                    </div>

                </div>
                <div id="card_right" v-if="idShowText" @click="status = !status" :class="{ openSpan: status }"
                    class="openClose">
                    {{ status ? "展开" : "收起" }}
                    {{ status ? '﹀' : '︿' }}
                </div>
            </div>
        </el-card>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;"
            @selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }">
            <el-table-column type="selection" width="45" align="center">
            </el-table-column>
            <el-table-column label="客户名称" width="120" align="center">
                <template slot-scope="scope">
                    <!-- <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.name }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.name }}
                        </div>
                    </el-popover> -->
                    {{ scope.row.name }}
                </template>
            </el-table-column>
            <el-table-column label="联系方式" width="140" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.mobile }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.mobile }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="微信/QQ" width="140" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.third_contact }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.third_contact }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="客户级别" width="140" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.level }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.level }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="项目类型" width="140" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.project_name }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.project_name }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="客户来源" width="140" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p v-if="scope.row.from == 1">淘宝</p>
                        <p v-if="scope.row.from == 2">百度</p>
                        <div slot="reference" class="name-wrapper" v-if="scope.row.from == 1">
                            淘宝
                        </div>
                        <div slot="reference" class="name-wrapper" v-if="scope.row.from == 2">
                            百度
                        </div>
                        <div slot="reference" class="name-wrapper" v-if="scope.row.from == 11">
                            百度
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="创建人" width="140" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.admin_nickname }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.admin_nickname }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" width="140" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.createtime }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.createtime }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="派单时间" width="140" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.updatetime }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.updatetime }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="备注" width="140" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.remark }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.remark }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" @click="editData(scope.row)">编辑</el-button>
                    <el-button size="mini" @click="sxpd(scope.row)" v-if="scope.row.distribute == null">顺序派单</el-button>
                    <el-button size="mini" @click="zp(scope.row)" v-if="scope.row.distribute == null">指派</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[15, 30]" :page-size="15" layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
        </div>
        <!-- 顺序规则弹出框 -->
        <div class="sx_dialog">
            <el-dialog title="顺序派单规则" :visible.sync="centerDialogVisible" width="454px" center append-to-body
                @close="sxgzValue = ''">
                <p>规则1</p>
                <i class="el-icon-warning-outline"></i>
                <span style="line-height: 50px;">订单按商务接单排名进行顺序派发，每天
                    <el-time-select style="width: 135px;" v-model="sxgzValue" :picker-options="{
                            start: '06:00',
                            step: '00:30',
                            end: '23:30'
                        }" placeholder="请选择时间" @change="getDate4()">
                    </el-time-select>
                    过后,下一单自动派发给排名为1的商务,之后再按之前顺序进行派发
                </span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="centerDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="sxgz()">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 添加用户弹出框 -->
        <div class="tj_dialog">
            <el-dialog title="添加客户" :visible.sync="addcenterDialogVisible" width="750px" center append-to-body
                @close="closeAdd">
                <el-form :model="form">
                    <div class="dialog_top">
                        <div class="dialog_left">
                            <el-form-item label="客户名称" :label-width="formLabelWidth">
                                <el-input v-model="form.c_name" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入客户名称"></el-input>
                            </el-form-item>
                            <el-form-item label="联系方式" :label-width="formLabelWidth">
                                <el-input v-model="form.tel" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入联系方式"></el-input>
                            </el-form-item>
                            <el-form-item label="微信/QQ" :label-width="formLabelWidth">
                                <el-input v-model="form.wxqq" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入微信/QQ"></el-input>
                            </el-form-item>
                            <el-form-item label="客户级别" :label-width="formLabelWidth">
                                <el-select v-model="level" placeholder="请选择" value-key="filter" @change="selectLevel1">
                                    <el-option v-for="item in options2" :key="item.level" :label="item.name"
                                        :value="item.level">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="客户来源" :label-width="formLabelWidth">
                                <el-select v-model="from" placeholder="请选择" value-key="id" @change="selectfrom1">
                                    <el-option v-for="item in options4" :key="item.from" :label="item.name"
                                        :value="item.from">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="dialog_right" style="display: flex;">
                            <el-form-item label="项目类型" label-width="110px">
                                <div class="btn">
                                    <div class="btn_elbtn">
                                        <div class="status_btn" :class="item.id == project_id ? 'checked' : ''"
                                            v-for="(item, index) in btnlist" :key="index" @click="getProjectId(item.id)">{{
                                                item.name }}</div>
                                    </div>
                                </div>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="dialog_bottom">
                        <el-form-item label="添加备注" :label-width="formLabelWidth">
                            <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 10 }" placeholder="请输入内容"
                                v-model="form.notes">
                            </el-input>
                        </el-form-item>
                    </div>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addcenterDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addData()">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 编辑弹出框 -->
        <div class="tj_dialog">
            <el-dialog title="编辑客户" :visible.sync="editcenterDialogVisible" width="750px" center append-to-body
                @close="closeAdd">
                <el-form :model="form">
                    <div class="dialog_top">
                        <div class="dialog_left">
                            <el-form-item label="客户名称" :label-width="formLabelWidth">
                                <el-input v-model="form.name" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入客户名称"></el-input>
                            </el-form-item>
                            <el-form-item label="联系方式" :label-width="formLabelWidth">
                                <el-input v-model="form.mobile" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入联系方式"></el-input>
                            </el-form-item>
                            <el-form-item label="微信/QQ" :label-width="formLabelWidth">
                                <el-input v-model="form.third_contact" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入微信/QQ"></el-input>
                            </el-form-item>
                            <el-form-item label="客户级别" :label-width="formLabelWidth">
                                <el-select v-model="form.level" placeholder="请选择" value-key="filter" @change="selectLevel1">
                                    <el-option v-for="item in options2" :key="item.level" :label="item.name1"
                                        :value="item.level">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="客户来源" :label-width="formLabelWidth">
                                <el-select v-model="form.from" placeholder="请选择" value-key="id" @change="selectfrom1">
                                    <el-option v-for="item in options4" :key="item.from" :label="item.name"
                                        :value="item.from">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="dialog_right" style="display: flex;">
                            <el-form-item label="项目类型" label-width="110px">
                                <div class="btn">
                                    <div class="btn_elbtn">
                                        <div class="status_btn" :class="item.id == project_id ? 'checked' : ''"
                                            v-for="(item, index) in btnlist" :key="index" @click="getProjectId(item.id)">{{
                                                item.name }}</div>
                                    </div>
                                </div>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="dialog_bottom">
                        <el-form-item label="添加备注" :label-width="formLabelWidth">
                            <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 10 }" placeholder="请输入内容"
                                v-model="form.remark">
                            </el-input>
                        </el-form-item>
                    </div>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button editcenterDialogVisible="false">取 消</el-button>
                    <el-button type="primary" @click="sureEdit">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 指派弹出框 -->
        <div class="zp_dialog">
            <el-dialog title="指派" :visible.sync="zpcenterDialogVisible" width="450px" center append-to-body>
                <el-form :model="form">
                    <el-form-item label="请选择部门" :label-width="formLabelWidth">
                        <el-select v-model="departmentName" placeholder="请选择" value-key="id" @change="departmentId">
                            <el-option v-for="item in options10" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="请选择商务" :label-width="formLabelWidth">
                        <el-select v-model="staff" placeholder="请选择" value-key="id" @change="selectstaff">
                            <el-option v-for="item in options6" :key="item.id" :label="item.nickname" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="zpcenterDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="zpData">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { getdpkhData, addPerson, alterCustomInfo, sxpdData, delData, sxgzData, DepartmentList } from '../../api/yunyingguanli/dpkh'
import { department, } from '../../api/renyuanguanli/bumenlist';
import { getproject, getcreator, getStaff, getswpo } from '../../api/common/common'
import { getData } from '../../api/Khgl/Khlb';
import { timestampToTime } from "../../utils/publicFun"
export default {
    data() {
        return {
            tableData: [],
            // 分页
            filter: 0,
            level: '',
            from: '',
            project_type: '',
            value4: '',
            creator: '',
            paidan: '',
            totalCount: 0,
            currentIndex: 3,
            sxgzValue: '',
            createTime: '',
            options6: [],
            project_id: '',
            options1: [
                {
                    filter: 0,
                    name: "全部"
                },
                {
                    filter: 1,
                    name: "我负责"
                },
                {
                    filter: 2,
                    name: "我的下属负责"
                },
            ],
            options2: [
                {
                    level: '',
                    name: "",
                    name1: '全部'
                },
                {
                    level: 0,
                    name: "A",
                    name1: 'A（准客户）'
                },
                {
                    level: 1,
                    name: "B",
                    name1: 'B（意向客户）'
                },
                {
                    level: 2,
                    name: "C",
                    name1: 'C（一般客户）'
                },
                {
                    level: 3,
                    name: "D",
                    name1: 'D（有效客户）'
                },
                {
                    level: 4,
                    name: "K",
                    name1: 'K（低效客户）'
                },
                {
                    level: 5,
                    name: "P",
                    name1: 'P（违法客户）'
                }
            ],
            options3: [],
            options4: [
                {
                    from: "",
                    name: "全部"
                },
                {
                    from: 1,
                    name: "淘宝"
                },
                {
                    from: 2,
                    name: "百度"
                },
            ],
            options5: [],
            value: true,
            currentPage: 1,
            textHeight: null,
            status: false,
            idShowText: false,
            value1: '',
            value2: '',
            value3: '',
            value4: '',
            value5: '',
            input2: '',
            staff: '',
            departmentName: '',
            options10: [],
            // 顺序规则弹出框
            centerDialogVisible: false,
            addcenterDialogVisible: false,
            editcenterDialogVisible: false,
            zpcenterDialogVisible: false,
            value: '',
            // project_id: '',
            form: {
                name: '',
                mobile: '',
                third_contact: '',
                level: '',
                from: '',
                remark: '',
            },
            formLabelWidth: '120px',
            areaInfo: [],
            options: [],
            btnlist: [],
            multipleSelection: [],
            arr: [],
            newArr: [],
            createTime: '',
            AreaID: 0,
            typeIndex: 0,
            departmentIds: "",
            levalName1: ''
        }
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.calculateText();
            }, 300);
        });
        this.getdepartmentdata()
        this.getproject_typeData()
        this.getcreatorData()
        // this.getgetStaffData()
        this.getzpdepartmentdata()
        // this.getData()
        this.typeIndex = 3
    },
    created() {
        // this.departmentIds = window.localStorage.getItem("department-id")
        // console.log(this.departmentIds)
    },
    methods: {
        // 添加清空输入框
        closeAdd() {
            this.form = {
                name: '',
                mobile: '',
                third_contact: '',
                level: '',
                from: '',
                remark: '',
            }
        },
        handleDelete(index, row) {
            console.log(index, row);
        },
        // 分页
        handleSizeChange() {
            // 每页显示数量 也就是pagesize发生变化时 要重置数据，和重置页码
            this.tableData = []
            this.currentPage = 1;
            this.pageSize = 15
            this.getData()
        },
        handleCurrentChange(val) {
            // 当 页码发生变化时 重置数据
            this.tableData = []
            this.currentPage = val
            this.getData()
        },
        // 展示与收起
        calculateText() {
            // 这是默认两行数据的高度，一行的高度可以自定义 可以*3 *4达到三行或者四行显示展示和收起的效果
            let twoHeight = 20 * 2;
            this.textHeight = `${twoHeight}px`;
            let curHeight = this.$refs.desc.offsetHeight;
            console.log("curHeight", curHeight);
            console.log("twoHeight", twoHeight);
            if (curHeight > twoHeight) {
                this.status = true;
                this.idShowText = true;
            } else {
                this.status = false;
                this.idShowText = false;
            }
        },
        // 地区部门数据列表
        async getdepartmentdata() {
            let reqData = {
                id: 0
            }
            let res = await department(reqData)
            console.log(res);
            this.areaInfo = res.data.data
            // this.options10 = res.data.data
            console.log(this.areaInfo);
            // console.log(res.data.data[3].id);
            this.getData(res.data.data[3].id)
        },
        changeType(id, index) {
            console.log(id);
            this.AreaID = id
            this.typeIndex = index
            this.getData()
        },
        //   获取列表数据
        async getData() {
            let reqData = {
                area: this.AreaID,
                page: this.currentPage,
                filter: this.filter == "" ? 0 : this.filterId,
                aid: this.creator,
                level: this.levalName,
                project_type: this.project_typeID,
                from: this.fromId,
                timeRange: this.crr1
            }
            let res = await getdpkhData(reqData)
            console.log(res.data.data.data);
            this.tableData = res.data.data.data;
            // console.log(this.tableData);
            this.setData(this.tableData)
            this.totalCount = res.data.data.total
            if (res.data.code == 5) {
                this.$message({
                    type: 'error',
                    message: '暂无权限访问!'
                });
                this.$router.push({ path: "/Index/kongbai" });
            }
        },
        setData(arr) {
            for (let i = 0; i < arr.length; i++) {
                arr[i].createtime = !arr[i].createtime ? '' : timestampToTime(arr[i].createtime * 1000)
                arr[i].updatetime = !arr[i].updatetime ? '' : timestampToTime(arr[i].updatetime * 1000)
            }
        },
        // 添加用户
        async addData() {
            let reqData = {
                name: this.form.c_name,
                mobile: this.form.tel,
                third_contact: this.form.wxqq,
                level: this.levalName,
                remark: this.form.notes,
                from: this.fromId,
                project_id: this.project_id
            }
            let res = await addPerson(reqData)
            if (res.data.code == 0) {
                this.$message({
                    message: res.data.msg,
                    type: "error"
                })

            }
            if (res.data.code == 1) {
                this.$message({
                    message: '添加成功',
                    type: "success"
                });
                this.getData()
                this.addcenterDialogVisible = false
            }

            console.log(res);
        },
        // 修改获取数据
        editData(data) {
            console.log(data);
            this.editcenterDialogVisible = true
            this.form.name = data.name
            this.form.mobile = data.mobile
            this.form.third_contact = data.third_contact
            this.form.level = data.level
            this.form.from = data.from
            this.form.remark = data.remark
            this.id = data.id
            this.fromId = data.form
            this.levalName1 = data.level
            this.project_id = data.project_id
        },
        // 确定修改
        async sureEdit() {
            let reqData = {
                ids: this.id,
                name: this.form.name,
                mobile: this.form.mobile,
                third_contact: this.form.third_contact,
                level: this.levalName1,
                project_id: this.project_id,
                from: this.fromId,
                remark: this.form.remark
            }
            let res = await alterCustomInfo(reqData)

            if (res.data.code == 0) {
                this.$message({
                    message: res.data.msg,
                    type: "error"
                })

            }
            if (res.data.code == 1) {
                this.$message({
                    message: '修改成功',
                    type: "success"
                });
                this.getData()
                this.editcenterDialogVisible = false
            }
        },
        // 顺序派单
        async sxpd(data) {
            console.log(data);
            let reqData = {
                type: 1,
                customer_id: data.id,
                // user_type: 2
            }
            let res = await sxpdData(reqData)
            console.log(res);
            if (res.data.code == 1) {
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
                this.getData()
            }
        },
        // 指派获取
        zp(data) {
            console.log(data);
            this.customer_id = data.id
            this.zpcenterDialogVisible = true
        },
        // 指派
        async zpData() {
            let reqData = {
                type: 2,
                uid: this.id,
                customer_id: this.customer_id,
                // user_type: 2
            }
            let res = await sxpdData(reqData)
            console.log(res);
            if (res.data.code == 1) {
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
                this.getData()
            }
            this.zpcenterDialogVisible = false
        },
        // 获取指派部门
        async getzpdepartmentdata() {
            let reqData = {
                id: 0
            }
            let res = await DepartmentList(reqData)
            console.log(res);
            this.options10 = res.data.data
        },
        // 获取指派部门id
        departmentId(id) {
            console.log(id);
            this.departmentIds = id
            // let obj = {};
            // obj = this.options6.find((item) => {
            //     return item.id === id; //筛选出匹配数据
            // });
            // console.log(obj);
            // this.id = obj.id
            // console.log(this.id);
            this.getgetStaffData()
        },
        // 全选
        handleSelectionChange(val) {
            this.multipleSelection = val;
            for (var i of this.multipleSelection) {
                console.log(i);
                this.arr.push(i.id)
                console.log(this.arr);
            }
            this.newArr = [...new Set(this.arr)];
            console.log(this.newArr);
        },
        // 删除选中
        async deleteData(data) {
            let reqData = {
                ids: this.newArr + '',
            }
            console.log(reqData);
            this.$confirm('确定要删除该数据吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await delData(reqData)
                if (res.data.code == 1) {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getData()
                    // location.reload()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 获取顺序规则事件
        getDate4() {
            console.log(this.sxgzValue);
        },
        // 获取客户项目类型
        async getproject_typeData() {
            let res = await getproject()
            console.log(res.data.data);
            let obj2 = {
                id: '',
                name: '全部'
            }
            this.options3 = res.data.data
            this.options3.unshift(obj2)
            this.btnlist = res.data.data
        },
        // 获取项目类型下拉框id
        selectproject_type(id) {
            console.log(id);
            let obj = {};
            obj = this.options3.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            console.log(obj);
            this.project_typeID = obj.id
            console.log(this.project_typeID);
            this.getData()
        },
        // 获取项目类型按钮id
        getProjectId(id) {
            console.log(id);
            this.project_id = id
        },
        // 获取创建人
        async getcreatorData() {
            let res = await getcreator()
            console.log(res.data.data);
            let obj = {
                id: '',
                nickname: '全部'
            }
            this.options5 = res.data.data
            this.options5.unshift(obj)
        },
        // 获取创建人id
        selectcreator(id) {
            console.log(id);
            let obj = {};
            obj = this.options5.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            console.log(obj);
            this.creator = obj.id
            console.log(this.creator);
            this.getData()
        },
        // 获取客户筛选id
        selectFilter(id) {
            console.log(id);
            let obj = {};
            obj = this.options1.find((item) => {
                return item.filter === id; //筛选出匹配数据
            });
            console.log(obj);
            this.filterId = obj.filter
            console.log(this.id);
            this.getData(0)
        },
        // 获取客户级别id
        selectLevel(id) {
            console.log(id);
            let obj = {};
            obj = this.options2.find((item) => {
                return item.level === id; //筛选出匹配数据
            });
            console.log(obj);
            this.levalName = obj.name
            this.levelId = obj.level
            console.log(this.levelId);
            this.getData()
        },
        // 获取客户级别id
        selectLevel1(id) {
            console.log(id);
            let obj = {};
            obj = this.options2.find((item) => {
                return item.level === id; //筛选出匹配数据
            });
            console.log(obj);
            this.levalName1 = obj.name
            this.levelId = obj.level
            console.log(this.levelId);
            // this.getData()
        },
        // 获取客户来源id
        selectfrom(id) {
            console.log(id);
            let obj = {};
            obj = this.options4.find((item) => {
                return item.from === id; //筛选出匹配数据
            });
            console.log(obj);
            this.fromId = obj.from
            console.log(this.fromId);
            this.getData()
        },
        // 获取客户来源id
        selectfrom1(id) {
            console.log(id);
            let obj = {};
            obj = this.options4.find((item) => {
                return item.from === id; //筛选出匹配数据
            });
            console.log(obj);
            this.fromId = obj.from
            console.log(this.fromId);
        },
        // 获取员工列表
        async getgetStaffData() {
            let reqData = {
                department_id: this.departmentIds
            }
            let res = await getswpo(reqData)
            console.log(res);
            this.options6 = res.data.data
        },
        // 获取员工列表id
        selectstaff(id) {
            // console.log(id);
            let obj = {};
            obj = this.options6.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            console.log(obj);
            this.id = obj.id
            console.log(this.id);
            // this.getData(0)
        },
        // 获取创建时间
        getDate1() {
            // console.log(this.createTime);
            this.createtime = this.createTime
            let brr1 = [];
            for (var i of this.createtime) {
                brr1.push(i / 1000)
            }
            this.crr1 = brr1.join('-')
            console.log(this.crr1);
            this.getData()
        },
        getDate2() {
            console.log(this.jiedan / 1000);
            this.getData()
        },
        getDate3() {
            console.log(this.paidan);
            this.getData()
        },
        // 重置搜索输入框
        async clearinput() {
            this.input2 = ''
            this.level = ""
            this.project_type = ""
            this.from = ""
            this.creator = ""
            this.createTime = ""
            let reqData = {
                area: this.AreaID,
                page: this.currentPage,
                filter: this.filter == "" ? 0 : this.filterId,
            }
            let res = await getdpkhData(reqData)
            console.log(res.data.data.data);
            this.tableData = res.data.data.data;
            this.totalCount = res.data.data.total
        },
        // 顺序规则事件
        async sxgz() {
            this.centerDialogVisible = false
            let reqData = {
                // order_rule: this.sxgzValue
                order_rule_name: 'order_rule',
                order_rule_value: this.sxgzValue
            }
            let res = await sxgzData(reqData)
            console.log(res);
            if (res.data.code == 1) {
                this.$message({
                    type: 'success',
                    message: '设置成功!'
                });
                this.getData()
                // location.reload()
            }
        },
        // 搜索
        async searchBtnInfo() {
            let reqData = {
                area: this.AreaID,
                page: this.currentPage,
                filter: this.filter == "" ? 0 : this.filterId,
                aid: this.creator,
                level: this.levalName,
                project_type: this.project_typeID,
                from: this.fromId,
                timeRange: this.crr1,
                search: this.input2
            }
            let res = await getdpkhData(reqData)
            console.log(res);
            if (this.input2) {
                this.tableData = res.data.data.data;
                this.totalCount = res.data.data.total
            } else {
                this.getData()
            }
            this.input2 = ""
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.el-card__body {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    width: 1500px;
}

.el-card {
    border: none;
    border-radius: 0;
}

.el-card.is-always-shadow {
    box-shadow: none;
}

.box-card {
    display: flex;
    justify-content: space-between;
}

/deep/[data-v-255bf696] .el-card__body {
    display: flex;
    justify-content: space-between;

}

.card_name {
    display: flex;
    justify-content: space-between;
}

.left_bottom[data-v-6bfd8bdf][data-v-6bfd8bdf] {
    margin-left: 0;
}

.select_area {
    font-weight: bold;
    white-space: normal;
    display: flex;
    flex-shrink: 0;
    text-align: center;
    line-height: 56px;
    margin-left: 20px;
}

.card_div {
    display: flex;
    justify-content: space-around;
    text-align: right;

    p {
        padding: 0 10px;
    }
}

.block {
    text-align: right;
    line-height: 78px;
}

.left_top {
    display: flex;
    justify-content: space-between;

    p {
        margin: 0 20px;

        span {
            padding-right: 17px;
        }
    }
}

#card_left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.left_bottom {
    display: flex;

    p {
        margin: 10px 25px;

        span {
            padding-right: 10px;
        }
    }
}

.name-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.card_address {
    // width: 500px;
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    background-color: #FFF;
    overflow: hidden;
    color: #303133;
    transition: .3s;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    display: flex;

    p {
        margin: 10px 15px;
    }
}

.card_name {
    position: relative;

    .titleText {
        font-weight: bold;
        font-size: 18px;
    }

    .openClose {
        font-size: 14px;
        // color: #25dbe6;
        color: skyblue;
        cursor: pointer;
    }

    .openSpan {
        position: absolute;
        right: -50px;
        cursor: pointer;
    }

    .statusText {
        overflow: hidden;
        display: block;
    }

    .statusText:after {
        position: absolute;
        bottom: 0;
        width: 100px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.2), #fff 45%);
    }
}

.el-dialog {
    i {
        color: orange;
        font-size: 18px;
        padding-right: 7px;
    }
}

.sx_dialog .el-dialog .el-date-editor.el-input {
    width: 122px;

    ::placeholder {
        font-size: 12px;
    }
}

.el-textarea {
    width: 575px;
}

.dialog_bottom {
    display: flex;
}

label {
    width: 60px;
}

.dialog_top {
    display: flex;
    justify-content: flex-start;
}

.tj_dialog {
    .el-dialog {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}

.dialog_right .btn {
    margin-left: 10px;
    width: 250px;
}

// .btn_elbtn {
//     width: 300px;

//     .el-button+.el-button {
//         margin-left: 0;
//         margin-right: 10px;
//         margin-bottom: 10px;
//     }
// }

// .btn_elbtn .el-button:nth-child(1) {
//     margin-right: 10px;
// }

.btn_elbtn {
    width: 300px;

    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    cursor: pointer;
}

.status_btn {
    padding: 0 10px;
    box-sizing: border-box;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid #999;
    border-radius: 10px;
    line-height: 30px;
}

.checked {
    background-color: #409EFF;
    color: #fff;
    border: none;
}

.left_bottom[data-v-6bfd8bdf] {
    margin-left: 18px;
}

.el-date-editor.el-input {
    width: 200px;
}

.el-button {
    color: #606266;
    border-color: #DCDFE6;
    background-color: #fff;
}

.box4 {
    color: #606266;
    border-color: #DCDFE6;
    background-color: #fff;
}

.box3 {
    background-color: #409EFF;
    color: #409EFF;
    border-color: #b3d8ff;
}

.type {
    width: 5%;
    height: 100%;
    text-align: center;
    line-height: 40px;
    background-color: #ecf5ff;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 8px;
    margin-left: 10px;
    white-space: nowrap;
}

.type1 {
    background-color: #409EFF;
    width: 5%;
    height: 100%;
    text-align: center;
    line-height: 40px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    margin-top: 8px;
    margin-left: 10px;
    white-space: nowrap;
}
</style>