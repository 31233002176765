import store from '@/store'
import axios from 'axios'
import router from '../router'

import {
	Message
} from 'element-ui'
import {
	baseURL
} from '../config/index'

export const request = (options) => { //暴露一个function：request，使用options接收页面传过来的参数
	//当发起请求的时候，这里会从本地取token 并通过headers 带给后端
	var token = localStorage.getItem("admin-token")
	// 请求头信息 请求登录接口时不用带token
	if (options.url == "/admin/login") {
		options.headers = {
			'content-type': 'application/json;charset=utf-8',
			'Cache-Control': 'no-cache',
		};
	} else {
		options.headers = {
			'content-type': 'application/json;charset=utf-8',
			'Cache-Control': 'no-cache',
			"token": token
		};
	}
	return new Promise((resolve, reject) => { //异步封装接口，使用Promise处理异步请求
		axios({ //发送请求
			url: baseURL + options.url, //接收请求的API
			method: options.method || 'get', //接收请求的方式,如果不传默认为GET
			headers: options.headers || {},
			// 当method == get时 用params 去拼参数
			// 当method == post时 data 去拼参数
			// params: options.method == 'delete' ? options.params : {},
			params: options.method == 'get' || options.method == 'delete' ? options.params : {},

			data: options.method == 'post' ? options.data : {},
		}).then(res => {
			console.log(res)

			//这里是请求的自带的状态码 如果不是200 就提示错误码  如果404 或者有需要显示的页面这里 也可以做判断处理
			if (res.status !== 200) {
				// store.dispatch('user/resetToken').then(() => {
				//   location.reload()
				// })
				return Message({
					message: res.status || 'Error',
					type: 'error',
					duration: 5 * 1000
				})

			} else {
				resolve(res)
				// 这里是后端返给你的状态码  如果不是200 就显示后端提供的msg
				// if (res.data.code == 200) {
				// 	resolve(res)
				// } else {
				// 	// store.dispatch('user/resetToken').then(() => {
				// 	//   location.reload()
				// 	// })
				// 	return Message({
				// 		message: res.msg || 'Error',
				// 		type: 'error',
				// 		duration: 5 * 1000
				// 	})
				// }
			}
		}).catch(err => {
			if (err.response.data.code == 401) {
				localStorage.setItem("admin-token","")
				router.push({ path: "/" })
				return Message({
					message: '登录过期,请重新登录',
					type: 'error',
					duration: 5 * 1000
				})
			}
			else {
				Message({
					showClose: true,
					message: err,
					type: "error"
				});
			}
			resolve(err)
		})
	})
}
