<template>
  <div class="userlist">
    <div class="left" @click.self="removeSelect">
      <el-tree :data="data" ref="tree" node-key="id" @node-drop="dragSuccess" draggable :allow-drop="dropAllow"
        @node-click="tiao" icon-class="el-icon-arrow-right" highlight-current :accordion="true" style="z-index: 1000;"
        :props="defaultProps">
      </el-tree>
      <div class="bottom" @click.self="removeSelect">
        <el-divider></el-divider>
        <div class="bottom">
          <div style="display: flex;">
            <el-button type="primary" plain @click="editData()" size="mini" style="margin-bottom:10px">编辑</el-button>
            <el-button type="danger" plain @click="deleteData()" size="mini" style="margin-bottom:10px">删除</el-button>
          </div>
          <el-button type="primary" plain @click="addInfo" v-if="isSelect == true">添加({{ selectName }})</el-button>
          <el-button type="primary" plain @click="addInfo" v-else>添加地区</el-button>
        </div>
      </div>
    </div>
    <div class="right" style="width:78% ;">
      <router-view />
    </div>
    <!-- 添加区域 -->
    <div class="tjbumen">
      <el-dialog title="添加区域" :visible.sync="addareacenterDialogVisible" width="30%" center append-to-body>
        <el-form :model="addareaform">
          <el-form-item label="地区" :label-width="formLabelWidth">
            <el-input v-model="adddepartmentform.department_name" autocomplete="off" placeholder="请输入要添加的地区"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addareacenterDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addareaData()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 添加部门 -->
    <div class="tjbumen">
      <el-dialog title="添加部门" :visible.sync="adddeparmentcenterDialogVisible" width="30%" center append-to-body>
        <el-form :model="adddepartmentform">
          <el-form-item label="部门" :label-width="formLabelWidth">
            <el-input v-model="adddepartmentform.department_name" autocomplete="off" placeholder="请输入要添加的部门"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="adddeparmentcenterDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="adddepartment()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 编辑 -->
    <div class="tjbumen">
      <el-dialog title="编辑部门" :visible.sync="editdeparmentcenterDialogVisible" width="30%" center append-to-body>
        <el-form :model="adddepartmentform">
          <el-form-item label="部门" :label-width="formLabelWidth">
            <el-input v-model="adddepartmentform.department_name" autocomplete="off" placeholder="请输入要编辑的部门"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editdeparmentcenterDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="sureEdit()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { department, adddepartment, editdepartment, deletedepartment } from '../../api/renyuanguanli/bumenlist';

export default {
  props: {
    // 点击高亮当前行
    highlightcurrent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: [],
      adddepartmentform: {
        department_name: '',
        area_id: ''
      },
      formLabelWidth: '',
      editdeparmentcenterDialogVisible: false,
      addareacenterDialogVisible: false,
      adddeparmentcenterDialogVisible: false,
      defaultProps: {
        children: 'childlist',
        label: 'name'
      },
      isSelect: false,
      selectName: "",
      selectId: "",
      ziID: '',
      fuID: '',
      bumenID: '',
      addareaform: {
        name: ""
      }
    };
  },
  created() {
    this.bumenID = localStorage.getItem('department-id')
    console.log(this.bumenID);
  },
  mounted() {

    // this.tiao()
    this.getdepartmentdata()
    // this.adddepartment()
  },
  methods: {
    // 拖拽时触发
    dropAllow(draggingNode, dropNode, type) {
      if (draggingNode.level === dropNode.level) {
        return type === "prev" || type === "next"
      } else {
        return false;
      }
    },
    // 拖拽成功时触发
    async dragSuccess(draggingNode, dropNode, type, event) {
      this.$message({
        message: "成功",
        type: "success",
      });
      console.log(draggingNode.data.id);//子
      console.log(dropNode.data.id);//父
      this.ziID = draggingNode.data.id
      this.fuID = dropNode.data.id
      // 请求接口传参即可
      let reqData = {
        ids: this.ziID,
        pid: this.fuID
      }
      let res = await editdepartment(reqData)
      console.log(res);
      this.getdepartmentdata()
    },
    tiao(data, node, arr) {
      this.isSelect = true
      console.log('哈哈哈哈', data)

      let p_name = ''
      if (data.pid != 0) {
        this.data.forEach(item => {
          if (item.id == data.pid) {
            p_name = item.name
          }
        });
      }
      console.log(p_name);
      this.selectName = data.name
      this.selectId = data.id
      if (!data.children) {
        this.$router.push({
          path: `/Index/BumenGuanLi/Jishu`,
          query: {
            title: data.name,
            department_id: data.id,
            p_name: p_name
          }
        })
      }
    },
    // 地区部门数据列表
    async getdepartmentdata() {
      let reqData = {
        id: 0
      }
      let res = await department(reqData)
      console.log(res);
      this.data = res.data.data
      if (res.data.code == 5) {
        this.$message({
          type: 'error',
          message: '暂无权限访问!'
        });
        this.$router.push({ path: "/Index/kongbai" });
      }

      console.log(this.data);
    },
    //添加部门
    async adddepartment() {
      let reqData = {
        // 
        pid: this.selectId == "" ? this.bumenID : this.selectId,
        name: this.adddepartmentform.department_name
      }
      let res = await adddepartment(reqData)
      console.log(res);
      if (res.data.code == 1) {
        this.$message({
          message: '添加成功',
          type: "success"
        });
        this.getdepartmentdata()
      }
      this.adddeparmentcenterDialogVisible = false
      this.adddepartmentform = {
        department_name: '',
      }
    },
    // 添加地区
    async addareaData() {
      let reqData = {
        pid: this.selectId == "" ? this.selectId : this.bumenID,
        name: this.adddepartmentform.department_name
      }
      console.log();
      let res = await adddepartment(reqData)
      console.log(res)
      if (res.data.code == 1) {
        this.$message({
          message: '添加成功',
          type: "success"
        });
        this.getdepartmentdata()
      }
      this.addareacenterDialogVisible = false
      this.addareaform = {
        name: ""
      }
    },
    // 编辑
    editData() {
      this.editdeparmentcenterDialogVisible = true
      this.adddepartmentform.department_name = this.selectName
      // this.editId = this.selectId
    },
    // 确定编辑
    async sureEdit() {
      let reqData = {
        name: this.adddepartmentform.department_name,
        ids: this.selectId
      }
      console.log(reqData);
      let res = await editdepartment(reqData)
      console.log(res);
      this.editdeparmentcenterDialogVisible = false
      this.getdepartmentdata()
    },
    // 删除地区
    async deleteData(data) {
      this.$confirm('确定要删除该数据吗？', '提示', {
        type: 'warning',
        beforeClose: async (action, instance, done) => {
          if (action === "confirm") {
            // 调取接口
            let reqData = {
              ids: this.selectId,
            }
            let res = await deletedepartment(reqData)

            if (res.data.code == 1) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getdepartmentdata()
              done();
              // location.reload()
            }
          } else {
            console.log(456);
            done();
          }
        },

      })
    },
    removeSelect() {
      this.isSelect = false;
    },
    addInfo() {
      if (this.isSelect == true) {
        // console.log("选中状态");
        // 这里是选中状态，这里要调用添加部门的接口 要传area_id的
        this.adddeparmentcenterDialogVisible = true
      } else {
        // console.log("点击了空白处");
        // 这里是点击了空白处，就是添加地区的
        this.addareacenterDialogVisible = true
      }
      // adddeparmentcenterDialogVisible = true
    }
  }
};
</script>

<style lang="less" scoped>
.left {
  width: 190px;
  height: 92%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // margin: 10px;
  // height: 866px;
  position: fixed;
  top: 60px;
  left: 210px;
}

.bottom {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.userlist {
  display: flex;
  justify-content: space-around;
  // height: 100%;
}

.right {
  margin-right: 20px;
  margin-left: 10px;
  position: fixed;
  left: 400px;
}

.el-tree {
  width: 190px;
}

// 修改下拉图标的位置
::v-deep .el-tree-node__expand-icon {
  position: absolute;
  right: 10%;
}

::v-deep .el-tree-node__label {
  padding-left: 15px;
}

/deep/.el-tree-node__content {
  height: 50px;
}
</style>