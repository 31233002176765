<template>
    <div>
        <el-card class="box-card">
            <div class="title">
                <span v-if="this.$route.query.p_name">{{ this.$route.query.p_name }} - </span>
                {{ this.$route.query.title }}
            </div>
            <div class="card_div">
                <p><el-input placeholder="请输入内容" v-model="input2" class="input-with-select">
                        <el-button slot="append" icon="el-icon-search" @click="searchBtnInfo()"></el-button>
                    </el-input></p>
                <p><el-button type="primary" plain size="medium" @click="dialogFormVisible = true">添加人员</el-button></p>
                <p><el-button plain size="medium" :disabled="this.multipleSelection.length == 0"
                        @click="deleteData">删除选中</el-button></p>
            </div>
        </el-card>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;"
            @selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }">
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column prop="id" label="序号" width="150" align="center">
            </el-table-column>
            <el-table-column prop="nickname" label="姓名" width="150" align="center">
            </el-table-column>
            <el-table-column prop="gender" label="性别" width="140" align="center">
                <template slot-scope="scope">
                    <div v-if="scope.row.gender == '0'">
                        男
                    </div>
                    <div v-if="scope.row.gender == '1'">
                        女
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="mobile" label="手机号" width="150" align="center">
            </el-table-column>
            <el-table-column prop="department.name" label="岗位" width="150" align="center">
            </el-table-column>
            <el-table-column prop="auth.access.name" label="绑定角色" width="150" align="center">
            </el-table-column>
            <!-- <el-table-column prop="switch" label="开关" width="150" align="center">
                <el-switch v-model="value" active-color="#13ce66" inactive-color="#ff4949">
                </el-switch>
            </el-table-column> -->
            <el-table-column prop="entrytime" label="入职时间" width="150" align="center">
            </el-table-column>
            <el-table-column prop="createtime" label="创建时间" width="160" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button @click="editData(scope.row)" type="primary" plain size="mini">修改</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[10, 30, 50]" :page-size="15" layout="total, sizes, prev, pager, next, jumper"
                :total="countTotal">
            </el-pagination>
        </div>
        <!-- 添加用户弹框 -->
        <div class="dialog">
            <el-dialog title="添加人员" :visible.sync="dialogFormVisible" append-to-body @close="closeadd"
                :close-on-click-modal='false'>
                <el-form :model="addform">
                    <el-form-item label="员工名称" :label-width="formLabelWidth">
                        <el-input v-model="addform.nickname" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="员工性别" :label-width="formLabelWidth">
                        <el-radio-group v-model="addform.gender" size="small">
                            <el-radio label="0" border>男</el-radio>
                            <el-radio label="1" border>女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="手机号" :label-width="formLabelWidth">
                        <el-input v-model="addform.mobile" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="岗位" :label-width="formLabelWidth">
                        <el-select v-model="selectTreeD" placeholder="请选择" :popper-append-to-body="false">
                            <el-option :value="selectTreedepartment" class="setstyle" style="overflow: auto; height: 100%"
                                disabled value-key="id">
                                <el-tree style="min-height: 150px; max-height: 300px" :data="dataDeparent"
                                    :props="defaultPropsData" ref="tree" check-strictly :expand-on-click-node="false"
                                    @node-click="getdepartment_id"></el-tree>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="绑定角色" :label-width="formLabelWidth">
                        <el-select v-model="selectTreeName" placeholder="请选择" :popper-append-to-body="false">
                            <el-option :value="selectTree" class="setstyle" style="overflow: auto; height: 100%" disabled
                                value-key="id">
                                <el-tree style="min-height: 150px; max-height: 300px" :data="data" :props="defaultProps"
                                    ref="tree" check-strictly :expand-on-click-node="false" @node-click="getId"></el-tree>
                            </el-option>
                        </el-select>

                    </el-form-item>
                    <el-form-item label="登录密码" :label-width="formLabelWidth">
                        <el-input v-model="addform.password" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="入职时间" :label-width="formLabelWidth">
                        <el-input v-model="addform.entrytime" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addPersonInfo()">确 定</el-button>
                </div>
            </el-dialog>
        </div>
        <!-- 修改弹框 -->
        <div class="dialog">
            <el-dialog title="修改人员" :visible.sync="editdialogFormVisible" append-to-body>
                <el-form :model="addform">
                    <el-form-item label="员工名称" :label-width="formLabelWidth">
                        <el-input v-model="addform.nickname" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="员工性别" :label-width="formLabelWidth">
                        <el-radio-group v-model="addform.gender" size="small">
                            <el-radio :label="0" border>男</el-radio>
                            <el-radio :label="1" border>女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="手机号" :label-width="formLabelWidth">
                        <el-input v-model="addform.mobile" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="岗位" :label-width="formLabelWidth">
                        <el-select v-model="selectTreeD" placeholder="请选择" :popper-append-to-body="false">
                            <el-option :value="selectTreedepartment" class="setstyle" style="overflow: auto; height: 100%"
                                disabled value-key="id">
                                <el-tree style="min-height: 150px; max-height: 300px" :data="dataDeparent"
                                    :props="defaultPropsData" ref="tree" check-strictly :expand-on-click-node="false"
                                    @node-click="getdepartment_id"></el-tree>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="绑定角色" :label-width="formLabelWidth">
                        <el-select v-model="selectTreeName" placeholder="请选择" :popper-append-to-body="false">
                            <el-option :value="selectTree" class="setstyle" style="overflow: auto; height: 100%" disabled
                                value-key="id" @change="getrule_id">
                                <el-tree style="min-height: 150px; max-height: 300px" :data="data" :props="defaultProps"
                                    ref="tree" check-strictly :expand-on-click-node="false" @node-click="getId"></el-tree>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="登录密码" :label-width="formLabelWidth">
                        <el-input v-model="addform.password" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="入职时间" :label-width="formLabelWidth">
                        <el-input v-model="addform.entrytime" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="editdialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="sureEdit()">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { getAreaPersonInfo, searchBtn, addPerson, getRole, editPerson, delData } from '../../../api/jishu/jishu';
import { department, } from '../../../api/renyuanguanli/bumenlist';
import { timestampToTime } from "../../../utils/publicFun"
export default {
    data() {
        return {
            form: {
                on_off: 1,
                create_time: '',
                job: '',
                joinin_time: '',
                // name: '',
                role: '',
                sex: '',
                tel: ''
            },
            selectTreedepartment: '',
            data: [],
            dataDeparent: [],
            defaultProps: {
                children: 'childlist',
                label: 'name'
            },
            defaultPropsData: {
                children: 'childlist',
                label: 'name'
            },
            tableData: [],
            roleoptions: [],
            joboptions: [],
            options1: [],
            value: "",
            input2: '',
            multipleSelection: [],
            gridData: [],
            dialogTableVisible: false,
            dialogFormVisible: false,
            editdialogFormVisible: false,
            addform: {
                nickname: '',
                gender: "0",
                mobile: '',
                department_id: '',
                rule_id: '',
                password: '',
                entrytime: '',
            },
            formLabelWidth: '120px',
            options2: [],
            param: "",
            page: 1,
            pageSize: 10,
            countTotal: 0,
            currentPage: 1,
            selectTreeName: "",
            selectTreeD: '',
            selectTree: "",
            selectid: '',
            newArr: [],
            arr: []
        }
    },
    beforeRouteUpdate(to, from, next) {
        next()
        this.getParam();
    },
    created() {
        document.title = this.param.title
        this.param = this.$route.query
    },
    mounted() {
        // 获取数据的函数
        this.getInfo(8)
        this.getdepartmentdata()
        this.getruledata()
    },
    methods: {
        // 清空
        closeadd() {
            this.addform = {
                nickname: '',
                gender: "0",
                mobile: '',
                department_id: '',
                rule_id: '',
                password: '',
                entrytime: '',
            }
            this.selectTreeD = ""
            this.selectTreeName = ""
        },
        // 地区部门列表数据
        async getdepartmentdata() {
            let reqData = {
                id: 0
            }
            let res = await department(reqData)
            console.log(res.data.data);
            // this.options1 = res.data.data[3].childlist
            this.options1 = res.data.data
            this.dataDeparent = res.data.data
            this.getInfo()
        },
        // 获取角色数据
        async getruledata() {
            let res = await getRole()
            this.data = res.data.data
            console.log(res.data.data);
        },
        // 获取岗位id
        getdepartment_id(data) {
            console.log(data);
            this.addform.department_id = data.id
            this.Did = data.id
            this.selectTreeD = data.name;
            this.$nextTick(() => {
            })
        },
        getrule_id(id) {

        },
        // 获取角色id
        getId(data) {
            console.log(data);
            this.addform.rule_id = data.id
            this.id = data.id
            console.log(this.addform.rule_id);
            this.selectTreeName = data.name;
            // this.$nextTick(() => {
            // })
        },
        // 获取列表数据
        async getInfo() {
            // 在这里掉接口
            let reqData = {
                department_id: this.param.department_id,
                page: this.currentPage,
            }
            console.log(reqData);
            let res = await getAreaPersonInfo(reqData)
            console.log(res);
            if (res.data.code == 1) {
                this.tableData = res.data.data.data
                this.setData(this.tableData)
                this.countTotal = res.data.data.total
            }
        },
        setData(arr) {
            for (let i = 0; i < arr.length; i++) {
                arr[i].createtime = !arr[i].createtime ? '' : timestampToTime(arr[i].createtime * 1000)
            }
        },
        // 添加人员信息功能
        async addPersonInfo() {
            let date1 = new Date(this.addform.entrytime)
            let date2 = Date.parse(date1)
            let reqData = {
                nickname: this.addform.nickname,
                gender: this.addform.gender,
                mobile: this.addform.mobile,
                department_id: this.addform.department_id,
                rule_id: this.addform.rule_id,
                password: this.addform.password,
                entrytime: this.addform.entrytime
            }
            let res = await addPerson(reqData)
            console.log(res)
            if (res.data.code == 0) {
                this.$message({
                    message: res.data.msg,
                    type: "error"
                })

            }
            if (res.data.code == 1) {
                this.$message({
                    message: '添加成功',
                    type: "success"
                });
                this.getInfo()
                this.dialogFormVisible = false
            }
        },
        getParam() {
            this.param = this.$route.query;
            console.log(this.param);
            this.getInfo()
        },
        // 修改
        editData(data) {
            this.editdialogFormVisible = true
            console.log(data);
            this.addform.nickname = data.nickname
            this.addform.gender = data.gender
            // console.log(this.addform.gender);
            this.addform.mobile = data.mobile
            this.addform.entrytime = data.entrytime
            this.selectTreeName = data.auth.access.name
            this.selectTreeD = data.department.name
            this.ids = data.id
            this.addform.department_id = data.department_id
            this.addform.rule_id = data.auth.access.id
        },
        // 确定修改
        async sureEdit() {
            let reqData = {
                nickname: this.addform.nickname,
                gender: this.addform.gender,
                mobile: this.addform.mobile,
                department_id: this.addform.department_id,
                rule_id: this.addform.rule_id,
                password: this.addform.password,
                entrytime: this.addform.entrytime,
                ids: this.ids,
            }
            let res = await editPerson(reqData)
            if (res.data.code == 0) {
                this.$message({
                    message: res.data.msg,
                    type: "error"
                })

            }
            if (res.data.code == 1) {
                this.$message({
                    message: '修改成功',
                    type: "success"
                });
                this.getInfo()
                this.editdialogFormVisible = false
            }
        },
        // 分页
        handleSizeChange() {
            // 每页显示数量 也就是pagesize发生变化时 要重置数据，和重置页码
            this.tableData = []
            this.currentPage = 1;
            this.pageSize = 15
            this.getInfo()
        },
        handleCurrentChange(val) {
            // 当 页码发生变化时 重置数据
            this.tableData = []
            this.currentPage = val
            this.getInfo()
        },
        // 选中要删除的部门并将要删除的idpush进新的数组，然后去重
        handleSelectionChange(val) {
            // console.log(val);
            this.multipleSelection = val
            // console.log(this.multipleSelection);
            for (var i of this.multipleSelection) {
                // console.log(i);
                this.arr.push(i.id)
                console.log(this.arr);
            }
            this.newArr = [...new Set(this.arr)];
            console.log(this.newArr);

        },
        // 删除选中
        async deleteData(data) {
            let reqData = {
                ids: this.newArr + '',
            }
            console.log(reqData);
            this.$confirm('确定要删除该数据吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await delData(reqData)
                if (res.data.code == 1) {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getInfo()
                    // location.reload()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 搜索
        async searchBtnInfo() {
            let reqData = {
                department_id: this.param.department_id,
                page: this.currentPage,
                search: this.input2
            }
            console.log(reqData);
            let res = await getAreaPersonInfo(reqData)
            console.log(res);
            if (this.input2) {
                this.tableData = res.data.data.data
                this.countTotal = res.data.data.total
            } else {
                this.getInfo()
            }
            this.input2 = ""
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.el-card__body {
    padding: 5px;
}

.el-card {
    border: none;
    border-radius: 0;
}

.el-card.is-always-shadow {
    box-shadow: none;
}

.box-card {
    display: flex;
    justify-content: space-between;
    line-height: 50px;

    .title {
        line-height: 50px;
        font-weight: bold;
    }
}

/deep/[data-v-255bf696] .el-card__body {
    display: flex;
    justify-content: space-between;
    width: 1509px;
}

.card_div {
    display: flex;
    justify-content: space-around;

    p {
        padding: 0 10px;
    }
}

.block {
    text-align: right;
}

.dialog {
    height: 480px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    /deep/.el-input__inner {
        width: 220px;
    }
}

/deep/.el-dialog {
    width: 430px;
}

.dialog-footer,
.el-dialog__header {
    text-align: center;
}

/deep/.el-table .el-table__cell {
    z-index: 1000;
}

/deep/ .dialog {
    display: none;
}
</style>