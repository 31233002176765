<template>
    <div style="margin-right: 20px;">
        <el-card class="box-card">
            <div style="display: flex;">
                <p><el-input placeholder="请输入客户名称或联系方式" class="input-with-select" v-model="input2">
                        <el-button slot="append" icon="el-icon-search" @click="searchBtnInfo()"></el-button>
                    </el-input></p>
                <p><el-button type="primary" style="margin-left: 10px;" @click="resetInput()">重置</el-button></p>
            </div>
            <div class="card_center" style="display: flex;">
                <p>
                    <span>商务</span>
                    <el-select v-model="nickname" placeholder="请选择商务" value-key="id" @change="selectnickname" clearable
                        ref="ad">
                        <el-option v-for="item in options1" :key="item.id" :label="item.nickname" :value="item.id">
                        </el-option>
                    </el-select>
                </p>
            </div>
            <div class="card_div">
                <p><el-button type="primary" plain size="medium">导入</el-button></p>
                <p><el-button type="primary" plain size="medium">导出</el-button></p>
                <p><el-button plain size="medium" class="scxz" :disabled="multipleSelection.length == 0"
                        @click="deleteData">删除选中</el-button>
                </p>
            </div>
        </el-card>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;"
            @selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }">
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column label="客户名称" align="center">
                <template slot-scope="scope">{{ scope.row.name }}</template>
            </el-table-column>
            <el-table-column prop="business_name" label="商务" align="center">
            </el-table-column>
            <el-table-column prop="distribute_time" label="派单时间" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="expiretime" label="超时时间" show-overflow-tooltip align="center">
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[15, 30]" :page-size="15" layout="total, sizes, prev, pager, next, jumper" :total="countTotal">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { listData } from '../../api/Swgl/Jdlb'
import { delData, getstaffList, order_chaoshi } from '../../api/Swgl/Jdcsjl'
export default {
    data() {
        return {
            treedata: [],
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            tableData: [],
            selectedValue: [],
            multipleSelection: [],
            value: [],
            currentPage: 1,
            countTotal: 0,
            arr: [],
            newArr: [],
            options1: [],
            nickname: '',
            input2: ''
        }

    },
    mounted() {
        this.getData()
        this.getstaffListData()
    },
    methods: {
        handleEdit(index, row) {
            console.log(index, row);
        },
        handleDelete(index, row) {
            console.log(index, row);
        },
        // 分页
        handleSizeChange() {
            // 每页显示数量 也就是pagesize发生变化时 要重置数据，和重置页码
            this.tableData = []
            this.currentPage = 1;
            this.pageSize = 15
            this.getData()
        },
        handleCurrentChange(val) {
            // 当 页码发生变化时 重置数据
            this.tableData = []
            this.currentPage = val
            this.getData()
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        // 获取客户列表数据
        async getData() {
            // console.log(val);
            let reqData = {
                page: this.currentPage,
                state: 3,
                business_id: this.businessId,
                // search: this.input3
            }
            let res = await order_chaoshi(reqData)
            console.log(res);
            if (res.data.code == 5) {
                this.$message({
                    type: 'error',
                    message: '暂无权限访问!'
                });
                this.$router.push({ path: "/Index/kongbai" });
            }
            this.tableData = res.data.data.data
            this.countTotal = res.data.data.total
        },
        // 搜索
        async searchInput() {
            let reqData = {
                page: this.currentPage,
                state: 3,
                business_id: this.businessId,
                search: this.input3
            }
            console.log(reqData);
            let res = await listData(reqData)
            console.log(res);
            if (this.input3) {
                this.tableData = res.data.data.data
                this.countTotal = res.data.data.total
            } else {
                this.getData()
            }
            this.input3 = ""
        },
        // 重置
        resetInput() {
            this.input3 = ""
            this.$refs.ad.clearSelection();
            this.getData()
        },
        // 获取商务列表
        async getstaffListData() {
            let res = await getstaffList()
            console.log(res);
            this.options1 = res.data.data
        },
        // 获取商务列表的id
        selectnickname(id) {
            console.log(id);
            let obj = {};
            obj = this.options1.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            console.log(obj);
            this.businessId = obj.id
            console.log(this.levelId);
            this.getData()
        },
        // 多选
        handleSelectionChange(val) {
            // console.log(val);
            this.multipleSelection = val
            // console.log(this.multipleSelection);
            for (var i of this.multipleSelection) {
                // console.log(i.date);
                this.arr.push(i.records_id)
                console.log(this.arr);
            }
            this.newArr = [...new Set(this.arr)];
            console.log(this.newArr);

        },
        // 删除选中
        async deleteData(data) {
            let reqData = {
                ids: this.newArr + '',
            }
            console.log(reqData);
            this.$confirm('确定要删除该数据吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await delData(reqData)
                if (res.data.code == 1) {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getData()
                    // location.reload()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 搜索
        async searchBtnInfo() {
            let reqData = {
                page: this.currentPage,
                state: 3,
                business_id: this.businessId,
                search: this.input2
            }
            let res = await listData(reqData)
            if (this.input2) {
                this.tableData = res.data.data.data
                this.countTotal = res.data.data.total
            } else {
                this.getData()
            }
            this.input2 = ""
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.el-card__body {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    width: 1500px;
}

.box-card {
    display: flex;
    justify-content: space-between;
}

/deep/[data-v-255bf696] .el-card__body {
    display: flex;
    justify-content: space-between;

}

.card_div {
    display: flex;
    justify-content: space-around;
    text-align: right;

    p {
        padding: 0 10px;
    }
}

.block {
    text-align: right;
    line-height: 78px;
}

.card_top {
    display: flex;
    justify-content: space-between;

    p {
        margin: 0 20px;

        span {
            padding-right: 17px;
        }
    }
}

.card_bottom {
    display: flex;

    p {
        margin: 10px 25px;

        span {
            padding-right: 10px;
        }
    }
}

.name-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.el-select {
    margin-left: 10px;
}

.el-card {
    border: none;
    border-radius: 0;
}
</style>