<template>
    <div style="margin-right: 20px;">
        <el-card class="box-card">
            <div class="block">
                <span class="demonstration">选择月份</span>
                <el-date-picker v-model="value2" value-format='yyyy-MM' type="month" placeholder="选择月"
                    @change="selectMonth()">
                </el-date-picker>
            </div>
            <div class="card_div">
                <p><el-button type="primary" plain size="medium">导入</el-button></p>
                <p><el-button type="primary" plain size="medium">导出</el-button></p>
                <p><el-button type="primary" plain size="medium" @click="addcenterDialogVisible = true">新增月目标</el-button>
                </p>
                <p>
                    <el-button plain size="medium" class="scxz" :disabled="multipleSelection.length == 0"
                        @click="deleteData">删除选中</el-button>
                </p>
            </div>
        </el-card>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;"
            @selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }">
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column prop="user.nickname" label="姓名" width="180" align="center">
            </el-table-column>
            <el-table-column prop="deparment" label="部门" width="180" align="center">
            </el-table-column>
            <el-table-column prop="day_count" label="日目标单数" width="180" align="center">
            </el-table-column>
            <el-table-column prop="day_complete" label="日完成数" width="180" align="center">
            </el-table-column>
            <el-table-column prop="month_count" label="月目标单数" width="180" align="center">
            </el-table-column>
            <el-table-column prop="month_complete" label="月完成数" width="180" align="center">
            </el-table-column>
            <el-table-column prop="createtime" label="创建时间" width="180" align="center">
            </el-table-column>
            <el-table-column prop="updatetime" label="修改时间" width="180" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" @click="editData(scope.row)">修改</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[15, 30]" :page-size="15" layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
        </div>
        <!-- 新增月目标弹框 -->
        <div class="add_dialog">
            <el-dialog title="新增月目标" :visible.sync="addcenterDialogVisible" width="388px" center append-to-body
                @close="closeAdd">
                <el-form :model="form">
                    <el-form-item label="选择员工" :label-width="formLabelWidth">
                        <el-select v-model="nickname" placeholder="请选择" value-key="id" @change="selectnickname" clearable
                            ref="ad">
                            <el-option v-for="item in options1" :key="item.id" :label="item.nickname" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="日目标" :label-width="formLabelWidth">
                        <el-input v-model="form.day_count" autocomplete="off" placeholder="请输入日目标"></el-input>
                    </el-form-item>
                    <el-form-item label="月目标" :label-width="formLabelWidth">
                        <el-input v-model="form.month_count" autocomplete="off" placeholder="请输入月目标"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addcenterDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addymbdata()">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 月目标设置 -->
        <div class="add_dialog">
            <el-dialog title="月目标设置" :visible.sync="editcenterDialogVisible" width="388px" center append-to-body
                @close="closeAdd">
                <el-form :model="form">
                    <el-form-item label="选择员工" :label-width="formLabelWidth">
                        <el-select v-model="nickname" placeholder="请选择" value-key="id" @change="selectnickname" clearable
                            ref="ad">
                            <el-option v-for="item in options1" :key="item.id" :label="item.nickname" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="日目标" :label-width="formLabelWidth">
                        <el-input v-model="form.day_count" autocomplete="off" placeholder="请输入日目标"></el-input>
                    </el-form-item>
                    <el-form-item label="月目标" :label-width="formLabelWidth">
                        <el-input v-model="form.month_count" autocomplete="off" placeholder="请输入月目标"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="editcenterDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="sureEdit()">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { ymbdata, addymb, editymb, delData, operateList } from '../../api/yunyingguanli/ymb'
import { getstaffList } from '../../api/Swgl/Jdcsjl'
import { timestampToTime } from "../../utils/publicFun"
export default {
    data() {
        return {
            tableData: [],
            value: true,
            currentPage: 1,
            multipleSelection: [],
            addcenterDialogVisible: false,
            editcenterDialogVisible: false,
            value2: '',
            form: {
                name: '',
                day_count: '',
                month_count: ''
            },
            formLabelWidth: '120px',
            month: '',
            options1: [],
            nickname: '',
            uid: '',
            arr: [],
            newArr: [],
            totalCount: 0
        }
    },
    mounted() {
        this.getymbdata()
        this.getoperateList()
    },
    methods: {
        // 清空输入框
        closeAdd() {
            this.form = {
                name: '',
                day_count: '',
                month_count: ''
            }
            this.nickname = ""
        },
        // 分页
        handleSizeChange() {
            // 每页显示数量 也就是pagesize发生变化时 要重置数据，和重置页码
            this.tableData = []
            this.currentPage = 1;
            this.pageSize = 15
            this.getymbdata()
        },
        handleCurrentChange(val) {
            // 当 页码发生变化时 重置数据
            this.tableData = []
            this.currentPage = val
            this.getymbdata()
        },
        // 全选
        // 这个函数是组件提供的 复选框 选中时的事件函数
        // 当复选框有被选中的情况下，会传进来被选的数据 是以数组的形式存储的 
        // this.multipleSelection 来接收这个值
        handleSelectionChange(val) {
            console.log(val);
            this.multipleSelection = val;
            console.log(this.multipleSelection);
        },
        // 获取月目标列表数据
        async getymbdata() {
            let reqData = {
                page: this.currentPage,
                month: this.month
            }
            let res = await ymbdata(reqData)
            console.log(res);
            this.tableData = res.data.data.data
            this.setData(this.tableData)
            this.totalCount = res.data.data.total
            if (res.data.code == 5) {
                this.$message({
                    type: 'error',
                    message: '暂无权限访问!'
                });
                this.$router.push({ path: "/Index/kongbai" });
            }
        },
        setData(arr) {
            for (let i = 0; i < arr.length; i++) {
                arr[i].createtime = !arr[i].createtime ? '' : timestampToTime(arr[i].createtime * 1000)
                arr[i].updatetime = !arr[i].updatetime ? '' : timestampToTime(arr[i].updatetime * 1000)
            }
        },
        // 获取商务列表
        async getoperateList() {
            let res = await operateList()
            console.log(res);
            this.options1 = res.data.data
        },
        // 获取商务列表的id
        selectnickname(id) {
            console.log(id);
            let obj = {};
            obj = this.options1.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            console.log(obj);
            this.uid = obj.id
            // this.getListData()
        },
        // 新增月目标数据
        async addymbdata() {

            let reqData = {
                uid: this.uid,
                day_count: this.form.day_count,
                month_count: this.form.month_count
            }
            let res = await addymb(reqData)
            if (res.data.code == 0) {
                this.$message({
                    message: res.data.msg,
                    type: "error"
                })

            }
            if (res.data.code == 1) {
                this.$message({
                    message: '添加成功',
                    type: "success"
                });
                this.getymbdata()
                this.addcenterDialogVisible = false
            }
            console.log(res);
        },
        // 编辑获取信息
        editData(data) {
            this.editcenterDialogVisible = true
            console.log(data);
            this.nickname = data.user.nickname
            this.id = data.id
            this.form.day_count = data.day_count
            this.form.month_count = data.month_count
        },
        // 确定编辑
        async sureEdit() {
            let reqData = {
                uid: this.uid,
                day_count: this.form.day_count,
                month_count: this.form.month_count,
                id: this.id
            }
            let res = await editymb(reqData)
            console.log(res);
            if (res.data.code == 0) {
                this.$message({
                    message: res.data.msg,
                    type: "error"
                })

            }
            if (res.data.code == 1) {
                this.$message({
                    message: '修改成功',
                    type: "success"
                });
                this.getymbdata()
                this.editcenterDialogVisible = false
            }

        },
        // 多选
        handleSelectionChange(val) {
            // console.log(val);
            this.multipleSelection = val
            // console.log(this.multipleSelection);
            for (var i of this.multipleSelection) {
                console.log(i);
                this.arr.push(i.id)
                console.log(this.arr);
            }
            this.newArr = [...new Set(this.arr)];
            console.log(this.newArr);
        },
        // 删除选中
        async deleteData(data) {
            let reqData = {
                ids: this.newArr + '',
            }
            console.log(reqData);
            this.$confirm('确定要删除该数据吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await delData(reqData)
                if (res.data.code == 1) {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getymbdata()
                    // location.reload()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 选择月份搜索
        selectMonth() {
            this.month = this.value2
            this.getymbdata()
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.el-card__body {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    width: 1500px;
}

.el-card {
    border: none;
    border-radius: 0;
}

.el-card.is-always-shadow {
    box-shadow: none;
}

.box-card {
    display: flex;
    justify-content: space-between;
}

/deep/[data-v-255bf696] .el-card__body {
    display: flex;
    justify-content: space-between;

}

.card_div {
    display: flex;
    justify-content: space-around;
    text-align: right;

    p {
        padding: 0 10px;
    }
}

.block {
    text-align: right;
    line-height: 70px;
    margin-left: 10px;

    .el-input {
        margin-left: 10px;
    }
}

.card_div[data-v-7df24cc8] {
    line-height: 70px;
}
</style>