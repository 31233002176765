<template>
    <div style="margin-right: 20px;">
        <el-card class="box-card">
            <div style="display: flex;">
                <p><el-input placeholder="请输入客户名称或联系方式" v-model="input2" class="input-with-select">
                        <el-button slot="append" icon="el-icon-search" @click="searchBtnInfo()"></el-button>
                    </el-input></p>
                <p><el-button type="primary" style="margin-left: 10px;">重置</el-button></p>
            </div>
            <div class="card_div">
                <p><el-button type="primary" plain size="medium">导入</el-button></p>
                <p><el-button type="primary" plain size="medium">导出</el-button></p>
                <p><el-button plain size="medium" class="scxz" :disabled="multipleSelection.length == 0">删除选中</el-button>
                </p>
            </div>
        </el-card>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;"
            @selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }">
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column label="合同编号" width="160" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.contract_no }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.contract_no }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="客户名称" width="160" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.customer_name }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.customer_name }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="项目名称" width="160" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.project_name }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.project_name }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="项目类型" width="160" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.category_name }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.category_name }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="款期" width="160" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.installment }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.installment }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="回款金额" width="160" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.money }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.money }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="回款时间" width="170" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.returntime }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.returntime }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" width="170" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.createtime }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.createtime }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="附件" width="150" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" @click="viewFJ(scope.row)">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" @click="pass(scope.row)">通过</el-button>
                    <el-button size="mini" @click="refuse(scope.row)">拒绝</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[15, 30]" :page-size="15" layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
        </div>
        <!-- 拒绝弹框 -->
        <div class="add_dialog">
            <el-dialog title="拒绝原因" :visible.sync="jjcenterDialogVisible" width="30%" center append-to-body>
                <el-form :model="form">
                    <el-form-item label="请输入原因" :label-width="formLabelWidth">
                        <el-input type="text" placeholder="请输入文字内容" v-model="form.reason" style="width: 300px;">
                        </el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="jjcenterDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="sureRefuse()">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 查看弹出框 -->
        <el-dialog title="附件详情" :visible.sync="viewFJdialogFormVisible" append-to-body>
            <el-form :model="form">
                <el-upload action="httpsa://crm.nanjingyunpeng.cn/api/v1/customer/uploads" list-type="picture-card"
                    :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :file-list="fileData" :headers="token">
                    <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="viewFJdialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="viewFJdialogFormVisible = false">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getProcessList, shenheProcess } from '../../api/Htgl/hksh'
export default {
    data() {
        return {
            tableData: [],
            value: true,
            currentPage: 1,
            jjcenterDialogVisible: false,
            formLabelWidth: '120px',
            multipleSelection: [],
            viewFJdialogFormVisible: false,
            form: {
                reason: ''
            },
            input2: '',
            totalCount: 0,
            fileData: '',
            dialogImageUrl: '',
            dialogVisible: false,
        }
    },
    mounted() {
        this.getProcessListData()
    },
    created() {
        let temp = window.localStorage.getItem("admin-token")
        this.token = {
            "token": temp
        }
        console.log(this.token)
    },
    methods: {
        // 分页
        handleSizeChange() {
            // 每页显示数量 也就是pagesize发生变化时 要重置数据，和重置页码
            this.tableData = []
            this.currentPage = 1;
            this.pageSize = 15
            this.getProcessListData()
        },
        handleCurrentChange(val) {
            // 当 页码发生变化时 重置数据
            this.tableData = []
            this.currentPage = val
            this.getProcessListData()
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 全选
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 获取列表数据
        async getProcessListData() {
            let reqData = {
                page: this.currentPage
            }
            let res = await getProcessList(reqData)
            console.log(res);
            if (res.data.code == 5) {
                this.$message({
                    type: 'error',
                    message: '暂无权限访问!'
                });
                this.$router.push({ path: "/Index/kongbai" });
            }
            this.tableData = res.data.data.data
            this.totalCount = res.data.data.total

        },
        // 通过
        async pass(data) {
            this.$confirm('确定要通过吗？', '提示', {
                type: 'warning',
                beforeClose: async (action, instance, done) => {
                    if (action === "confirm") {
                        // 调取接口
                        let reqData = {
                            id: data.id,
                            type: 1
                        }
                        let res = await shenheProcess(reqData)
                        if (res.data.code == 1) {
                            this.$message({
                                type: 'success',
                                message: '已通过!'
                            });
                            this.getProcessListData()
                            done();
                        }
                    } else {
                        this.$message({
                            type: 'info',
                            message: '操作失败'
                        });
                        done();
                    }
                },

            })
        },
        // 获取拒绝
        refuse(data) {
            console.log(data);
            this.ids = data.id
            this.jjcenterDialogVisible = true
        },
        // 拒绝
        async sureRefuse() {
            let reqData = {
                id: this.ids,
                type: 2,
                reason: this.form.reason
            }
            let res = await shenheProcess(reqData)
            console.log(res);
            if (res.data.code == 1) {
                this.$message({
                    type: 'error',
                    message: '已拒绝'
                });
            }
            this.jjcenterDialogVisible = false
        },
        // 查看
        viewFJ(data) {
            console.log(data);
            this.fileData = [{ url: data.file }]
            console.log(this.fileData);
            this.viewFJdialogFormVisible = true
        },
        // 搜索
        async searchBtnInfo() {
            let reqData = {
                page: this.currentPage,
                search: this.input2
            }
            let res = await getProcessList(reqData)
            if (this.input2) {
                this.tableData = res.data.data.data
                this.totalCount = res.data.data.total
            } else {
                this.getProcessListData()
            }
            this.input2 = ""
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.el-card__body {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    width: 1500px;
}

.el-card {
    border: none;
    border-radius: 0;
}

.el-card.is-always-shadow {
    box-shadow: none;
}

.box-card {
    display: flex;
    justify-content: space-between;
}

/deep/[data-v-255bf696] .el-card__body {
    display: flex;
    justify-content: space-between;

}

.card_div {
    display: flex;
    justify-content: space-around;
    text-align: right;

    p {
        padding: 0 10px;
    }
}

.block {
    text-align: right;
    line-height: 78px;
}

.card_top {
    display: flex;
    justify-content: space-between;

    p {
        margin: 0 20px;

        span {
            padding-right: 17px;
        }
    }
}

.card_bottom {
    display: flex;

    p {
        margin: 10px 25px;

        span {
            padding-right: 10px;
        }
    }
}

.name-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}
</style>