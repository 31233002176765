<template>
    <div style="margin-right: 20px;">
        <el-card class="box-card">
            <div style="display: flex;">
                <p><el-input placeholder="请输入客户名称或联系方式" v-model="input2" class="input-with-select">
                        <el-button slot="append" icon="el-icon-search" @click="searchBtnInfo()"></el-button>
                    </el-input></p>
                <p><el-button type="primary" style="margin-left: 10px;" @click="resetInput">重置</el-button></p>
            </div>
            <div class="card_div">
                <p><el-button type="primary" plain size="medium">导入</el-button></p>
                <p><el-button type="primary" plain size="medium">导出</el-button></p>
                <p><el-button plain size="medium" class="scxz" :disabled="multipleSelection.length == 0"
                        @click="deleteData">删除选中</el-button>
                </p>
            </div>
        </el-card>
        <el-card>
            <div style="padding:10px;" class="card_name">
                <div id="card_left" :style="{ 'max-height': status ? textHeight : '' }" :class="{ statusText: status }"
                    class="titleText" ref="desc">
                    <div class="left_top">
                        <p><span>客户筛选</span>
                            <el-select v-model="filter" placeholder="请选择" value-key="filter" @change="selectFilter">
                                <el-option v-for="item in options1" :key="item.filter" :label="item.name"
                                    :value="item.filter">
                                </el-option>
                            </el-select>
                        </p>
                        <p><span>客户级别</span>
                            <el-select v-model="level" placeholder="请选择" value-key="filter" @change="selectLevel">
                                <el-option v-for="item in options2" :key="item.level" :label="item.name1"
                                    :value="item.level">
                                </el-option>
                            </el-select>
                        </p>
                        <p><span>项目类型</span>
                            <el-select v-model="project_type" placeholder="请选择" value-key="id" @change="selectproject_type">
                                <el-option v-for="item in options3" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </p>
                        <p><span>&emsp;创建人</span>
                            <el-select v-model="creator" placeholder="请选择" value-key="id" @change="selectcreator">
                                <el-option v-for="item in options5" :key="item.id" :label="item.nickname" :value="item.id">
                                </el-option>
                            </el-select>
                        </p>
                    </div>
                    <div class="left_bottom">
                        <p><span>创建时间</span>
                            <el-date-picker v-model="createTime" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels @change="getDate1()"
                                value-format="timestamp" :picker-options="{
                                        disabledDate(time) { // 设置禁用状态，参数为当前日期，要求返回 Boolean
                                            return time.getTime() > Date.now();
                                        }
                                    }">
                            </el-date-picker>
                        </p>
                        <p><span>负责商务</span>
                            <el-select v-model="nickname1" placeholder="请选择" value-key="id" @change="selectnickname1">
                                <el-option v-for="item in options9" :key="item.id" :label="item.nickname" :value="item.id">
                                </el-option>
                            </el-select>
                        </p>
                        <p><span>接单时间</span>
                            <el-date-picker v-model="jiedanTime" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels @change="getDate2()"
                                value-format="timestamp" :picker-options="{
                                        disabledDate(time) { // 设置禁用状态，参数为当前日期，要求返回 Boolean
                                            return time.getTime() > Date.now();
                                        }
                                    }">
                            </el-date-picker>
                        </p>
                    </div>

                </div>
                <div id="card_right" v-if="idShowText" @click="status = !status" :class="{ openSpan: status }"
                    class="openClose">
                    {{ status ? "展开" : "收起" }}
                    {{ status ? '﹀' : '︿' }}

                </div>

            </div>
        </el-card>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;"
            @selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }">
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column label="客户名称" width="120" align="center">
                <template slot-scope="scope">
                    <!-- <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.name }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.name }}
                        </div>
                    </el-popover> -->
                    {{ scope.row.name }}

                </template>
            </el-table-column>
            <el-table-column label="联系方式" width="120" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.mobile }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.mobile }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="微信/QQ" width="120" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.third_contact }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.third_contact }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="客户级别" width="120" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.level }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.level }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="项目类型" width="120" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.project_name }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.project_name }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="备注" width="120" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.remark }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.remark }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="跟进记录" width="120" align="center">
                <template slot-scope="scope">
                    <el-popover placement="top" width="400" trigger="click">
                        <div slot="reference" class="name-wrapper" @click="openDetails(scope.row.id)">{{ scope.row.follow[0]
                            ? scope.row.follow[0].createtime :
                            ""
                        }}
                            {{ scope.row.follow[0] ? scope.row.follow[0].desc : "" }}
                        </div>
                        <el-timeline :reverse="reverse">
                            <el-timeline-item v-for="(item, index) in scope.row.follow || []" :key="index">
                                <p>{{ item.admin_nickname }}{{ item.createtime }}</p>
                                <p>{{ item.desc }}</p>
                            </el-timeline-item>
                        </el-timeline>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="读取记录" width="120" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p v-for="item in scope.row.read_log">{{ item.add_time }}&emsp;{{ item.u_nickname }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.read_log[0] ? scope.row.read_log[0].add_time : '' }}{{ scope.row.read_log[0] ?
                                scope.row.read_log[0].u_nickname : '' }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="创建人" width="120" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.admin_nickname }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.admin_nickname }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" width="120" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.createtime }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.createtime }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="商务" width="120" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.business_nicename }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.business_nicename }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="投入时间" width="120" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.sea_time }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.sea_time }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <!-- <el-button size="mini" @click="fenpeiList(scope.row)">分配</el-button> -->
                    <el-button size="mini" @click="open(scope.row)">领取</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[15, 30]" :page-size="15" layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
        </div>
        <!-- 分配客户 -->
        <div class="add_dialog">
            <el-dialog title="分配" :visible.sync="fenpeicenterDialogVisible" width="388px" center append-to-body>
                <el-form :model="form">
                    <el-form-item label="选择部门" :label-width="formLabelWidth">
                        <el-select v-model="deparement" placeholder="请选择" value-key="id" @change="selectDeparement">
                            <el-option v-for="item in deparementList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择员工" :label-width="formLabelWidth">
                        <el-select v-model="nickname" placeholder="请选择" value-key="id" @change="selectnickname" clearable
                            ref="ad">
                            <el-option v-for="item in userList" :key="item.id" :label="item.username" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="fenpeicenterDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="sureFenpei">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { getData, delData } from '../../api/Khgl/Khlb'
import { department, } from '../../api/renyuanguanli/bumenlist';
import { getadmin, receiving, fenpei } from '../../api/Khgl/ghkh'
import { getproject, getcreator } from '../../api/common/common'
import { getstaffList } from '../../api/Swgl/Jdcsjl'
export default {
    data() {
        return {
            reverse: '',
            filter: "",
            level: '',
            from: '',
            project_type: '',
            deparement: '',
            nickname: '',
            userList: [],
            options: [],
            deparementList: [],
            createTime: '',
            jiedanTime: '',
            options9: [],
            input2: '',
            options4: [
                {
                    from: 1,
                    name: "淘宝"
                },
                {
                    from: 2,
                    name: "百度"
                },
            ],
            options1: [
                {
                    filter: 0,
                    name: "全部"
                },
                {
                    filter: 1,
                    name: "我负责"
                },
                {
                    filter: 2,
                    name: "我的下属负责"
                },
            ],
            options2: [
                {
                    level: "",
                    name: "",
                    name1: '全部'
                },
                {
                    level: 0,
                    name: "A",
                    name1: 'A（准客户）'
                },
                {
                    level: 1,
                    name: "B",
                    name1: 'B（意向客户）'
                },
                {
                    level: 2,
                    name: "C",
                    name1: 'C（一般客户）'
                },
                {
                    level: 3,
                    name: "D",
                    name1: 'D（有效客户）'
                },
                {
                    level: 4,
                    name: "K",
                    name1: 'K（低效客户）'
                },
                {
                    level: 5,
                    name: "P",
                    name1: 'P（违法客户）'
                }
            ],
            options3: [],
            tableData: [],
            value: true,
            currentPage: 1,
            fenpeicenterDialogVisible: false,
            textHeight: null,
            status: false,
            idShowText: false,
            value1: '',
            crr1: [],
            crr2: [],
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            formLabelWidth: '120px',
            textarea2: '',
            totalCount: 0,
            multipleSelection: [],
            arr: [],
            options5: [],
            newArr: [],
            nickname1: '',
            creator: ''
        }
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.calculateText();
            }, 300);
        });
        this.getListData()
        this.getdepartmentdata()
        this.getcreatorData()
        this.getproject_typeData()
        this.getstaffListData()
    },
    methods: {
        // 分页
        handleSizeChange() {
            // 每页显示数量 也就是pagesize发生变化时 要重置数据，和重置页码
            this.tableData = []
            this.currentPage = 1;
            this.pageSize = 15
            this.getListData()
        },
        handleCurrentChange(val) {
            // 当 页码发生变化时 重置数据
            this.tableData = []
            this.currentPage = val
            this.getListData()
        },
        // 全选
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 展示与收起
        calculateText() {
            // 这是默认两行数据的高度，一行的高度可以自定义 可以*3 *4达到三行或者四行显示展示和收起的效果
            let twoHeight = 20 * 2;
            this.textHeight = `${twoHeight}px`;
            let curHeight = this.$refs.desc.offsetHeight;
            console.log("curHeight", curHeight);
            console.log("twoHeight", twoHeight);
            if (curHeight > twoHeight) {
                this.status = true;
                this.idShowText = true;
            } else {
                this.status = false;
                this.idShowText = false;
            }
        },
        // 客户筛选
        selectFilter(id) {
            console.log(id);
            let obj = {};
            obj = this.options1.find((item) => {
                return item.filter === id; //筛选出匹配数据
            });
            console.log(obj);
            this.filterId = obj.filter
            console.log(this.id);
            this.getListData()
        },
        // 客户级别
        selectLevel(id) {
            console.log(id);
            let obj = {};
            obj = this.options2.find((item) => {
                return item.level === id; //筛选出匹配数据
            });
            console.log(obj);
            this.levelId = obj.level
            this.levalName = obj.name
            console.log(this.levelId);
            this.getListData()
        },
        // 获取客户项目类型
        async getproject_typeData() {
            let res = await getproject()
            console.log(res.data.data);
            let obj = {
                id: '',
                name: '全部'
            }
            this.options3 = res.data.data
            this.options3.unshift(obj)
            this.btnlist = res.data.data
        },
        // 获取项目类型下拉框id
        selectproject_type(id) {
            console.log(id);
            let obj = {};
            obj = this.options3.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            console.log(obj);
            this.project_typeID = obj.id
            console.log(this.project_typeID);
            this.getListData()
        },
        // 获取项目类型按钮id
        getProjectId(id) {
            console.log(id);
            this.project_id = id
        },
        // 获取客户列表数据
        async getListData() {
            let reqData = {
                page: this.currentPage,
                filter: this.filterId,
                level: this.levalName,
                project_type: this.project_typeID,
                from: this.fromId,
                business_id: this.businessId,
                createtime: this.crr1,
                catchtime: this.crr2,
                creator_id: this.creator,
                sea: 1
            }
            let res = await getData(reqData)
            console.log(res);
            if (res.data.code == 5) {
                this.$message({
                    type: 'error',
                    message: '暂无权限访问!'
                });
                this.$router.push({ path: "/Index/kongbai" });
            }
            this.tableData = res.data.data.data
            // console.log(this.tableData);
            this.totalCount = res.data.data.total
        },
        // 获取商务列表
        async getstaffListData() {
            let res = await getstaffList()
            let obj2 = {
                id: '',
                nickname: '全部'
            }
            console.log(res);
            this.options9 = res.data.data
            this.options9.unshift(obj2)
        },
        // 获取商务列表的id
        selectnickname1(id) {
            console.log(id);
            let obj = {};
            obj = this.options9.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            console.log(obj);
            this.businessId = obj.id
            // console.log(this.levelId);
            this.getListData()
        },
        // 获取创建人
        async getcreatorData() {
            let res = await getcreator()
            let obj1 = {
                id: '',
                nickname: '全部'
            }
            console.log(res.data.data);
            this.options5 = res.data.data
            this.options5.unshift(obj1)
        },
        // 创建人
        selectcreator(id) {
            console.log(id);
            let obj = {};
            obj = this.options5.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            console.log(obj);
            this.creator = obj.id
            console.log(this.creator);
            this.getListData()
        },
        // 地区部门数据列表
        async getdepartmentdata() {
            let reqData = {
                id: 0
            }
            let res = await department(reqData)
            console.log(res);
            this.deparementList = res.data.data
            console.log(this.deparementList);
        },
        selectDeparement(id) {
            console.log(id);
            // let obj = {};
            // obj = this.userList.find((item) => {
            //     return item.id === id; //筛选出匹配数据
            // });
            // console.log(obj);
            this.deparementId = id
            console.log(this.deparementId);
            this.getadminList()
        },
        // 获取商务列表
        async getadminList() {
            let reqData = {
                department_id: this.deparementId
            }
            let res = await getadmin(reqData)
            console.log(res);
            this.userList = res.data.data.data
        },
        // 获取商务列表的id
        selectnickname(id) {
            console.log(id);
            let obj = {};
            obj = this.userList.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            console.log(obj);
            this.uid = obj.id
            // this.getListData()
        },
        // 客户来源
        selectfrom(id) {
            console.log(id);
            let obj = {};
            obj = this.options4.find((item) => {
                return item.from === id; //筛选出匹配数据
            });
            console.log(obj);
            this.fromId = obj.from
            console.log(this.fromId);
            this.getListData()
        },
        // 分配
        fenpeiList(data) {
            console.log(data);
            this.fenpeicenterDialogVisible = true
            this.id = data.id
        },
        async sureFenpei() {
            let reqData = {
                type: 2,
                uid: this.uid,
                customer_id: this.id
            }
            let res = await fenpei(reqData)
            console.log(res);
            this.fenpeicenterDialogVisible = false
            if (res.data.code == 1) {
                this.$message({
                    type: 'success',
                    message: '分配成功!'
                });
                this.getListData()
            }
        },
        // 领取
        async open(data) {
            this.$confirm('确定要领取该客户吗？', '提示', {
                type: 'warning',
                beforeClose: async (action, instance, done) => {
                    if (action === "confirm") {
                        // 调取接口
                        let reqData = {
                            customer_id: data.id,
                        }
                        let res = await receiving(reqData)
                        if (res.data.code == 1) {
                            this.$message({
                                type: 'success',
                                message: '领取成功!'
                            });
                            this.getListData()
                            done();
                        }
                    } else {
                        this.$message({
                            type: 'info',
                            message: '领取失败'
                        });
                        done();
                    }
                },

            })
        },
        // 全选
        handleSelectionChange(val) {
            this.multipleSelection = val;
            for (var i of this.multipleSelection) {
                console.log(i);
                this.arr.push(i.id)
                console.log(this.arr);
            }
            this.newArr = [...new Set(this.arr)];
            console.log(this.newArr);
        },
        // 删除选中
        async deleteData(data) {
            let reqData = {
                ids: this.newArr + '',
            }
            console.log(reqData);
            this.$confirm('确定要删除该数据吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await delData(reqData)
                if (res.data.code == 1) {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getListData()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 获取创建时间
        getDate1() {
            // console.log(this.createTime);
            this.createtime = this.createTime
            let brr1 = [];
            for (var i of this.createtime) {
                brr1.push(i / 1000)
            }
            this.crr1 = brr1.join('-')
            console.log(this.crr1);
            this.getListData()
        },
        // 获取接单时间
        getDate2() {
            console.log(this.jiedanTime)
            // console.log(this.createTime);
            this.jiedantime = this.jiedanTime
            let brr2 = [];
            for (var i of this.jiedantime) {
                brr2.push(i / 1000)
            }
            this.crr2 = brr2.join('-')
            console.log(this.crr2);
            this.getListData()
        },
        // 重置输入框
        async resetInput() {
            this.input2 = ""
            this.level = ""
            this.project_type = ""
            this.from = ""
            this.createtime = ""
            this.catchtime = ""
            this.creator = ""
            this.nickname = ""
            let reqData = {
                page: this.currentPage,
                filter: this.filterId,
                sea: 1
            }
            let res = await getData(reqData)
            console.log(res);
            this.tableData = res.data.data.data
            this.totalCount = res.data.data.total
        },
        // 搜索
        async searchBtnInfo() {
            let reqData = {
                page: this.currentPage,
                filter: this.filterId,
                level: this.levelId,
                project_type: this.project_typeID,
                from: this.fromId,
                business_id: this.businessId,
                createtime: this.crr1,
                catchtime: this.crr2,
                creator_id: this.creator,
                sea: 1,
                search: this.input2
            }
            let res = await getData(reqData)
            if (this.input2) {
                this.tableData = res.data.data.data
                this.totalCount = res.data.data.total
            } else {
                this.getListData()
            }
            this.input2 = ""
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.el-card__body {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    width: 1500px;
}

.el-card {
    border: none;
    border-radius: 0;
}

.el-card.is-always-shadow {
    box-shadow: none;
}

.box-card {
    display: flex;
    justify-content: space-between;
}

/deep/[data-v-255bf696] .el-card__body {
    display: flex;
    justify-content: space-between;

}

.card_name {
    display: flex;
    justify-content: space-between;
}

.card_div {
    display: flex;
    justify-content: space-around;
    text-align: right;

    p {
        padding: 0 10px;
    }
}

.block {
    text-align: right;
    line-height: 78px;
}

.left_top {
    display: flex;
    justify-content: space-between;

    p {
        margin: 0 20px;

        span {
            padding-right: 17px;
        }
    }
}

#card_left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.left_bottom {
    display: flex;
    // margin-left: 20px;

    p {
        margin: 10px 25px;

        span {
            padding-right: 10px;
        }
    }
}

.name-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.card_address {
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    background-color: #FFF;
    overflow: hidden;
    color: #303133;
    transition: .3s;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    display: flex;

    p {
        margin: 10px 15px;
    }
}

.card_name {
    position: relative;

    .titleText {
        font-weight: bold;
        font-size: 18px;
    }

    .openClose {
        font-size: 14px;
        // color: #25dbe6;
        color: skyblue;
        cursor: pointer;
    }

    .openSpan {
        position: absolute;
        right: -50px;
        cursor: pointer;
    }

    .statusText {
        overflow: hidden;
        display: block;
    }

    .statusText:after {
        position: absolute;
        bottom: 0;
        width: 100px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.2), #fff 45%);
    }
}

.el-dialog {
    i {
        color: orange;
        font-size: 18px;
        padding-right: 7px;
    }
}

.sx_dialog .el-dialog .el-date-editor.el-input {
    width: 122px;

    ::placeholder {
        font-size: 12px;
    }
}

.dialog_bottom {
    display: flex;
}

.el-textarea {
    width: 575px;
    margin-left: 8px;
}

.dialog_bottom {
    margin-left: 64px;
}

label {
    width: 60px;
}

.dialog_top {
    display: flex;
    justify-content: space-around;
}

.tj_dialog {
    .el-dialog {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}

.dialog_right .btn {
    margin-left: 10px;
    width: 250px;
}

.btn_elbtn {
    width: 300px;

    .el-button+.el-button {
        margin-left: 0;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

/deep/.left_bottom[data-v-6bfd8bdf] {
    margin-left: 20px;
}

/deep/.el-date-editor.el-input {
    width: 200px;
}
</style>