import { request } from "../../utils/request"
// 地区部门数据列表
export function listData(data) {
    return request({
        url: '/business/order',
        method: 'post',
        data,
    })
}
// 商务接单
export function orderReceiving(data) {
    return request({
        url: '/business/order_receiving',
        method: 'post',
        data,
    })
}