<template>
    <div style="margin-right: 20px;">
        <div class="card_address">
            <div class="select_area">选择地区</div>
            <div :class="typeIndex == index ? 'type1' : 'type'" v-for=" (item, index) in areaInfo" :key="index">
                <div @click="changeType(item.id, index)">{{ item.name }}</div>
            </div>
        </div>
        <el-card class="box-card" style="margin-top: 20px;">
            <div style="display: flex;">
                <p><el-input placeholder="请输入客户名称或联系方式" v-model="input2" class="input-with-select">
                        <el-button slot="append" icon="el-icon-search" @click="searchBtnInfo()"></el-button>
                    </el-input></p>
                <p><el-button type="primary" style="margin-left: 10px;">重置</el-button></p>
            </div>
            <div class="card_div">
                <p><el-button type="primary" plain size="medium" @click="tiaozheng" v-if="this.type == 0">调整顺序</el-button>
                </p>
                <div v-if="this.type == 1" style="display: flex;">
                    <p><el-button type="primary" plain size="medium" @click="sureData">保存</el-button></p>
                    <p><el-button type="primary" plain size="medium" @click="quxiao">取消</el-button></p>
                </div>
            </div>
        </el-card>
        <el-table :data="tableData" style="width: 100%;" row-key="id" class="no-table">
            <el-table-column label="">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.name }}</p>
                        <div slot="reference" class="name-wrapper">
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="姓名">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.nickname }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.nickname }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="性别">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p v-if="scope.row.gender == 0">男</p>
                        <p v-if="scope.row.gender == 1">女</p>
                        <div slot="reference" class="name-wrapper" v-if="scope.row.gender == 0">
                            男
                        </div>
                        <div slot="reference" class="name-wrapper" v-if="scope.row.gender == 1">
                            女
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="联系方式">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.mobile }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.mobile }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <div>
                        <el-switch v-model="scope.row.switch" active-color="green" inactive-color="red"
                            @change="changeswitch($event, scope.row.id)" :active-value="1" :inactive-value="0">
                        </el-switch>
                    </div>
                </template>
            </el-table-column>
            <el-table-column v-if="this.type == 1">
                <template slot-scope="scope">
                    <div class="changeIcon">
                        <i class="el-icon-s-operation"></i>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[15, 30]" :page-size="15" layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { department, } from '../../api/renyuanguanli/bumenlist';
import { getData, dragSort, switchChange } from '../../api/Swgl/Jdsxgl'
import Sortable from "sortablejs"
export default {
    data() {
        return {
            tableData: [],
            value: '',
            input2: '',
            areaInfo: [],
            currentPage: 1,
            totalCount: 0,
            areaId: '',
            type: 0,
            oldData: '',
            newData: '',
            typeIndex: 0,
            AreaID: 0,
            newArr: [],
            newCrr: [],

            tableData_arr: [],
            tableObject: null
        }
    },
    mounted() {
        this.getdepartmentdata()
        this.getListData(1)
        // this.typeIndex = 3
        // this.columnDrop()
    },
    computed: {

    },
    methods: {
        tiaozheng() {
            this.type = 1
            this.rowDrop()
        },
        // 取消
        quxiao() {
            this.type = 0
            // this.$destroy();
            this.tableObject.destroy()
            this.rowDrop('.no-table')
            this.tableData = []
            this.getListData()

        },
        // 拖拽排序
        rowDrop(notable) {
            let tbody = document.querySelector('.el-table__body-wrapper tbody')
            let _this = this

            this.tableObject = Sortable.create(tbody, {
                group: {
                    name: 'words',
                    pull: true,
                    put: true
                },
                animation: 150, // ms, number 单位：ms，定义排序动画的时间
                filter: toString(notable),
                onEnd: ({ newIndex, oldIndex }) => { // 结束拖拽
                    //获取当前拖动数据,并删除原下标的数据
                    const currRow = this.tableData.splice(oldIndex, 1)[0]
                    //在新下标位置插入拖动数据
                    this.tableData.splice(newIndex, 0, currRow)
                }
            })
        },

        // 分页
        handleSizeChange() {
            // 每页显示数量 也就是pagesize发生变化时 要重置数据，和重置页码
            this.tableData = []
            this.currentPage = 1;
            this.pageSize = 15
            this.getListData()
        },
        handleCurrentChange(val) {
            // 当 页码发生变化时 重置数据
            this.tableData = []
            this.currentPage = val
            this.getListData()
        },
        // 地区部门数据列表
        async getdepartmentdata() {
            let reqData = {
                id: 0
            }
            let res = await department(reqData)
            console.log(res);
            this.areaInfo = res.data.data
            console.log(this.areaInfo);
            console.log(res.data.data[0].id);
            this.getListData(res.data.data[0].id)
        },
        changeType(id, index) {
            console.log(id);
            this.AreaID = id
            this.typeIndex = index
            this.getListData()
        },
        // 获取列表数据
        async getListData() {
            // console.log(val);
            let reqData = {
                area_id: !this.AreaID ? 1 : this.AreaID,
                // page: this.currentPage
            }
            // console.log(reqData);
            let res = await getData(reqData)
            console.log(res);
            if (res.data.code == 5) {
                this.$message({
                    type: 'error',
                    message: '暂无权限访问!'
                });
                this.$router.push({ path: "/Index/kongbai" });
            }
            this.tableData = res.data.data
            console.log(this.tableData);
            // this.totalCount = res.data.data.total
        },
        //   排序保存
        async sureData() {
            let sort = []
            let sort2 = []
            this.tableData.forEach(item => {
                sort.push(item.id)
                sort2.push(item.nickname)
            });
            let reqData = {
                area_id: this.AreaID,
                sort: sort.join(',')
            }
            console.log(sort);
            console.log(sort2);
            let res = await dragSort(reqData)
            console.log(res);
            if (res.data.code == 1) {
                this.tableObject.destroy()
                this.$message({
                    type: 'success',
                    message: '排序成功!'
                });
                this.type = 0
                this.getListData()
            }
        },
        // 搜索
        async searchBtnInfo() {
            let reqData = {
                area_id: this.AreaID,
                page: this.currentPage,
                search: this.input2
            }
            let res = await getData(reqData)
            if (this.input2) {
                this.tableData = res.data.data.data
                this.totalCount = res.data.data.total
            } else {
                this.getListData()
            }
            this.input2 = ""
        },
        // 接单开关
        async changeswitch(index, id) {
            console.log(index);
            console.log(id);
            let reqData = {
                aid: id
            }
            let res = await switchChange(reqData)
            if (res.data.code == 1) {
                this.$message({
                    type: 'success',
                    message: '设置成功!'
                });
                this.getListData()
            }
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.el-card__body {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    width: 1500px;
}

.box-card {
    display: flex;
    justify-content: space-between;
}

/deep/[data-v-255bf696] .el-card__body {
    display: flex;
    justify-content: space-between;

}

.select_area {
    font-weight: bold;
    white-space: normal;
    display: flex;
    flex-shrink: 0;
    text-align: center;
    line-height: 56px;
    margin-left: 20px;
}

.card_div {
    display: flex;
    justify-content: space-around;
    text-align: right;

    p {
        padding: 0 10px;
    }
}

.block {
    text-align: right;
    line-height: 78px;
}

.card_top {
    display: flex;
    justify-content: space-between;

    p {
        margin: 0 20px;

        span {
            padding-right: 17px;
        }
    }
}

.card_bottom {
    display: flex;

    p {
        margin: 10px 25px;

        span {
            padding-right: 10px;
        }
    }
}

.name-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.card_address {
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    background-color: #FFF;
    overflow: hidden;
    color: #303133;
    transition: .3s;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    display: flex;

    p {
        margin: 10px 15px;
    }
}

.changeIcon {
    font-size: 24px;
}

.type {
    width: 5%;
    height: 100%;
    text-align: center;
    line-height: 40px;
    background-color: #ecf5ff;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 8px;
    margin-left: 10px;
}

.type1 {
    background-color: #409EFF;
    width: 5%;
    height: 100%;
    text-align: center;
    line-height: 40px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    margin-top: 8px;
    margin-left: 10px;
}
</style>