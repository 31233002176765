import { request } from "../../utils/request"
// 获取地区列表信息
export function getdpkhData(data) {
    return request({
        url: '/operate/user',
        method: 'post',
        data,
    })
}
// 获取地区信息
export function getAreaInfo(data) {
    return request({
        url: 'operate/areaList',
        method: 'post',
        data,
    })
}
// 获取项目类型数据
export function getp_typeListdata(data) {
    return request({
        url: 'operate/p_typeList',
        method: 'post',
        data,
    })
}
// 获取客户级别数据
export function getlevelList(data) {
    return request({
        url: 'operate/levelList',
        method: 'post',
        data,
    })
}
// 获取客户来源数据
export function getc_sourseList(data) {
    return request({
        url: 'operate/c_sourseList',
        method: 'post',
        data,
    })
}

// 获取创建人信息
export function getcreate_manList(data) {
    return request({
        url: 'operate/create_manList',
        method: 'post',
        data,
    })
}
//修改用户
export function alterCustomInfo(data) {
    return request({
        url: '/operate/edit',
        method: 'post',
        data,
    })
}
// 添加客户
export function addPerson(data) {
    return request({
        url: '/operate/add',
        method: 'post',
        data,
    })
}
// 顺序派单
export function sxpdData(data) {
    return request({
        url: '/operate/distribute',
        method: 'post',
        data,
    })
}
// 删除
export function delData(data) {
    return request({
        url: '/operate/user_del',
        method: 'post',
        data,
    })
}
// 顺序规则
export function sxgzData(data) {
    return request({
        url: '/operate/rule',
        method: 'post',
        data,
    })
}
// 获取部门
export function DepartmentList(data) {
    return request({
        url: '/business/disbumens',
        method: 'post',
        data,
    })
}
// 自拓客户列表
export function customer_zituolist(data) {
    return request({
        url: '/customer/zituolist',
        method: 'post',
        data,
    })
}
// 添加自拓客户
export function customer_addzituo(data) {
    return request({
        url: '/customer/addzituo',
        method: 'post',
        data,
    })
}
// 获取地区列表
export function business_disbumens(data) {
    return request({
        url: '/business/disbumens',
        method: 'post',
        data,
    })
}