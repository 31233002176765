import { request } from "../../utils/request"
// 获取月目标列表信息数据
export function ymbdata(data) {
    return request({
        url: '/operate/month',
        method: 'post',
        data,
    })
}
// 添加月目标
export function addymb(data) {
    return request({
        url: '/operate/monthHandle',
        method: 'post',
        data,
    })
}
// 修改月目标
export function editymb(data) {
    return request({
        url: '/operate/monthHandle',
        method: 'post',
        data,
    })
}
// 删除月目标
export function delData(data) {
    return request({
        url: '/operate/opmonth_del',
        method: 'post',
        data,
    })
}
// 获取人员列表
export function operateList(data) {
    return request({
        url: '/operate/operateList',
        method: 'post',
        data,
    })
}