import Vue from 'vue'
import VueRouter from 'vue-router'

import YBP from "../views/yibiaopan/index.vue"

import Login from '../views/login.vue'
import Index from '../views/Index.vue'
// import Home from '../views/home.vue'
import BumenGuanLi from '../views/renyuanguanli/bumenlist.vue'
import Dpkh from '../views/yunyingguanli/dpkh.vue'
import Pdlb from '../views/yunyingguanli/pdlb.vue'
import Ymb from '../views/yunyingguanli/ymb.vue'
import Yykb from '../views/yunyingguanli/yykb.vue'
import Jdcsjl from '../views/shangwuguanli/jdcsjl.vue'
import Jdlb from '../views/shangwuguanli/jdlb.vue'
import Jdsxgl from '../views/shangwuguanli/jdsxgl.vue'
import Mmb from '../views/shangwuguanli/mmb.vue'
import Ghkh from '../views/kehuguanli/ghkh'
import Khlb from '../views/kehuguanli/khlb'
import ztkh from '../views/kehuguanli/ztkh'
import Hkjl from '../views/hetongguanli/hkjl.vue'
import Hksh from '../views/hetongguanli/hksh.vue'
import Htlb from '../views/hetongguanli/htlb.vue'
import Jsqx from '../views/quanxianguanli/jsqx.vue'
import Jishu from '../views/yunpeng_aside/zhengzhou/jishu.vue'

import kongbai from "../views/kongbai.vue"

Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [{
  path: '/',
  name: 'Login',
  component: Login
},
{
  path: '/Index',
  name: 'Index',
  component: Index,
  redirect: '/Index/YBP',
  children: [
    {
      path: '/Index/YBP',
      name: 'YBP',
      component: YBP,
    },
    // {
    //   path: '/Index/Home',
    //   name: 'Home',
    //   component: Home,
    // },
    {
      path: '/Index/kongbai',
      name: 'kongbai',
      component: kongbai,
    },
    {
      path: '/Index/BumenGuanLi',
      name: 'BumenGuanLi',
      component: BumenGuanLi,
      children: [
        {
          path: '/Index/BumenGuanLi/Jishu',
          name: 'Jishu',
          component: Jishu,
        },
      ]
    },

    {
      path: '/Index/Dpkh',
      name: 'Dpkh',
      component: Dpkh,
    },
    {
      path: '/Index/Pdlb',
      name: 'Pdlb',
      component: Pdlb,
    },
    {
      path: '/Index/Ymb',
      name: 'Ymb',
      component: Ymb,
    },
    {
      path: '/Index/Yykb',
      name: 'Yykb',
      component: Yykb,
    },
    {
      path: '/Index/Jdcsjl',
      name: 'Jdcsjl',
      component: Jdcsjl,
    },
    {
      path: '/Index/Jdlb',
      name: 'Jdlb',
      component: Jdlb,
    },
    {
      path: '/Index/Jdsxgl',
      name: 'Jdsxgl',
      component: Jdsxgl,
    },
    {
      path: '/Index/Mmb',
      name: 'Mmb',
      component: Mmb,
    },
    {
      path: '/Index/Ghkh',
      name: 'Ghkh',
      component: Ghkh,
    },
    {
      path: '/Index/Khlb',
      name: 'Khlb',
      component: Khlb,
    },
	{
	  path: '/Index/ztkh',
	  name: 'ztkh',
	  component: ztkh,
	},
    {
      path: '/Index/Hkjl',
      name: 'Hkjl',
      component: Hkjl,
    },
    {
      path: '/Index/Hksh',
      name: 'Hksh',
      component: Hksh,
    },
    {
      path: '/Index/Htlb',
      name: 'Htlb',
      component: Htlb,
    },
    {
      path: '/Index/Jsqx',
      name: 'Jsqx',
      component: Jsqx,
    },

  ]
},

]

const router = new VueRouter({
  mode: 'hash',
  routes,
  scrollBehavior: () => {
    history.pushState(null, null, document.URL)
  }
})
router.beforeEach((to, from, next) => {
  if (to.path == "/Index/BumenGuanLi/YBP") {
    next({ path: "/Index" })
  }

  next();
})
export default router

