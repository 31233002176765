import { request } from "../../utils/request"
// 新增月目标
export function getadmin(data) {
    return request({
        url: '/admin/list',
        method: 'post',
        data,
    })
}
// 领取/operate/distribute
export function receiving(data) {
    return request({
        url: '/operate/order_receiving',
        method: 'post',
        data,
    })
}
// 领取
export function fenpei(data) {
    return request({
        url: '/operate/distribute',
        method: 'post',
        data,
    })
}