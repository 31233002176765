import { request } from "../utils/request"
// 获取列表数据
export function roleInfo(data) {
    return request({
        url: '/group/index',
        method: 'post',
        data,
    })
}
// 获取角色列表
export function roleDataInfo(data) {
    return request({
        url: '/rule/index',
        method: 'post',
        data,
    })
}
// 添加角色
export function addRoles(data) {
    return request({
        url: '/group/add',
        method: 'post',
        data,
    })
}
// 获取名字
export function getGroupName(data) {
    return request({
        url: '/group/get_group_name',
        method: 'post',
        data,
    })
}
// 确定编辑
export function editDataInfo(data) {
    return request({
        url: '/group/edit',
        method: 'post',
        data,
    })
}
// 删除角色
export function delData(data) {
    return request({
        url: '/group/del',
        method: 'post',
        data,
    })
}
//角色权限树

export function get_roletree(data) {
    return request({
        url: '/group/roletree',
        method: 'post',
        data,
    })
}