<template>
    <div style="margin-right: 20px;">
        <div class="card_address">
            <div class="select_area">选择地区</div>
            <div :class="typeIndex == index ? 'type1' : 'type'" v-for=" (item, index) in areaInfo" :key="index">
                <div @click="changeType(item.id, index)">{{ item.name }}</div>
            </div>
        </div>
        <el-card class="box-card" style="margin-top: 20px;">
            <div class="block">
                <span class="demonstration">选择月份</span>
                <el-date-picker v-model="value2" type="month" placeholder="选择月">
                </el-date-picker>
            </div>
            <div class="card_div">
                <p><el-button type="primary" plain size="medium">导入</el-button></p>
                <p><el-button type="primary" plain size="medium">导出</el-button></p>
                <p><el-button type="primary" plain size="medium" @click="addcenterDialogVisible = true">新增月目标</el-button>
                </p>
                <p><el-button plain size="medium" class="scxz" :disabled="multipleSelection.length == 0"
                        @click="deleteData">删除选中</el-button>
                </p>
            </div>
        </el-card>
        <el-table style="width: 100%;" ref="multipleTable" :data="tableData" tooltip-effect="dark"
            @selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }">
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column label="姓名" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.user.nickname }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.user.nickname }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="部门" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.deparment }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.deparment }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="月目标业绩(万)" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.month_count }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.month_count }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="完成业绩(万)" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.month_acc_num }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.month_acc_num }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.createtime }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.createtime }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="修改时间" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.updatetime }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.updatetime }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="editData(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[15, 30]" :page-size="15" layout="total, sizes, prev, pager, next, jumper" :total="countTotal">
            </el-pagination>
        </div>
        <!-- 新增月目标弹框 -->
        <div class="add_dialog">
            <el-dialog title="新增月目标" :visible.sync="addcenterDialogVisible" width="388px" center append-to-body
                @close="closeAdd">
                <el-form :model="form">
                    <el-form-item label="选择员工" :label-width="formLabelWidth">
                        <el-select v-model="nickname" placeholder="请选择客户级别" value-key="id" @change="selectnickname"
                            clearable ref="ad">
                            <el-option v-for="item in options1" :key="item.id" :label="item.nickname" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="月目标" :label-width="formLabelWidth">
                        <el-input v-model="form.month" autocomplete="off" placeholder="请输入月目标业绩"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addcenterDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addMonthData">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 月目标设置 -->
        <div class="add_dialog">
            <el-dialog title="月目标设置" :visible.sync="editcenterDialogVisible" width="388px" center append-to-body>
                <el-form :model="form">
                    <el-form-item label="选择员工" :label-width="formLabelWidth">
                        <el-select v-model="nickname" placeholder="请选择客户级别" value-key="id" @change="selectnickname"
                            clearable ref="ad">
                            <el-option v-for="item in options1" :key="item.id" :label="item.nickname" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="月目标" :label-width="formLabelWidth">
                        <el-input v-model="form.month" autocomplete="off" placeholder="请输入月目标业绩"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="editcenterDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="sureEdit">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { department, } from '../../api/renyuanguanli/bumenlist';
import { getstaffList } from '../../api/Swgl/Jdcsjl'
import { addMonth, getData } from '../../api/Swgl/mmb'
import { timestampToTime } from "../../utils/publicFun"
export default {
    data() {
        return {
            tableData: [],
            value: true,
            currentPage: 1,
            addcenterDialogVisible: false,
            editcenterDialogVisible: false,
            formLabelWidth: '120px',
            value2: '',
            multipleSelection: [],
            form: {
                month: '',
            },
            areaInfo: [],
            countTotal: 0,
            options1: [],
            nickname: '',
            businessId: '',
            typeIndex: 0,
            AreaID: 0,
            newArr: [],
            arr: []
        }
    },
    mounted() {
        this.getdepartmentdata()
        this.getstaffListData()
        this.getListData()
    },
    methods: {
        // 清空输入框
        closeAdd() {
            this.form = {
                month: ''
            }
            this.nickname = ""
        },
        // 分页
        handleSizeChange() {
            // 每页显示数量 也就是pagesize发生变化时 要重置数据，和重置页码
            this.tableData = []
            this.currentPage = 1;
            this.pageSize = 15
            this.getListData()
        },
        handleCurrentChange(val) {
            // 当 页码发生变化时 重置数据
            this.tableData = []
            this.currentPage = val
            this.getListData()
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 地区部门数据列表
        async getdepartmentdata() {
            let reqData = {
                id: 0
            }
            let res = await department(reqData)
            console.log(res);
            this.areaInfo = res.data.data
            console.log(this.areaInfo);
            // this.getListData(res.data[0].id)
        },
        changeType(id, index) {
            console.log(id);
            this.AreaID = id
            this.typeIndex = index
            this.getListData()
        },
        // 获取列表数据
        async getListData() {
            // console.log(val);
            let reqData = {
                area_id: this.AreaID,
                page: this.currentPage,
                month: ''
            }
            // console.log(reqData);
            let res = await getData(reqData)
            console.log(res);
            if (res.data.code == 5) {
                this.$message({
                    type: 'error',
                    message: '暂无权限访问!'
                });
                this.$router.push({ path: "/Index/kongbai" });
            }
            this.tableData = res.data.data.data
            this.setData(this.tableData)
            this.countTotal = res.data.data.total
        },
        setData(arr) {
            for (let i = 0; i < arr.length; i++) {
                arr[i].createtime = !arr[i].createtime ? '' : timestampToTime(arr[i].createtime * 1000)
                arr[i].updatetime = !arr[i].updatetime ? '' : timestampToTime(arr[i].updatetime * 1000)
            }
        },
        // 添加月目标
        async addMonthData() {
            let reqData = {
                uid: this.businessId,
                month_count: this.form.month
            }
            let res = await addMonth(reqData)
            console.log(res);
            if (res.data.code == 1) {
                this.$message({
                    type: 'success',
                    message: '添加成功!'
                });
                this.getListData()
            }
            this.addcenterDialogVisible = false
        },
        // 编辑月目标
        editData(data) {
            console.log(data);
            this.editcenterDialogVisible = true
            this.nickname = data.user.nickname
            this.id = data.id
            this.form.month = data.month_count
            this.businessId = data.uid
        },
        // 确定编辑
        async sureEdit() {
            let reqData = {
                month_count: this.form.month,
                id: this.id,
                uid: this.businessId
            }
            let res = await addMonth(reqData)
            console.log(res);
            if (res.data.code == 1) {
                this.$message({
                    type: 'success',
                    message: '修改成功!'
                });
                this.getListData()
            }
            this.editcenterDialogVisible = false
        },
        // 获取商务列表
        async getstaffListData() {
            let res = await getstaffList()
            console.log(res);
            this.options1 = res.data.data
        },
        // 获取商务列表的id
        selectnickname(id) {
            console.log(id);
            let obj = {};
            obj = this.options1.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            console.log(obj);
            this.businessId = obj.id
            // this.getListData()
        },
        // 多选
        handleSelectionChange(val) {
            // console.log(val);
            this.multipleSelection = val
            // console.log(this.multipleSelection);
            for (var i of this.multipleSelection) {
                // console.log(i.date);
                this.arr.push(i.records_id)
                console.log(this.arr);
            }
            this.newArr = [...new Set(this.arr)];
            console.log(this.newArr);
        },
        // 删除选中
        async deleteData(data) {
            let reqData = {
                ids: this.newArr + '',
            }
            console.log(reqData);
            this.$confirm('确定要删除该数据吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await delData(reqData)
                if (res.data.code == 1) {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getData()
                    // location.reload()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
    }
}
</script>

<style lang="less" scoped>
/deep/.el-card__body {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    width: 1500px;
}

.el-card {
    border: none;
    border-radius: 0;
}

.el-card.is-always-shadow {
    box-shadow: none;
}

.select_area {
    font-weight: bold;
    white-space: normal;
    display: flex;
    flex-shrink: 0;
    text-align: center;
    line-height: 56px;
    margin-left: 20px;
}

.box-card {
    display: flex;
    justify-content: space-between;
}

/deep/[data-v-255bf696] .el-card__body {
    display: flex;
    justify-content: space-between;

}

.card_div {
    display: flex;
    justify-content: space-around;
    text-align: right;

    p {
        padding: 0 10px;
    }
}

.block {
    text-align: right;
    line-height: 78px;
}

.card_top {
    display: flex;
    justify-content: space-between;

    p {
        margin: 0 20px;

        span {
            padding-right: 17px;
        }
    }
}

.card_bottom {
    display: flex;

    p {
        margin: 10px 25px;

        span {
            padding-right: 10px;
        }
    }
}

.name-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.card_address {
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    background-color: #FFF;
    overflow: hidden;
    color: #303133;
    transition: .3s;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    display: flex;

    p {
        margin: 10px 15px;
    }
}

.type {
    width: 5%;
    height: 100%;
    text-align: center;
    line-height: 40px;
    background-color: #ecf5ff;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 8px;
    margin-left: 10px;
}

.type1 {
    background-color: #409EFF;
    width: 5%;
    height: 100%;
    text-align: center;
    line-height: 40px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    margin-top: 8px;
    margin-left: 10px;
}
</style>