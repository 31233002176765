import { request } from "../../utils/request"
// 新增月目标
export function getContract(data) {
    return request({
        url: '/contract/list',
        method: 'post',
        data,
    })
}
// 新增回款记录
export function addreturnedMoney(data) {
    return request({
        url: '/contract/return_money',
        method: 'post',
        data,
    })
}
// 删除
export function delData(data) {
    return request({
        url: '/contract/contract_del',
        method: 'post',
        data,
    })
}
// 修改
export function editDataInfo(data) {
    return request({
        url: '/contract/edit',
        method: 'post',
        data,
    })
}