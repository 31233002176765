<template>
  <div class="">
    <img class="page_bg" src="https://crm.nanjingyunpeng.cn/uploads/20230802/cacd6bdc980644d3cb5d746ecd98f50c.jpg" alt />
    <div class="page_title">后台管理系统</div>
    <div class="login_box">
      <div class="title">欢迎登录</div>
      <div class="form_box">
        <div class="form_item">

          <el-input placeholder="请输入账号" prefix-icon="el-icon-user" v-model="input1" size="small" maxlength="15"
            style="background-color: transparent;"></el-input>
        </div>
        <div class="form_item">
          <el-input placeholder="请输入密码" type="password" prefix-icon="el-icon-orange" v-model="input2" size="small"
            maxlength="15"></el-input>
        </div>
      </div>
      <el-button class="log_btn" type="primary" @click="userLogin" @keyup.enter="keyDown(e)">登陆</el-button>
      <div class="safe_user">
        <div class="line"></div>
        <div class="check_box">
          <el-checkbox v-model="checked">记住密码</el-checkbox>
        </div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { CLIENT_RENEG_WINDOW } from 'tls';
import { login } from '../api/login';
export default {
  components: {},
  created() {
		let token = localStorage.getItem("admin-token")
		if (token && token != "") {
			this.$router.push({ path: "/Index" });
		}
  },
  mounted() {
    // 绑定监听事件
    window.addEventListener("keydown", this.keyDown);

  },
  destroyed() {
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },
  data() {
    return {
      input1: "",
      input2: "",

      checked: true
    }
  },
  methods: {
    async userLogin() {
      try {
        //准备好 要向后端提交的数据
        let reqData = {
          username: this.input1,
          password: this.input2,
          keeplogin: 1
        }
        let res = await login(reqData);
        console.log(res);
        if (res.data.data.token) {
          localStorage.setItem("admin-token", res.data.data.token)
          localStorage.setItem("department-id", res.data.data.department_id)
          localStorage.setItem('user-name', res.data.data.nickname)
          if (res.data.code == 0) {
            this.$message({
              message: '账号或密码错误',
              type: "error"
            });
          } else {
            this.$router.push({ path: "/Index" });
          }
        }
        // this.$router.push({ name: "YBP" });
      } catch (err) {
        // 或者在这里message 也可以
        console.log(err);

      }
    },
    // 点击回车键登录
    keyDown(e) {
      // console.log(e);
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode == 13) {
        this.userLogin(); // 定义的登录方法
      }
    },

    // userLogin() {
    //   this.$router.push({ path: "/Index" });
    //   // try {
    //   //   //准备好 要向后端提交的数据
    //   //   let reqData = {
    //   //     username: this.input1,
    //   //     password: this.input2,
    //   //     keeplogin: 1
    //   //   }
    //   //   let res = await login(reqData);
    //   //   console.log(res);
    //   //   if (res.data.data.token) {
    //   //     localStorage.setItem("admin-token", res.data.data.token)

    //   //   }
    //   //   // this.$router.push({ name: "YBP" });
    //   // } catch (err) {
    //   //   // 或者在这里message 也可以
    //   //   console.log(err);

    //   // }
    // }
  }
};
</script>
<style lang="less" scoped>
page {
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}
.page_bg {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}

.el-input__inner {
  background-color: transparent;
}

.page_title {
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translate(-50%, 0);
  font-size: 30px;
  color: #ffffff;
  font-weight: 700;
}

.login_box {
  width: 430px;
  height: 400px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  position: absolute;
  left: 73%;
  top: 45%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.login_box .title {
  padding: 10px 0;
  text-align: center;
  font-size: 30px;
  cursor: default;
  color: #065fe7;
  padding: 60px 0 10px 0;
  /* background-color: rgb(21, 106, 185); */
}

.form_box {
  width: 100%;
  margin-top: 20px;
}

.form_item {
  width: 300px;
  height: 45px;
  background-color: transparent;
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 20px 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  margin: 14px auto;
  font-size: 24px;
  color: #000;
}

.form_item_text {
  color: rgb(21, 106, 185);
  font-weight: 700;
  white-space: nowrap;
}

.log_btn {
  width: 170px;
  border-radius: 22px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 14px);
  font-size: 16px;
  letter-spacing: 0.25rem;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1a8dfe+0,0765f3+100 */
  background: #1a8dfe;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg,
      #1a8dfe 0%,
      #0765f3 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,
      #1a8dfe 0%,
      #0765f3 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,
      #1a8dfe 0%,
      #0765f3 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1a8dfe', endColorstr='#0765f3', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.safe_user {
  width: 100%;
  padding: 10px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.check_box {
  margin: 0 20px;
}

.safe_user .line {
  width: 100px;
  height: 1px;
  background-color: rgb(165, 165, 165);
}

/deep/.el-input__inner {
  background-color: transparent;
  border: none;
  color: #000;
  font-size: 18px;
}
</style>

