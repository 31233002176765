<template>
    <div class="htlb">
        <div class="left">
            <el-card class="box-card">
                <div style="display: flex;">
                    <p><el-input placeholder="请输入客户名称或联系方式" v-model="input2" class="input-with-select">
                            <el-button slot="append" icon="el-icon-search" @click="searchBtnInfo()"></el-button>
                        </el-input></p>
                    <p><el-button type="primary" style="margin-left: 10px;" @click="resetInput">重置</el-button></p>
                </div>
                <div class="card_div">
                    <p><el-button type="primary" plain size="medium">导入</el-button></p>
                    <p><el-button type="primary" plain size="medium">导出</el-button></p>
                    <p><el-button plain size="medium" class="scxz" :disabled="multipleSelection.length == 0"
                            @click="deleteData">删除选中</el-button></p>
                </div>
            </el-card>
            <el-card>
                <div style="padding:10px;">
                    <div class="card_top">
                        <p><span>客户筛选</span>
                            <el-select v-model="filter" placeholder="请选择" value-key="filter" @change="selectFilter">
                                <el-option v-for="item in options1" :key="item.filter" :label="item.name"
                                    :value="item.filter">
                                </el-option>
                            </el-select>
                        </p>
                        <p><span>打款次数</span>
                            <el-select v-model="DKnum" placeholder="请选择" value-key="id" @change="selectDKnum">
                                <el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </p>
                        <p><span>项目类型</span>
                            <el-select v-model="project_type" placeholder="请选择" value-key="id" @change="selectproject_type">
                                <el-option v-for="item in options3" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </p>
                    </div>
                </div>
            </el-card>
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;"
                @selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }"
                @row-click="getYsmoney">
                <el-table-column type="selection" width="55" align="center">
                </el-table-column>
                <el-table-column label="合同编号" width="80" align="center">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>{{ scope.row.contract_no }}</p>
                            <div slot="reference" class="name-wrapper">
                                {{ scope.row.contract_no }}
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="客户名称" width="80" align="center">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>{{ scope.row.user_name }}</p>
                            <div slot="reference" class="name-wrapper">
                                {{ scope.row.user_name }}
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="项目名称" width="80" align="center">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>{{ scope.row.project_name }}</p>
                            <div slot="reference" class="name-wrapper">
                                {{ scope.row.project_name }}
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="项目类型" width="80" align="center">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>{{ scope.row.project_type }}</p>
                            <div slot="reference" class="name-wrapper">
                                {{ scope.row.project_type }}
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="合同金额" width="80" align="center">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>{{ scope.row.money }}</p>
                            <div slot="reference" class="name-wrapper">
                                {{ scope.row.money }}
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="有效金额" width="80" align="center">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>{{ scope.row.valid_money }}</p>
                            <div slot="reference" class="name-wrapper">
                                {{ scope.row.valid_money }}
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>

                <el-table-column label="款次" width="55" align="center">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>{{ scope.row.money_count }}</p>
                            <div slot="reference" class="name-wrapper">
                                {{ scope.row.money_count }}
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="运营" width="55" align="center">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>{{ scope.row.op_nickname }}</p>
                            <div slot="reference" class="name-wrapper">
                                {{ scope.row.op_nickname }}
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="商务" width="55" align="center">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>{{ scope.row.business_nickname }}</p>
                            <div slot="reference" class="name-wrapper">
                                {{ scope.row.business_nickname }}
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="签约时间" width="115" align="center">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>{{ scope.row.signtime }}</p>
                            <div slot="reference" class="name-wrapper">
                                {{ scope.row.signtime }}
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" width="115" align="center">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>{{ scope.row.createtime }}</p>
                            <div slot="reference" class="name-wrapper">
                                {{ scope.row.createtime }}
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="editData(scope.row)">编辑</el-button>
                        <el-button size="mini" @click.stop="viewFJ(scope.row)">查看附件</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[15, 30]" :page-size="15"
                    layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
                </el-pagination>
            </div>
        </div>
        <div class="right">
            <el-card style="display: flex;flex-direction: column;justify-content: space-between;">
                <h3>应收账款</h3>
                <el-table :data="accountsData" style="width: 100%">
                    <el-table-column label="款期" width="140">
                        <template slot-scope="scope">
                            <el-popover trigger="hover" placement="top">
                                <p>{{ scope.row.installment }}</p>
                                <div slot="reference" class="name-wrapper">
                                    {{ scope.row.installment }}
                                </div>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="应回金额" width="140">
                        <template slot-scope="scope">
                            <el-popover trigger="hover" placement="top">
                                <p>{{ scope.row.money
                                }}</p>
                                <div slot="reference" class="name-wrapper">
                                    {{ scope.row.money
                                    }}
                                </div>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="已回金额" width="140">
                        <template slot-scope="scope">
                            <el-popover trigger="hover" placement="top">
                                <p>{{ scope.row.already_money }}</p>
                                <div slot="reference" class="name-wrapper">
                                    {{ scope.row.already_money }}
                                </div>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="140">
                        <template slot-scope="scope">
                            <el-button size="mini" @click="getaddreturnedMoneyData(scope.row)">新增回款记录</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </div>
        <!-- 编辑合同弹出框 -->
        <div class="tj_dialog">
            <el-dialog title="新增合同" :visible.sync="editcenterDialogVisible" width="750px" center append-to-body>
                <div class="dialog_top">
                    <div class="dialog_left">
                        <el-form :model="contractForm">
                            <el-form-item label="合同编号" :label-width="formLabelWidth">
                                <el-input v-model="contractForm.contract_no" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入合同编号"></el-input>
                            </el-form-item>
                            <el-form-item label="合同名称" :label-width="formLabelWidth">
                                <el-input v-model="contractForm.name" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入合同名称"></el-input>
                            </el-form-item>
                            <el-form-item label="项目名称" :label-width="formLabelWidth">
                                <el-input v-model="contractForm.project_name" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入项目名称"></el-input>
                            </el-form-item>
                            <el-form-item label="合同金额" :label-width="formLabelWidth">
                                <el-input v-model="contractForm.money" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入合同金额"></el-input>
                            </el-form-item>
                            <el-form-item label="有效金额" :label-width="formLabelWidth">
                                <el-input v-model="contractForm.valid_money" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入有效金额"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="dialog_right" style="display: flex;">
                        <el-form>
                            <el-form-item label="项目类型" label-width="110px">
                                <div class="btn">
                                    <div class="btn_elbtn">
                                        <el-button size="mini" v-for="item in btnlist" style="margin-bottom: 10px;"
                                            @click="getProjectId(item.id)">{{
                                                item.name
                                            }}</el-button>
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item label="签约时间" :label-width="formLabelWidth">
                                <el-date-picker v-model="qyData" type="date" placeholder="选择日期" @change="qyDataClick()"
                                    :picker-options="{
                                        disabledDate(time) { // 设置禁用状态，参数为当前日期，要求返回 Boolean
                                            return time.getTime() > Date.now();
                                        }
                                    }">
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-divider></el-divider>
                <div class="dialog_center">
                    <el-form :model="contractForm">
                        <el-form-item label="打款次数" :label-width="formLabelWidth">
                            <el-select v-model="contractForm.Dkcs" placeholder="请选择打款次数" value-key="id"
                                @change="selectDkcs">
                                <el-option v-for="item in options6" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <div class="money_list">
                        <div class="money_item" v-for="(item, index) in moneyList" :key="index" v-if="dkId !== '' && index <
                            Number(dkId) + 1">
                            <label>{{ item.title }}</label>
                            <el-input class="money_item_input" v-model="item.moneyCount" autocomplete="off"
                                style="width: 100px;margin-right: 10px;" placeholder="20%"></el-input>
                            <label>{{ item.label }} {{ !item.moneyCount ? 0 : (parseFloat(item.moneyCount)
                                * parseFloat(contractForm.valid_money / 100))
                            }}</label>
                        </div>
                    </div>
                </div>
                <el-divider></el-divider>
                <div class="dialog_bottom">
                    <el-form>
                        <el-form-item label="上传附件" :label-width="formLabelWidth">
                            <el-upload class="upload-demo" action="https://crm.nanjingyunpeng.cn/api/v1/customer/uploads"
                                :on-success="handleAvatarSuccess2" :on-preview="handlePreview" :on-remove="handleRemove"
                                :before-remove="beforeRemove" multiple :limit="3" :on-exceed="handleExceed" :headers="token"
                                :file-list="fileData">
                                <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="editcenterDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="sureEdit">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 新增回款记录弹出框 -->
        <div class="tj_dialog">
            <el-dialog title="新增回款记录" :visible.sync="addcenterDialogVisible" width="450px" center append-to-body>
                <div class="dialog_top">
                    <div class="dialog_left">
                        <el-form :model="form">
                            <el-form-item label="回款时间" :label-width="formLabelWidth">
                                <el-date-picker v-model="HKDate" type="date" placeholder="选择日期" @change="HKDateClick()"
                                    :picker-options="{
                                        disabledDate(time) { // 设置禁用状态，参数为当前日期，要求返回 Boolean
                                            return time.getTime() > Date.now();
                                        }
                                    }">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="回款金额" :label-width="formLabelWidth">
                                <el-input type="text" placeholder="请输入文字内容" v-model="hkMoney" style="width: 300px;">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="上传附件" :label-width="formLabelWidth">
                                <el-upload class="upload-demo"
                                    action="https://crm.nanjingyunpeng.cn/api/v1/customer/uploads"
                                    :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove"
                                    :on-success="handleAvatarSuccess1" multiple :limit="3" :on-exceed="handleExceed"
                                    :headers="token" :file-list="fileData">
                                    <el-button size="small" type="primary">点击上传</el-button>
                                    <!-- <div slot="tip" class="el-upload__tip"></div>
                                    :file-list="fileList" -->
                                </el-upload>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addcenterDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addreturnedMoneyData()">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 查看附件弹出框 -->
        <el-dialog title="附件详情" :visible.sync="viewFJdialogFormVisible" append-to-body>
            <el-form :model="form">
                <el-upload action="https://crm.nanjingyunpeng.cn/api/v1/customer/uploads" list-type="picture-card"
                    :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :file-list="fileData" :headers="token">
                    <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="viewFJdialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="viewFJdialogFormVisible = false">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getContract, addreturnedMoney, delData, editDataInfo } from '../../api/Htgl/htlb'
import { getproject, getcreator } from '../../api/common/common'
import { addContract } from '../../api/Khgl/Khlb'
export default {
    data() {
        return {
            //因为这三个变量是v-for 用的 v-for一般是循环渲染dom的，所以一般v-for用的变量都是数组
            options1: [
                {
                    filter: 0,
                    name: "全部"
                },
                {
                    filter: 1,
                    name: "我负责"
                },
                {
                    filter: 2,
                    name: "我的下属负责"
                },
            ],
            options6: [
                {
                    id: 0,
                    name: "1"
                },
                {
                    id: 1,
                    name: "2"
                },
                {
                    id: 2,
                    name: "3"
                },
                {
                    id: 3,
                    name: "4"
                },
                {
                    id: 4,
                    name: "5"
                }
            ],
            options2: [
                {
                    id: 0,
                    name: "1"
                },
                {
                    id: 1,
                    name: "2"
                },
                {
                    id: 2,
                    name: "3"
                },
                {
                    id: 3,
                    name: "4"
                },
                {
                    id: 4,
                    name: "5"
                }
            ],
            options3: [],
            accountsData: [],
            multipleSelection: [],
            tableData: [],
            totalCount: 0,
            value: true,
            currentPage: 1,
            viewFJdialogFormVisible: false,
            editcenterDialogVisible: false,
            addcenterDialogVisible: false,
            btnlist: [],
            dialogImageUrl: '',
            dialogVisible: false,
            value: '',
            contractForm: {
                Dkcs: '',
                contract_no: '',
                name: '',
                project_name: '',
                money: '',
                valid_money: ''
            },
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            formLabelWidth: '120px',
            textarea2: '',
            input2: '',
            HKDate: '',
            DateHK: '',
            hkMoney: '',
            fileData: "",
            arr: [],
            newArr: [],
            project_type: '',
            filter: '',
            qyData: '',
            dateQY: '',
            DKnum: '',
            dkId: '',
            row_list: [],
            moneyList: [
                {
                    title: "一期款",
                    moneyCount: "",
                    label: "金额",
                    isShow: false
                },
                {
                    title: "二期款",
                    moneyCount: "",
                    label: "金额",
                    isShow: false
                },
                {
                    title: "三期款",
                    moneyCount: "",
                    label: "金额",
                    isShow: false
                },
                {
                    title: "四期款",
                    moneyCount: "",
                    label: "金额",
                    isShow: false
                },
                {
                    title: "五期款",
                    moneyCount: "",
                    label: "金额",
                    isShow: false
                },
            ],
            imgArr: []
        }
    },
    created() {
        let temp = window.localStorage.getItem("admin-token")
        this.token = {
            "token": temp
        }
        console.log(this.token)
    },
    mounted() {
        this.getContractInfo()
        this.getproject_typeData()
    },
    methods: {
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleAvatarSuccess1(res, file) {
            console.log(res.data.url);
            if (res.code == 2) {
                return this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "error"
                });
            }
            if (res) {
                this.fileData = res.data.url
                this.imgArr.push(this.fileData)
                this.$message({
                    showClose: true,
                    message: "上传成功",
                    type: "success"
                });
            } else {
                this.$message({
                    showClose: true,
                    message: "上传失败",
                    type: "error"
                });
            }
        },
        // 分页
        handleSizeChange() {
            // 每页显示数量 也就是pagesize发生变化时 要重置数据，和重置页码
            this.tableData = []
            this.currentPage = 1;
            this.pageSize = 15
            this.getContractInfo()
        },
        handleCurrentChange(val) {
            // 当 页码发生变化时 重置数据
            this.tableData = []
            this.currentPage = val
            this.getContractInfo()
        },
        handleAvatarSuccess2(res, file) {
            console.log(res.code.fullurl);
            if (res.code == 2) {
                return this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "error"
                });
            }
            if (res) {
                this.fileData2 = res.code.fullurl
                this.$message({
                    showClose: true,
                    message: "上传成功",
                    type: "success"
                });
            } else {
                this.$message({
                    showClose: true,
                    message: "上传失败",
                    type: "error"
                });
            }
        },
        // 文件上传
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        // 获取列表数据
        async getContractInfo() {
            let reqData = {
                page: this.currentPage,
                filter: this.filterId,
                project_type: this.project_typeID,
                money_count: this.DKcountID
            }
            let res = await getContract(reqData)
            console.log(res);
            if (res.data.code == 5) {
                this.$message({
                    type: 'error',
                    message: '暂无权限访问!'
                });
                this.$router.push({ path: "/Index/kongbai" });
            }
            this.tableData = res.data.data.data
            this.totalCount = res.data.data.total
        },
        selectDkcs(id) {
            console.log(id);
            this.dkId = id
        },
        // 获取应收金额款项
        getYsmoney(row) {
            console.log(row.batch);
            this.accountsData = row.batch
        },
        // 获取回款时间
        HKDateClick() {
            console.log(this.HKDate);
            this.DateHK = Date.parse(this.HKDate)
            console.log(this.dateQY);
        },
        // 获取签约时间
        qyDataClick() {
            console.log(this.qyData);
            this.dateQY = Date.parse(this.qyData)
            console.log(this.dateQY);
        },
        // 新增回款记录获取信息
        async getaddreturnedMoneyData(data) {
            console.log(data);
            this.id = data.id
            this.ids = data.contract_id
            this.addcenterDialogVisible = true
        },
        // 新增回款记录
        async addreturnedMoneyData() {
            let reqData = {
                money: this.hkMoney,
                returntime: this.DateHK / 1000,
                batch_id: this.id,
                contract_id: this.ids,
                file: this.imgArr.join(',')
            }
            console.log(reqData);
            let res = await addreturnedMoney(reqData)
            console.log(res);
            this.addcenterDialogVisible = false
            if (res.data.code == 1) {
                this.$message({
                    showClose: true,
                    message: '新增成功',
                    type: "success"
                });
                this.getYsmoney()
            }
        },
        // 编辑获取信息
        editData(data) {
            console.log(data);
            this.editcenterDialogVisible = true
            this.contractForm.contract_no = data.contract_no
            this.contractForm.name = data.name
            this.contractForm.project_name = data.project_name
            this.contractForm.money = data.money
            this.contractForm.valid_money = data.valid_money
            this.contractForm.Dkcs = data.money_count - 1
            this.id = data.id
            this.dkId = data.money_count - 1
            let arr = []
            for (var i = 0; i < data.batch.length; i++) {
                let obj = {
                    title: "",
                    moneyCount: "",
                }
                obj.title = data.batch[i].installment
                obj.moneyCount = data.batch[i].percent
                arr.push(obj)
            }

            this.moneyList = arr
            this.fileData = [{ url: data.file }]
            this.qyData = data.createtime
        },
        // 确定编辑
        async sureEdit() {
            for (var i = 0; i <= this.contractForm.Dkcs; i++) {
                if (this.moneyList[i].moneyCount != '') {
                    var cardNumObj = {};
                    cardNumObj.percent = this.moneyList[i].moneyCount
                    console.log(cardNumObj.percent);
                    cardNumObj.money = parseFloat(this.moneyList[i].moneyCount) * parseFloat(this.contractForm.valid_money / 100)
                }
                this.row_list.push(cardNumObj)
            }
            console.log(this.row_list);
            let reqData = {
                ids: this.id,
                contract_no: this.contractForm.contract_no,
                name: this.contractForm.name,
                project_name: this.contractForm.project_name,
                money: this.contractForm.money,
                project_id: this.project_id,
                valid_money: this.contractForm.valid_money,
                signtime: this.dateQY / 1000,
                money_count: this.contractForm.Dkcs + 1,
                file: this.fileData2,
                row: this.row_list
            }
            console.log(reqData);
            let res = await editDataInfo(reqData)
            console.log(res);
            console.log(reqData);
            this.editcenterDialogVisible = false
            this.getContractInfo()
        },
        // 客户筛选
        selectFilter(id) {
            console.log(id);
            let obj = {};
            obj = this.options1.find((item) => {
                return item.filter === id; //筛选出匹配数据
            });
            console.log(obj);
            this.filterId = obj.filter
            console.log(this.id);
            this.getContractInfo()
        },
        // 获取客户项目类型
        async getproject_typeData() {
            let res = await getproject()
            console.log(res.data.data);
            this.options3 = res.data.data
            this.btnlist = res.data.data
        },
        // 获取按钮项目类型id
        getProjectId(id) {
            console.log(id);
            this.project_id = id
            console.log(this.project_id);
        },
        // 项目类型
        selectproject_type(id) {
            console.log(id);
            let obj = {};
            obj = this.options3.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            console.log(obj);
            this.project_typeID = obj.id
            console.log(this.project_typeID);
            this.getContractInfo()
        },
        // 获取打款次数id
        selectDKnum(id) {
            console.log(id);
            let obj = {};
            obj = this.options2.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            console.log(obj);
            this.DKcountID = obj.id
            console.log(this.DKcountID);
            this.getContractInfo()
        },
        // 全选
        handleSelectionChange(val) {
            this.multipleSelection = val;
            for (var i of this.multipleSelection) {
                console.log(i);
                this.arr.push(i.id)
                console.log(this.arr);
            }
            this.newArr = [...new Set(this.arr)];
            console.log(this.newArr);
        },
        // 删除选中
        async deleteData(data) {
            let reqData = {
                ids: this.newArr + '',
            }
            console.log(reqData);
            this.$confirm('确定要删除该数据吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await delData(reqData)
                if (res.data.code == 1) {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getContractInfo()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 查看附件
        viewFJ(data) {
            this.fileData = [{ url: data.file }]
            console.log(this.fileData);
            this.viewFJdialogFormVisible = true
        },
        // 重置输入框
        async resetInput() {
            this.input2 = ""
            this.level = ""
            this.project_type = ""
            this.money_count = ""
            let reqData = {
                page: this.currentPage,
                filter: this.filterId,
            }
            let res = await getContract(reqData)
            console.log(res);
            this.tableData = res.data.data.data
            this.totalCount = res.data.data.total
        },
        // 搜索
        async searchBtnInfo() {
            let reqData = {
                page: this.currentPage,
                filter: this.filterId,
                project_type: this.project_typeID,
                money_count: this.DKcountID,
                search: this.input2
            }
            let res = await getContract(reqData)
            if (this.input2) {
                this.tableData = res.data.data.data
                this.totalCount = res.data.data.total
            } else {
                this.getContractInfo()
            }
            this.input2 = ""
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.el-card__body {
    padding: 5px;
    display: flex;
    justify-content: space-between;
}

.el-card {
    border: none;
    border-radius: 0;
}

.el-card.is-always-shadow {
    box-shadow: none;
}

.box-card {
    display: flex;
    justify-content: space-between;
}

/deep/[data-v-255bf696] .el-card__body {
    display: flex;
    justify-content: space-between;

}

.card_div {
    display: flex;
    justify-content: space-around;
    text-align: right;

    p {
        padding: 0 10px;
    }
}

.block {
    text-align: right;
    line-height: 78px;
}

.card_top {
    display: flex;
    justify-content: space-between;

    p {
        margin: 0 20px;

        span {
            padding-right: 17px;
        }
    }
}

.card_bottom {
    display: flex;

    p {
        margin: 10px 25px;

        span {
            padding-right: 10px;
        }
    }
}

.name-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
}

/deep/.el-table th.el-table__cell>.cell,
.el-table th.el-table__cell>.cell {
    font-size: 12px;
}

.el-checkbox__label {
    font-size: 12px;
}

.htlb {
    display: flex;
    justify-content: space-between;

    .left {
        width: 1100px;
    }

    .right {

        /deep/[data-v-0d4ecc32] .el-card__body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}

/deep/.el-card__body {
    padding: 5px;
    display: flex;
    justify-content: space-between;
}

.box-card {
    display: flex;
    justify-content: space-between;
}

/deep/[data-v-255bf696] .el-card__body {
    display: flex;
    justify-content: space-between;

}

.card_name {
    display: flex;
    justify-content: space-between;
}

.card_div {
    display: flex;
    justify-content: space-around;
    text-align: right;

    p {
        padding: 0 10px;
    }
}

.block {
    text-align: right;
    line-height: 78px;
}

.left_top {
    display: flex;
    justify-content: space-between;

    p {
        margin: 0 20px;

        span {
            padding-right: 17px;
        }
    }
}

#card_left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.left_bottom {
    display: flex;

    p {
        margin: 10px 25px;

        span {
            padding-right: 10px;
        }
    }
}

.name-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.card_address {
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    background-color: #FFF;
    overflow: hidden;
    color: #303133;
    transition: .3s;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    display: flex;

    p {
        margin: 10px 15px;
    }
}

.card_name {
    position: relative;

    .titleText {
        font-weight: bold;
        font-size: 18px;
    }

    .openClose {
        font-size: 14px;
        // color: #25dbe6;
        color: skyblue;
        cursor: pointer;
    }

    .openSpan {
        position: absolute;
        right: -50px;
        cursor: pointer;
    }

    .statusText {
        overflow: hidden;
        display: block;
    }

    .statusText:after {
        position: absolute;
        bottom: 0;
        width: 100px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.2), #fff 45%);
    }
}

.el-dialog {
    i {
        color: orange;
        font-size: 18px;
        padding-right: 7px;
    }
}

.sx_dialog .el-dialog .el-date-editor.el-input {
    width: 122px;

    ::placeholder {
        font-size: 12px;
    }
}

.el-textarea {
    width: 575px;
}

.dialog_bottom {
    margin-left: -8px;
}

.dialog_top {
    display: flex;
    justify-content: space-around;

    /deep/.dialog_right .el-select {
        margin-left: 10px;
    }
}

.tj_dialog {
    .el-dialog {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}

.dialog_right .btn {
    margin-left: 10px;
    width: 250px;
}


.btn_elbtn {
    width: 300px;

    .el-button+.el-button {
        margin-left: 0;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

.btn_elbtn .el-button:nth-child(1) {
    margin-right: 10px;
}

.el-select {
    margin-left: 0;
}

.dialog_center {
    .el-form {
        width: 700px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

}

/deep/.right {
    [data-v-4efe5b9e] .el-card__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.money_list {
    box-sizing: border-box;
    padding: 0 54px;
    display: flex;
    flex-wrap: wrap;
}

.money_item {
    width: 50%;
    margin-bottom: 10px;
}

.money_item_input {
    margin: 0 10px;
}
</style>