<template>
    <div style="margin-right: 20px;">
        <el-card class="box-card">
            <div style="display: flex;">
                <p><el-input placeholder="请输入客户名称或联系方式" v-model="input2" clearable class="input-with-select">
                        <el-button slot="append" icon="el-icon-search" @click="searchBtnInfo()"></el-button>
                    </el-input></p>
                <p><el-button type="primary" style="margin-left: 10px;" @click="resetInput">重置</el-button></p>
            </div>
            <div class="card_div">
                <p><el-button type="primary" plain size="medium">导入</el-button></p>
                <p><el-button type="primary" plain size="medium">导出</el-button></p>
                <p><el-button plain size="medium" class="scxz" :disabled="multipleSelection.length == 0"
                        @click="deleteData">删除选中</el-button>
                </p>
				<p><el-button plain size="medium" class="scxz" :disabled="multipleSelection.length == 0"
				        @click="zjDialogVisible=true">转派</el-button>
				</p>
            </div>
        </el-card>
        <el-card>
            <div style="padding:10px;" class="card_name">
                <div id="card_left" :style="{ 'max-height': status ? textHeight : '' }" :class="{ statusText: status }"
                    class="titleText" ref="desc1">
                    <div class="left_top">
                        <p><span>客户筛选</span>
                            <el-select v-model="filter" placeholder="请选择" value-key="filter" @change="selectFilter">
                                <el-option v-for="item in options1" :key="item.filter" :label="item.name"
                                    :value="item.filter">
                                </el-option>
                            </el-select>
                        </p>
                        <p><span>客户级别</span>
                            <el-select v-model="level" placeholder="请选择" value-key="filter" @change="selectLevel">
                                <el-option v-for="item in options2" :key="item.level" :label="item.name1"
                                    :value="item.level">
                                </el-option>
                            </el-select>
                        </p>
                        <p><span>项目类型</span>
                            <el-select v-model="project_type" placeholder="请选择" value-key="id" @change="selectproject_type">
                                <el-option v-for="item in options3" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </p>
                        <p><span>&emsp;创建人</span>
                            <el-select v-model="creator" placeholder="请选择" value-key="id" @change="selectcreator">
                                <el-option v-for="item in options5" :key="item.id" :label="item.nickname" :value="item.id">
                                </el-option>
                            </el-select>
                        </p>
                    </div>
                    <div class="left_bottom">
                        <p><span>创建时间</span>
                            <el-date-picker v-model="createTime" style="width: 240px;" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels @change="getDate1()"
                                value-format="timestamp" :picker-options="{
                                        disabledDate(time) { // 设置禁用状态，参数为当前日期，要求返回 Boolean
                                            return time.getTime() > Date.now();
                                        }
                                    }">
                            </el-date-picker>
                        </p>
                        <p><span>负责商务</span>
                            <el-select v-model="nickname" filterable placeholder="请选择" value-key="id" @change="selectnickname">
                                <el-option v-for="item in options9" :key="item.id" :label="item.nickname" :value="item.id">
                                </el-option>
                            </el-select>
                        </p>
                        <p><span>接单时间</span>
                            <el-date-picker v-model="jiedanTime" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels @change="getDate2()"
                                value-format="timestamp" :picker-options="{
                                        disabledDate(time) { // 设置禁用状态，参数为当前日期，要求返回 Boolean
                                            return time.getTime() > Date.now();
                                        }
                                    }">
                            </el-date-picker>
                        </p>
						<p><span>地区</span>
						    <el-select v-model="department_id" placeholder="请选择" value-key="id" @change="selectAddress">
						        <el-option v-for="item in address_options" :key="item.id" :label="item.name" :value="item.id">
						        </el-option>
						    </el-select>
						</p>
                    </div>

                </div>
                <div id="card_right" v-if="idShowText" @click="status = !status" :class="{ openSpan: status }"
                    class="openClose">
                    {{ status ? "展开" : "收起" }}
                    {{ status ? '﹀' : '︿' }}
                </div>
            </div>
        </el-card>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;"
            @selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }">
            <el-table-column type="selection" width="45" align="center">
            </el-table-column>
            <el-table-column class="kehumiengcheng" label="客户名称" align="center">
                <template slot-scope="scope">
                    <!-- <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.name }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.name }}
                        </div>
                    </el-popover> -->
                    {{ scope.row.name }}
                </template>
            </el-table-column>
            <el-table-column label="联系方式" width="115" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.mobile
                        }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.mobile
                            }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="微信/QQ" width="115" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.third_contact }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.third_contact }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="客户级别" width="80" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.level }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.level }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="项目类型" width="120" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.project_name }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.project_name }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="跟进记录" width="130" align="center">
                <template slot-scope="scope">
                    <el-popover placement="top" width="400" trigger="click">
                        <div slot="reference" class="name-wrapper" style="color: blue;cursor: pointer;"
                            @click="openDetails(scope.row.id)">
                            {{ scope.row.follow[0]
                                ? scope.row.follow[0].createtime :
                                ""
                            }}
                            {{ scope.row.follow[0] ? scope.row.follow[0].desc : "" }}
                        </div>
                        <el-timeline :reverse="reverse">
                            <el-timeline-item v-for="(item, index) in scope.row.follow || []" :key="index">
                                <p>{{ item.admin_nickname }}{{ item.createtime }}</p>
                                <p>{{ item.desc }}</p>
                            </el-timeline-item>
                        </el-timeline>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="读取记录" width="115" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p v-for="item in scope.row.read_log">{{ item.add_time }}&emsp;{{ item.u_nickname }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.read_log[0] ? scope.row.read_log[0].add_time : '' }}{{ scope.row.read_log[0] ?
                                scope.row.read_log[0].u_nickname : '' }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="创建人" width="80" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.admin_nickname }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.admin_nickname }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" width="140" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.createtime }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.createtime }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <!-- <el-table-column label="派单时间" width="125" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.paidan_time }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.paidan_time }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column> -->
            <el-table-column label="商务" width="70" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.business_nicename }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.business_nicename }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <!-- <el-table-column label="接单时间" width="125" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.jiedan_time }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.jiedan_time }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column> -->

            <el-table-column label="备注" width="150" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.remark }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.remark }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" @click="editData(scope.row)" type="text">编辑</el-button>
                    <el-button size="mini" @click="open(scope.row)" type="text">放入公海</el-button>
                    <el-button size="mini" @click="getaddContractData(scope.row)" type="text">新增合同</el-button>
                    <!-- <el-button size="mini" @click="addJl(scope.row)" type="text">新增记录</el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[15, 30]" :page-size="15" layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
        </div>
        <!-- 编辑弹出框 -->
        <div class="tj_dialog">
            <el-dialog title="编辑客户" :visible.sync="editcenterDialogVisible" width="1000px" center append-to-body @close="editcenterDialogVisible_close">
                <div class="dialog_top">
                    <div class="dialog_left">
                        <el-form :model="form">
                            <el-form-item label="客户名称" :label-width="formLabelWidth">
                                <el-input v-model="form.name" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入客户名称"></el-input>
                            </el-form-item>
                            <el-form-item label="联系方式" :label-width="formLabelWidth">
                                <el-input v-model="form.mobile" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入联系方式"></el-input>
                            </el-form-item>
                            <el-form-item label="微信/QQ" :label-width="formLabelWidth">
                                <el-input v-model="form.third_contact" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入微信/QQ"></el-input>
                            </el-form-item>
                            <el-form-item label="客户级别" :label-width="formLabelWidth">
                                <el-select v-model="form.level" placeholder="请选择" value-key="filter" @change="selectLevel1">
                                    <el-option v-for="item in options2" :key="item.level" :label="item.name1"
                                        :value="item.level">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="客户来源" :label-width="formLabelWidth">
                                <el-select v-model="form.from" placeholder="请选择" value-key="id" @change="selectfrom1">
                                    <el-option v-for="item in options4" :key="item.from" :label="item.name"
                                        :value="item.from">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="dialog_right" style="display: flex;">
                        <el-form>
                            <el-form-item label="项目类型" label-width="110px">
                                <div class="btn">
                                    <div class="btn_elbtn">
                                        <div class="status_btn" :class="item.id == project_id ? 'checked' : ''"
                                            v-for="(item, index) in btnlist" :key="index" @click="getProjectId(item.id)">{{
                                                item.name }}</div>
                                    </div>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div class="dialog_bottom">
                    <el-form>
                        <el-form-item label="添加备注" :label-width="formLabelWidth">
                            <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 10 }" placeholder="请输入内容"
                                v-model="form.remark">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="跟进记录详情" :label-width="formLabelWidth">
                            <!-- <el-input type="textarea" :rows="10" placeholder="请输入内容" v-model="textarea2">
                            </el-input> -->
                            <div class="buzhoutiao" style="overflow-y: scroll;">
                                <!-- <el-steps direction="vertical" :active="1">
                                    <el-step title="步骤 3" space="200px" description="这是一段很长很长很长的描述性文字"
                                        v-for="( item, index ) in  10 " :key="index"></el-step>
                                </el-steps> -->
                                <el-timeline :reverse="reverse">
                                    <el-timeline-item v-for="(item, index) in followData || []" :key="index">
                                        <p>{{ item.admin_nickname }}{{ item.createtime }}</p>
                                        <p>{{ item.desc }}</p>
                                    </el-timeline-item>
                                </el-timeline>
                            </div>

                        </el-form-item>
                        <label for=""
                            style="margin-left: 50px; display: inline-block;height: 50px;font-size: 18px;text-align: center;">添加跟进记录</label>
                        <!-- <el-form-item label="文字描述" :label-width="formLabelWidth">
                            <el-input type="textarea" :autosize="{ minRows: 10, maxRows: 3 }" placeholder="请输入文字内容"
                                v-model="textarea2" style="width: 300px;">
                            </el-input>
                        </el-form-item> -->
                        <el-form-item label="文字描述" :label-width="formLabelWidth">
                            <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="textarea2">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="上传图片" :label-width="formLabelWidth">
                            <el-upload ref="upload_upload" class="upload-demo" action="https://crm.nanjingyunpeng.cn/api/v1/customer/uploads"
                                :on-success="handleAvatarSuccess1" :on-remove="handleRemove" :before-remove="beforeRemove"
                                multiple :limit="3" :on-exceed="handleExceed" :headers="token">
                                <el-button size=" small" type="primary">点击上传</el-button>
                            </el-upload>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="editcenterDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="sureEdit">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 新增合同弹出框 -->
        <div class="tj_dialog">
            <el-dialog title="新增合同" :visible.sync="addcenterDialogVisible" width="1000px" center append-to-body>
                <div class="dialog_top">
                    <div class="dialog_left">
                        <el-form :model="contractForm">
                            <el-form-item label="合同编号" :label-width="formLabelWidth">
                                <el-input v-model="contractForm.contract_no" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入合同编号"></el-input>
                            </el-form-item>
                            <el-form-item label="合同名称" :label-width="formLabelWidth">
                                <el-input v-model="contractForm.name" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入合同名称"></el-input>
                            </el-form-item>
                            <el-form-item label="项目名称" :label-width="formLabelWidth">
                                <el-input v-model="contractForm.project_name" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入项目名称"></el-input>
                            </el-form-item>
                            <el-form-item label="合同金额" :label-width="formLabelWidth">
                                <el-input v-model="contractForm.money" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入合同金额"></el-input>
                            </el-form-item>
                            <el-form-item label="有效金额" :label-width="formLabelWidth">
                                <el-input v-model="contractForm.valid_money" autocomplete="off" style="width: 217px;"
                                    placeholder="请输入有效金额"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="dialog_right" style="display: flex;">
                        <el-form>
                            <el-form-item label="项目类型" label-width="110px">
                                <div class="btn">
                                    <div class="btn_elbtn">
                                        <div class="status_btn" :class="item.id == project_id ? 'checked' : ''"
                                            v-for="( item, index ) in  btnlist " :key="index"
                                            @click="getProjectId(item.id)">{{
                                                item.name }}</div>
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item label="签约时间" :label-width="formLabelWidth">
                                <el-date-picker v-model="qyData" type="date" placeholder="选择日期" @change="qyDataClick()"
                                    :picker-options="{
                                            disabledDate(time) { // 设置禁用状态，参数为当前日期，要求返回 Boolean
                                                return time.getTime() > Date.now();
                                            }
                                        }
                                        ">
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-divider></el-divider>
                <div class="dialog_center">
                    <el-form :model="contractForm">
                        <el-form-item label="打款次数" :label-width="formLabelWidth">
                            <el-select v-model="contractForm.Dkcs" placeholder="请选择打款次数" value-key="id"
                                @change="selectDkcs">
                                <el-option v-for=" item  in  options6 " :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <div class="money_list">
                        <div class="money_item" v-for="( item, index ) in  moneyList " :key="index" v-if="dkId !== '' && index <
                            Number(dkId) + 1
                            ">
                            <label>{{ item.title }}</label>
                            <el-input class="money_item_input" v-model="item.moneyCount" autocomplete="off"
                                style="width: 100px;margin-right: 10px;" placeholder="20%"></el-input>
                            <label>{{ item.label }} {{ !item.moneyCount ? 0 : (parseFloat(item.moneyCount)
                                * parseFloat(contractForm.valid_money / 100))
                            }}</label>
                        </div>
                    </div>
                </div>
                <el-divider></el-divider>
                <div class="dialog_bottom">
                    <el-form>
                        <el-form-item label="上传附件" :label-width="formLabelWidth">
                            <el-upload class="upload-demo" action="https://crm.nanjingyunpeng.cn/api/v1/customer/uploads"
                                :on-success="handleAvatarSuccess2" :on-preview="handlePreview" :on-remove="handleRemove"
                                :before-remove="beforeRemove" multiple :limit="3" :on-exceed="handleExceed"
                                :headers="token">
                                <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addcenterDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addContractInfo()">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 转接弹出框选择商务 -->
        <div class="tj_dialog">
            <el-dialog title="客户转接" :visible.sync="zjDialogVisible" width="600px" center append-to-body>
                <div class="dialog_bottom">
                    <el-form>
                        <el-form-item label="转派商务" :label-width="formLabelWidth">
                            <el-select v-model="zjname" filterable placeholder="请选择" value-key="id" @change="selectZj">
                                <el-option v-for="item in options10" :key="item.id" :label="item.nickname" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="zjDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="orderzhuanData">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { getproject, getcreator } from '../../api/common/common'
import { getData, getRading, putSea, addfollow, addContract, delData, orderzhuanData } from '../../api/Khgl/Khlb'
import { alterCustomInfo,business_disbumens } from '../../api/yunyingguanli/dpkh'
import { getstaffList } from '../../api/Swgl/Jdcsjl'
import { timestampToTime, getTimeFormatDate } from "../../utils/publicFun"
export default {
    data() {
        return {
            // tableHeight: '200',
            fromId: '',
            reverse: true,
            activities: [],
            tableData: [],
            btnlist: [],
            editcenterDialogVisible: false,
            addcenterDialogVisible: false,
            zjDialogVisible: false,//转接
            form: {
                name: '',
                mobile: '',
                third_contact: '',
                level: '',
                from: "",
                remark: '',
            },
            nickname: '',
            input2: '',
            project_id: '',
            contractForm: {
                Dkcs: '',
                contract_no: '',
                name: '',
                project_name: '',
                money: '',
                valid_money: ''
            },
            fileData: '',
            formLabelWidth: '120px',
            textarea2: '',
            textHeight: null,
            status: false,
			department_id:"",
            idShowText: false,
            createTime: '',
            jiedanTime: '',
            multipleSelection: [],
            currentPage: 1,
            filter: 1,
            level: '',
            from: '',
            project_type: '',
            value4: '',
            creator: '',
            value1: '',
            value2: '',
            value3: '',
            value5: '',
            qyData: '',
            dateQY: '',
            fileData2: '',
            totalCount: 0,
            row: [],
            arr: [],
            newArr: [],
            options: [
                {
                    state: 0,
                    name: "全部"
                },
                {
                    state: 1,
                    name: "待接单"
                },
                {
                    state: 2,
                    name: "已接单"
                },
                {
                    state: 3,
                    name: "已超时"
                }
            ],
            options1: [
                {
                    filter: 0,
                    name: "全部"
                },
                {
                    filter: 1,
                    name: "我负责"
                },
                {
                    filter: 2,
                    name: "我的下属负责"
                },
            ],
            options2: [
                {
                    level: "",
                    name: "",
                    name1: '全部'
                },
                {
                    level: 0,
                    name: "A",
                    name1: 'A（准客户）'
                },
                {
                    level: 1,
                    name: "B",
                    name1: 'B（意向客户）'
                },
                {
                    level: 2,
                    name: "C",
                    name1: 'C（一般客户）'
                },
                {
                    level: 3,
                    name: "D",
                    name1: 'D（有效客户）'
                },
                {
                    level: 4,
                    name: "K",
                    name1: 'K（低效客户）'
                },
                {
                    level: 5,
                    name: "P",
                    name1: 'P（违法客户）'
                }
            ],
            options3: [],
            options5: [],
            options4: [
                {
                    from: "",
                    name: "全部"
                },
                {
                    from: 1,
                    name: "淘宝"
                },
                {
                    from: 2,
                    name: "百度"
                },
            ],
            options6: [
                {
                    id: 0,
                    name: "1"
                },
                {
                    id: 1,
                    name: "2"
                },
                {
                    id: 2,
                    name: "3"
                },
                {
                    id: 3,
                    name: "4"
                },
                {
                    id: 4,
                    name: "5"
                }
            ],
			address_options:[],
            createTime: '',
            createtime: '',
            jiedantime: '',
            dkId: '',
            crr1: '',
            crr2: '',
            moneyList: [
                {
                    title: "一期款",
                    moneyCount: "",
                    label: "金额",
                    isShow: false
                },
                {
                    title: "二期款",
                    moneyCount: "",
                    label: "金额",
                    isShow: false
                },
                {
                    title: "三期款",
                    moneyCount: "",
                    label: "金额",
                    isShow: false
                },
                {
                    title: "四期款",
                    moneyCount: "",
                    label: "金额",
                    isShow: false
                },
                {
                    title: "五期款",
                    moneyCount: "",
                    label: "金额",
                    isShow: false
                },
            ],

            row_list: [],
            row_arr: [],
            options9: [],
			options10: [],
            typea: '',
            shijian: '',
            date2: '',
            data4: '',
            data5: '',
            data6: '',
            data7: '',
            data8: '',
            fileArr: [],
            filterId: 1,
            levalName1: '',
            levalName: '',
            followData: [],
			zjname:"",
			buid:"",//转派接收人
        }
    },
    created() {
        let temp = window.localStorage.getItem("admin-token")
        this.token = {
            "token": temp
        }
        this.typea = this.$route.query.type
        console.log(this.typea);
        if (this.typea == 1) {
            let arr = getTimeFormatDate('today', "datetime")
            console.log(arr);
            let date1 = new Date(arr[0])
            this.date2 = Date.parse(date1) / 1000 + ''
            let date3 = new Date(arr[1])
            this.date4 = Date.parse(date3) / 1000 + ''
            console.log(this.date2);
            console.log(this.date4);
            this.shijian = this.date2 + '-' + this.date4
            console.log(this.shijian);
            this.crr1 = this.shijian
            // console.log(this.createtime);
            this.getListData()
        }
        if (this.typea == 2) {
            let arr = getTimeFormatDate('week', "datetime")
            console.log(arr);
            let date1 = new Date(arr[0])
            this.date5 = Date.parse(date1) / 1000 + ''
            let date3 = new Date(arr[1])
            this.date6 = Date.parse(date3) / 1000 + ''
            console.log(this.date5);
            console.log(this.date6);
            this.shijian = this.date5 + '-' + this.date6
            console.log(this.shijian);
            this.crr1 = this.shijian
            // console.log(this.createtime);
            this.getListData()
        }
        if (this.typea == 3) {
            let arr = getTimeFormatDate('month', "datetime")
            console.log(arr);
            let date1 = new Date(arr[0])
            this.date7 = Date.parse(date1) / 1000 + ''
            let date3 = new Date(arr[1])
            this.date8 = Date.parse(date3) / 1000 + ''
            console.log(this.date7);
            console.log(this.date8);
            this.shijian = this.date7 + '-' + this.date8
            console.log(this.shijian);
            this.crr1 = this.shijian
            // console.log(this.createtime);
            this.getListData()
        }
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.calculateText();
            }, 300);
        });
        this.getcreatorData()
        this.getproject_typeData()
        this.getListData(0)
        this.getstaffListData()
		this.getAddressListData()
    },
    methods: {
        editcenterDialogVisible_close(){
            this.textarea2 = ''
            this.$refs.upload_upload.clearFiles()
        },
		// 获取地区列表
		async getAddressListData() {
		    let res = await business_disbumens()
		    let obj = {
		        id: '',
		        name: '全部'
		    }
		    this.address_options = res.data.data
		    this.address_options.unshift(obj)
		},
		//选择地区
		selectAddress(id){
			let obj = {};
			obj = this.address_options.find((item) => {
			    return item.id === id; //筛选出匹配数据
			});
			this.department_id = obj.id
			this.nickname = ""
			this.businessId = ""
			this.getstaffListData()
			this.getListData()
		},
        // 获取商务列表
        async getstaffListData() {
            let res = await getstaffList({area_id:this.department_id||""})
            let obj = {
                id: '',
                nickname: '全部'
            }
			this.options10 = res.data.data
            this.options9 = JSON.parse(JSON.stringify(res.data.data))
            this.options9.unshift(obj)
        },
        // 获取商务列表的id
        selectnickname(id) {
            let obj = {};
            obj = this.options9.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            this.businessId = obj.id
            this.getListData()
        },
        handleAvatarSuccess1(res, file) {
            console.log(res);
            console.log(res.data.url);
            if (res.code == 2) {
                return this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "error"
                });
            }
            if (res) {
                console.log(res);
                this.fileData = res.data.url
                console.log(this.fileData);
                this.fileArr.push(this.fileData)
                console.log(this.fileArr);
                this.$message({
                    showClose: true,
                    message: "上传成功",
                    type: "success"
                });
            } else {
                this.$message({
                    showClose: true,
                    message: "上传失败",
                    type: "error"
                });
            }
        },
        handleAvatarSuccess2(res, file) {
            console.log(res);
            console.log(res.data.url);
            if (res.code == 2) {
                return this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "error"
                });
            }
            if (res) {
                console.log(res);
                this.fileData2 = res.data.url
                this.$message({
                    showClose: true,
                    message: "上传成功",
                    type: "success"
                });
            } else {
                this.$message({
                    showClose: true,
                    message: "上传失败",
                    type: "error"
                });
            }
        },
        // 获取跟进记录数据
        async openDetails(id) {
            console.log(id);
            let reqData = {
                customer_id: id
            }
            let res = await getRading(reqData)
            console.log(res);
            this.searchBtnInfo()
        },

        // 分页
        handleSizeChange() {
            // 每页显示数量 也就是pagesize发生变化时 要重置数据，和重置页码
            this.tableData = []
            this.currentPage = 1;
            this.pageSize = 15
            this.getListData()
        },
        handleCurrentChange(val) {
            // 当 页码发生变化时 重置数据
            this.tableData = []
            this.currentPage = val
            this.getListData()
        },
        // 全选
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
       
        // 展示与收起
        calculateText() {
            // 这是默认两行数据的高度，一行的高度可以自定义 可以*3 *4达到三行或者四行显示展示和收起的效果
            let twoHeight = 20 * 2;
            this.textHeight = `${twoHeight}px`;
            let curHeight = this.$refs.desc1.offsetHeight;
            console.log("curHeight", curHeight);
            console.log("twoHeight", twoHeight);
            if (curHeight > twoHeight) {
                this.status = true;
                this.idShowText = true;
            } else {
                this.status = false;
                this.idShowText = false;
            }
        },
        // 文件上传
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        // 获取客户项目类型
        async getproject_typeData() {
            let res = await getproject()
            console.log(res.data.data);
            let obj1 = {
                id: '',
                name: '全部'
            }

            this.options3 = res.data.data
            this.btnlist = res.data.data
            this.options3.unshift(obj1)
        },
        // 获取项目类型下拉框id
        selectproject_type(id) {
            console.log(id);
            let obj = {};
            obj = this.options3.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            console.log(obj);
            this.project_typeID = obj.id
            console.log(this.project_typeID);
            this.getListData()
        },
        // 获取项目类型按钮id
        getProjectId(id) {
            console.log(id);
            this.project_id = id
        },
        // 客户筛选
        selectFilter(id) {
            // if (id != 2) {
            //     this.getstaffListData()
            // }
            if (id == 1 || id == 0) {
                this.businessId = ""
                this.nickname = ""
            }
            console.log(id);
            let obj = {};
            obj = this.options1.find((item) => {
                return item.filter === id; //筛选出匹配数据
            });
            console.log(obj);
            this.filterId = obj.filter
            console.log(this.filterId);
            this.getListData()
        },
        // 客户级别
        selectLevel(id) {
            console.log(id);
            let obj = {};
            obj = this.options2.find((item) => {
                return item.level === id; //筛选出匹配数据
            });
            console.log(obj);
            this.levelId = obj.level
            this.levalName = obj.name
            console.log(this.levelId);
            this.getListData()
        },
        // 客户级别
        selectLevel1(id) {
            console.log(id);
            let obj = {};
            obj = this.options2.find((item) => {
                return item.level === id; //筛选出匹配数据
            });
            console.log(obj);
            this.levelId = obj.level
            this.levalName1 = obj.name
            console.log(this.levelId);
        },
        // 项目类型
        selectproject_type(id) {
            console.log(id);
            let obj = {};
            obj = this.options3.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            console.log(obj);
            this.project_typeID = obj.id
            console.log(this.project_typeID);
            this.getListData()
        },
        // 客户来源
        selectfrom(id) {
            console.log(id);
            let obj = {};
            obj = this.options4.find((item) => {
                return item.from === id; //筛选出匹配数据
            });
            console.log(obj);
            this.fromId = obj.from
            console.log(this.fromId);
            this.getListData()
        },
        // 客户来源
        selectfrom1(id) {
            console.log(id);
            let obj = {};
            obj = this.options4.find((item) => {
                return item.from === id; //筛选出匹配数据
            });
            console.log(obj);
            this.fromId = obj.from
            console.log(this.fromId);
        },
        // 获取创建人
        async getcreatorData() {
            let res = await getcreator()
            let obj2 = {
                id: '',
                nickname: '全部'
            }
            console.log(res.data.data);
            this.options5 = res.data.data
            this.options5.unshift(obj2)
        },
        // 创建人
        selectcreator(id) {
            console.log(id);
            let obj = {};
            obj = this.options5.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            console.log(obj);
            this.creator = obj.id
            console.log(this.creator);
            this.getListData()
        },
        selectDkcs(id) {
            console.log(id);
            this.dkId = id
        },
        // 获取客户列表数据
        async getListData() {
            let reqData = {
                page: this.currentPage,
                filter: this.filterId = "" ? 1 : this.filterId,
                level: this.levalName,
                project_type: this.project_typeID,
                creator_id: this.creator,
                createtime: this.crr1,
                catchtime: this.crr2,
                business_id: this.businessId,
				department_id:this.department_id
            }
            let res = await getData(reqData)
            if (res.data.code == 5) {
                this.$message({
                    type: 'error',
                    message: '暂无权限访问!'
                });
                this.$router.push({ path: "/Index/kongbai" });
            }
            // console.log(res);
            this.tableData = res.data.data.data
            this.totalCount = res.data.data.total

        },
        // setData(arr) {
        //     for (let i = 0; i < arr.length; i++) {
        //         arr[i].createtime = !arr[i].createtime ? '' : timestampToTime(arr[i].createtime * 1000)
        //     }
        // },
        // 编辑获取数据
        editData(data) {
            console.log(data);
            this.followData = data.follow
            this.editcenterDialogVisible = true
            this.form.name = data.name
            this.form.mobile = data.mobile
            this.form.third_contact = data.third_contact
            this.form.level = data.level
            this.form.from = data.from
            this.form.remark = data.remark
            this.id = data.id
            this.fromId = data.from
            this.levalName1 = data.level
            this.project_id = data.project_id
        },
        //编辑修改数据
        async sureEdit() {
            let reqData = {
                ids: this.id,
                name: this.form.name,
                mobile: this.form.mobile,
                third_contact: this.form.third_contact,
                level: this.levalName1,
                from: this.fromId = "" ? 1 : this.fromId,
                remark: this.form.remark,
                project_id: this.project_id,
                desc: this.textarea2,
                file: this.fileArr.join(',')
            }
            // console.log(reqData);
            let res = await alterCustomInfo(reqData)
            console.log(res);
            if (res.data.code == 1) {
                this.$message({
                    type: 'success',
                    message: '修改成功!'
                });
                this.getListData()
            }
            if (res.data.code == 0) {
                this.$message({
                    type: 'error',
                    message: res.data.msg
                });
            }
            this.editcenterDialogVisible = false
        },
        // 放入公海
        async open(data) {
            this.$confirm('确定要放入公海吗？', '提示', {
                type: 'warning',
                beforeClose: async (action, instance, done) => {
                    if (action === "confirm") {
                        // 调取接口
                        let reqData = {
                            customer_id: data.id,
                        }
                        let res = await putSea(reqData)
                        if (res.data.code == 1) {
                            this.$message({
                                type: 'success',
                                message: '操作成功!'
                            });
                            this.getListData()
                            done();
                        }
                        if (res.data.code == 0) {
                            this.$message({
                                type: 'error',
                                message: '非无效客户不能放入公海'
                            });
                            done();
                        }
                    } else {
                        this.$message({
                            type: 'info',
                            message: '操作失败'
                        });
                        done();
                    }
                },

            })
        },
        // 新增合同
        getaddContractData(data) {
            // console.log(data);
            this.addcenterDialogVisible = true
            this.id = data.id
            this.uid = data.id
        },
        async addContractInfo() {
            // console.log(this.contractForm.Dkcs);
            for (var i = 0; i <= this.contractForm.Dkcs; i++) {
                if (this.moneyList[i].moneyCount != '') {
                    var cardNumObj = {};
                    cardNumObj.percent = this.moneyList[i].moneyCount
                    console.log(cardNumObj.percent);
                    cardNumObj.money = parseFloat(this.moneyList[i].moneyCount) * parseFloat(this.contractForm.valid_money / 100)
                }
                this.row_list.push(cardNumObj)
            }
            console.log(this.row_list);
            let reqData = {
                customer_id: this.id,
                contract_no: this.contractForm.contract_no,
                name: this.contractForm.name,
                project_name: this.contractForm.project_name,
                money: this.contractForm.money,
                project_id: this.project_id,
                valid_money: this.contractForm.valid_money,
                signtime: this.dateQY / 1000,
                money_count: this.contractForm.Dkcs + 1,
                file: this.fileData2,
                uid: this.uid,
                row: this.row_list
            }
            let res = await addContract(reqData)
            console.log(res);
            if (res.data.code == 0) {
                this.$message({
                    type: 'error',
                    message: res.data.msg
                });
            }
            if (res.data.code == 1) {
                this.$message({
                    type: 'success',
                    message: '新增成功'
                });
            }
            console.log(res);
            console.log(reqData);
            this.addcenterDialogVisible = false
        },
        // 新增记录
        addJl(data) {
            console.log(data);
            this.ids = data.id
        },
        // 确定新增记录
        async addJlData() {
            let reqData = {
                customer_id: this.ids,
                desc: this.textarea2,
                file: this.fileArr.join(',')
            }
            console.log(reqData);
            let res = await addfollow(reqData)
            console.log(res);
            if (res.data.code == 1) {
                this.$message({
                    type: 'success',
                    message: '跟进成功!'
                });
                this.textarea2 = ""
                this.getListData()
            }
        },
        // 全选
        handleSelectionChange(val) {
            this.multipleSelection = val;
			let arr = []
            for (var i of this.multipleSelection) {
				arr.push(i.id)
            }
            this.newArr = arr;
        },
        // 删除选中
        async deleteData(data) {
            let reqData = {
                ids: this.newArr + '',
            }
            console.log(reqData);
            this.$confirm('确定要删除该数据吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await delData(reqData)
                if (res.data.code == 1) {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getListData()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 获取创建时间
        getDate1() {
            // console.log(this.createTime);
            this.createtime = this.createTime
            let brr1 = [];
            for (var i of this.createtime) {
                brr1.push(i / 1000)
            }
            this.crr1 = brr1.join('-')
            console.log(this.crr1);
            this.getListData()
        },
        // 获取接单时间
        getDate2() {
            console.log(this.jiedanTime)
            // console.log(this.createTime);
            this.jiedantime = this.jiedanTime
            let brr2 = [];
            for (var i of this.jiedantime) {
                brr2.push(i / 1000)
            }
            this.crr2 = brr2.join('-')
            console.log(this.crr2);
            this.getListData()
        },
        // 获取签约时间
        qyDataClick() {
            console.log(this.qyData);
            this.dateQY = Date.parse(this.qyData)
            console.log(this.dateQY);
        },
        // 重置输入框
        async resetInput() {
            this.input2 = ""
            this.level = ""
            this.project_type = ""
            this.from = ""
            this.createtime = ""
            this.catchtime = ""
            this.creator = ""
            this.nickname = ""
            let reqData = {
                page: this.currentPage,
                filter: this.filterId,
            }
            let res = await getData(reqData)
            this.tableData = res.data.data.data
            this.totalCount = res.data.data.total
        },
        // 搜索
        async searchBtnInfo() {
            console.log(this.input2);
            let reqData = {
                page: this.currentPage,
                filter: this.filterId,
                level: this.levelId,
                project_type: this.project_typeID,
                creator_id: this.creator,
                createtime: this.crr1,
                catchtime: this.crr2,
                business_id: this.businessId,
                search: this.input2
            }
            let res = await getData(reqData)
            if (this.input2) {
                this.tableData = res.data.data.data
                this.totalCount = res.data.data.total
            } else {
                this.getListData()
            }
            // this.input2 = ""
        },
		async orderzhuanData() {
			this.zjDialogVisible = false
			let reqData = {
			    oids: this.newArr + '',
				buid:this.buid
			}
			let res = await orderzhuanData(reqData)
			this.newArr = []
			this.multipleSelection = []
			this.$refs.multipleTable.clearSelection();
			if (res.data.code == 1) {
			    this.$message({
			        type: 'success',
			        message: '转派成功!'
			    });
			    this.getListData()
			}else{
				this.$message({
				    type: 'error',
				    message: res.data.msg
				});
			}
		},
		selectZj(e){
			this.buid = e
		}
    }
}
</script>

<style lang="less" scoped>
/deep/.el-card__body {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    // width: 1500px;
}

.el-card {
    border: none;
    border-radius: 0;
}

.el-card.is-always-shadow {
    box-shadow: none;
}

.box-card {
    display: flex;
    justify-content: space-between;
}

/deep/[data-v-255bf696] .el-card__body {
    display: flex;
    justify-content: space-between;

}

.card_name {
    display: flex;
    justify-content: space-between;
}

.card_div {
    display: flex;
    justify-content: space-around;
    text-align: right;

    p {
        padding: 0 10px;
    }
}

.block {
    text-align: right;
    line-height: 78px;
}

.left_top {
    display: flex;
    justify-content: space-between;

    p {
        margin: 0 20px;

        span {
            padding-right: 17px;
        }
    }
}

#card_left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.left_bottom {
    display: flex;
    // margin-left: 20px;

    p {
        margin: 10px 25px;

        span {
            padding-right: 10px;
        }
    }
}

.name-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.card_address {
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    background-color: #FFF;
    overflow: hidden;
    color: #303133;
    transition: .3s;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    display: flex;

    p {
        margin: 10px 15px;
    }
}

.card_name {
    position: relative;

    .titleText {
        font-weight: bold;
        font-size: 18px;
    }

    .openClose {
        font-size: 14px;
        // color: #25dbe6;
        color: skyblue;
        cursor: pointer;
    }

    .openSpan {
        // position: absolute;
        right: -50px;
        cursor: pointer;
    }

    .statusText {
        overflow: hidden;
        display: block;
    }

    .statusText:after {
        position: absolute;
        bottom: 0;
        width: 100px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.2), #fff 45%);
    }
}

.el-dialog {
    i {
        color: orange;
        font-size: 18px;
        padding-right: 7px;
    }
}

.sx_dialog .el-dialog .el-date-editor.el-input {
    width: 122px;

    ::placeholder {
        font-size: 12px;
    }
}

.el-textarea {
    width: 575px;
}

.dialog_bottom {
    margin-left: 60px;
}

.dialog_top {
    display: flex;
    justify-content: space-around;

    /deep/.dialog_right .el-select {
        margin-left: 10px;
    }
}

.tj_dialog {
    .el-dialog {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}

.dialog_right .btn {
    margin-left: 10px;
    width: 250px;
}


// .btn_elbtn {
//     width: 300px;

//     .el-button+.el-button {
//         margin-left: 0;
//         margin-right: 10px;
//         margin-bottom: 10px;
//     }
// }

// .btn_elbtn .el-button:nth-child(1) {
//     margin-right: 10px;
// }

// .btn_elbtn .el-button:nth-child(1) {
//     margin-right: 10px;
// }
.btn_elbtn {
    width: 300px;

    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start
}

.status_btn {
    padding: 0 10px;
    box-sizing: border-box;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid #999;
    border-radius: 10px;
    line-height: 30px;
}

.checked {
    background-color: #409EFF;
    color: #fff;
    border: none;
}

.el-select {
    margin-left: 0;
}

.dialog_center {
    .el-form {
        width: 700px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

}

/deep/.left_bottom[data-v-6bfd8bdf] {
    margin-left: 20px;
}

/deep/.el-date-editor.el-input {
    width: 200px;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 210px;
}

.money_list {
    box-sizing: border-box;
    padding: 0 54px;
    display: flex;
    flex-wrap: wrap;
}

.money_item {
    width: 50%;
    margin-bottom: 10px;
}

.money_item_input {
    margin: 0 10px;
}

.buzhoutiao {
    width: 550px;
    height: 400px;
    // border: 1px solid #ccc;
    overflow: hidden;
}
</style>