import { request } from "../../utils/request"
//
export function getData(data) {
    return request({
        url: '/customer/list',
        method: 'post',
        data,
    })
}
// 获取跟进记录/customer/sea
export function getRading(data) {
    return request({
        url: '/customer/reading',
        method: 'post',
        data,
    })
}
// 放入公海
export function putSea(data) {
    return request({
        url: '/customer/sea',
        method: 'post',
        data,
    })
}
// 新增记录/customer/follow
export function addfollow(data) {
    return request({
        url: '/customer/follow',
        method: 'post',
        data,
    })
}
// 上传图片
export function upload(data) {
    return request({
        url: '/customer/uploads',
        method: 'post',
        data,
    })
}
// 新增合同
export function addContract(data) {
    return request({
        url: '/contract/add',
        method: 'post',
        data,
    })
}
// 删除
export function delData(data) {
    return request({
        url: '/operate/user_del',
        method: 'post',
        data,
    })
}
// 转派
export function orderzhuanData(data) {
    return request({
        url: '/customer/orderzhuan',
        method: 'post',
        data,
    })
}
// 自拓新增记录/customer/follow_zt
export function addfollow_zt(data) {
    return request({
        url: '/customer/follow_zt',
        method: 'post',
        data,
    })
}