<template>
    <div style="margin-right: 20px;">
        <el-card class="box-card">
            <div style="display: flex;">
                <p><el-input placeholder="请输入角色名称" v-model="input2" class="input-with-select">
                        <el-button slot="append" icon="el-icon-search" @click="searchBtnInfo()"></el-button>
                    </el-input></p>
                <p><el-button type="primary" style="margin-left: 10px;">重置</el-button></p>
            </div>
            <div class="card_div">
                <p><el-button type="primary" plain size="medium" @click="adddialogFormVisible = true">添加角色</el-button>
                </p>
                <p><el-button plain size="medium" class="scxz" :disabled="multipleSelection.length == 0"
                        @click="deleteData">删除选中</el-button>
                </p>
            </div>
        </el-card>
        <el-table :data="tableData" @selection-change="handleSelectionChange" style="width: 100%;margin-bottom: 20px;"
            row-key="id" border default-expand-all :tree-props="{ children: 'childlist', hasChildren: 'hasChildren' }">
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column label="角色名称" prop="name" align="center">
            </el-table-column>
            <el-table-column label="备注" prop="remarks" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" @click="editData(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[15, 30]" :page-size="15" layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
        </div>
        <!-- 添加角色弹框 -->
        <div class="dialog">
            <el-dialog title="添加人员" :visible.sync="adddialogFormVisible" append-to-body>
                <el-form :model="addform">
                    <el-form-item label="绑定角色" :label-width="formLabelWidth">
                        <el-select v-model="selectTreeName" placeholder="请选择" :popper-append-to-body="false">
                            <el-option :value="selectTree" class="setstyle" style="overflow: auto; height: 100%" disabled
                                value-key="id">
                                <el-tree style="min-height: 150px; max-height: 300px" :data="data" :props="defaultPropsData"
                                    ref="tree" check-strictly :expand-on-click-node="false" @node-click="getId"></el-tree>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="角色名称" :label-width="formLabelWidth">
                        <el-input v-model="addform.name" autocomplete="off" placeholder="请输入角色名称"></el-input>
                    </el-form-item>
                    <el-form-item label="备注" :label-width="formLabelWidth">
                        <el-input v-model="addform.remarks" autocomplete="off" placeholder="请输入"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="权限信息" :label-width="formLabelWidth">
                        <el-tree :data="treedata" show-checkbox node-key="id" ref="tree" highlight-current
                            :props="defaultPropsdata2" :accordion="true" v-model="menuList" @check="mulCheck">
                        </el-tree>
                    </el-form-item> -->
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="adddialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addRolesInfo">确 定</el-button>
                </div>
            </el-dialog>
        </div>
        <!-- 编辑角色弹框 -->
        <div class="dialog">
            <el-dialog title="编辑人员" :visible.sync="editdialogFormVisible" append-to-body>
                <el-form :model="addform">
                    <el-form-item label="绑定角色" :label-width="formLabelWidth">
                        <el-select v-model="selectTreeName" placeholder="请选择" :popper-append-to-body="false">
                            <el-option :value="selectTree" class="setstyle" style="overflow: auto; height: 100%" disabled
                                value-key="id">
                                <el-tree style="min-height: 150px; max-height: 300px" :data="data" :props="defaultPropsData"
                                    ref="tree" check-strictly :expand-on-click-node="false" @node-click="getId"></el-tree>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="角色名称" :label-width="formLabelWidth">
                        <el-input v-model="addform.name" autocomplete="off" placeholder="请输入角色名称"></el-input>
                    </el-form-item>
                    <el-form-item label="备注" :label-width="formLabelWidth">
                        <el-input v-model="addform.remarks" autocomplete="off" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="权限信息" :label-width="formLabelWidth">
                        <el-tree :data="treedata2" show-checkbox node-key="id" ref="tree" highlight-current
                            :props="defaultProps" accordion v-model="menuList">
                        </el-tree>
                        <!-- @check="mulCheck" -->
                        <!-- :default-checked-keys="edit_Array"
                        :default-expanded-keys="edit_Array"  -->
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="editdialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="sureEdit()">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { get_roletree, roleInfo, roleDataInfo, delData, addRoles, getGroupName, editDataInfo } from '../../api/Jsqx'
import { toTreeData } from "./dataToTree.js";
export default {
    data() {
        return {
            edit_Array: [],
            tableData: [{
                name: '商务总监',
                beizhu: '阿巴巴'
            }],
            value: "",
            input2: '',
            currentPage: 1,
            arr: [],
            newArr: [],
            menuList: [],
            // normal: normal,
            addform: {
                name: '',
                remarks: '',
                rule_id: ''
            },
            data: [],
            totalCount: 0,
            formLabelWidth: '120px',
            adddialogFormVisible: false,
            editdialogFormVisible: false,
            multipleSelection: [],
            treedata: [],
            tempStrArr: [],
            parentID: '',
            selectTreeName: '',
            selectTree: '',
            defaultPropsData: {
                children: 'childlist',
                label: 'name',
                id: 'id',
            },
            defaultPropsdata2: {
                children: 'childlist',
                label: 'title',
                id: 'id',
            },
            treedata2: [],
            defaultProps: {
                children: 'children',
                label: 'text',
                id: 'id',
            }
        }
    },
    mounted() {
        this.getData()
        this.getRoleData()
    },
    methods: {
        // 分页
        handleSizeChange() {
            // 每页显示数量 也就是pagesize发生变化时 要重置数据，和重置页码
            this.tableData = []
            this.currentPage = 1;
            this.pageSize = 15
            this.getListData()
        },
        handleCurrentChange(val) {
            // 当 页码发生变化时 重置数据
            this.tableData = []
            this.currentPage = val
            this.getListData()
        },
        // 获取数据列表数据
        async getData() {
            let res = await roleInfo()
            console.log(res);
            this.tableData = res.data.data
            this.data = res.data.data
            console.log(this.data);
            if (res.data.code == 5) {
                this.$message({
                    type: 'error',
                    message: '暂无权限访问!'
                });
                this.$router.push({ path: "/Index/kongbai" });
            }
        },
        // 获取角色列表
        async getRoleData() {
            let res = await roleDataInfo()
            console.log(res);
            this.treedata = res.data.data
        },
        // 添加角色权限
        async addRolesInfo() {
            let tempMenuList = this.setData(this.menuList)
            // console.log(tempMenuList);
            let reqData = {
                pid: this.addform.rule_id,
                // rules: tempMenuList,
                name: this.addform.name,
                remarks: this.addform.remarks,
                status: 'normal'
            }
            console.log(reqData);
            let res = await addRoles(reqData)
            console.log(res);
            this.adddialogFormVisible = false
            this.getData()
        },
        // 编辑获取数据
        async editData(row) {
            this.ids = row.id
            this.pid = row.pid
            let reqData = {
                pid: this.pid
            }
            let res = await getGroupName(reqData)
            let selectName = res.data.data.name
            console.log(selectName);
            this.selectTreeName = selectName

            //获取平铺数据源
            let res2 = await get_roletree({ id: row.id, pid: row.pid })
            let menus = res2.data.data
            console.log(menus);

            //配置树的数据格式
            let attr = {
                id: "id",
                parent: "parent",
                text: "text",
                state: 'state',
                rootId: '#',
            };
            //组装成树
            let tree = toTreeData(JSON.parse(JSON.stringify(menus)), attr);
            console.log(tree);
            this.treedata2 = tree.length == 2 ? [tree[1]] : [tree[0]]//取前端需要展示的数据
            let arr2 = this.steamroller(this.treedata2) //获取所有的叶子节点
            let children = []
            //获取所有已选中的数据
            arr2.forEach(item => {
                if (item.state.selected) children.push(item)
            });

            this.editdialogFormVisible = true
            setTimeout(() => {
                //渲染已选中的数据
                this.$refs.tree.setCheckedNodes(children);
            }, 500);
            this.addform.name = row.name
            this.addform.remarks = row.remarks
        },
        //轮询获取所有选中的子节点
        steamroller(arr) {
            let newArr = [];
            let flat = function (item) {
                for (var i = 0; i < item.length; i++) {
                    if (item[i].children.length != 0) {
                        flat(item[i].children);
                    } else {
                        newArr.push(item[i]);
                    }
                }
            };
            flat(arr);
            return newArr;
        },
        // 确定编辑
        async sureEdit() {
            // let tempMenuList = this.setData(this.menuList)
            //获取所有选中的节点（包括父级）
            let a = this.$refs.tree.getCheckedNodes(false, true);
            let arr = []
            a.forEach(item => { arr.push(item.id) });
            console.log(arr.join(','));
            let reqData = {
                ids: this.ids,
                name: this.addform.name,
                remarks: this.addform.remarks,
                pid: this.pid,
                rules: arr.join(','),
                status: 'normal'
            }
            let res = await editDataInfo(reqData)
            console.log(res);
            this.editdialogFormVisible = false
            this.getData()
        },
        // 获取角色id
        getId(data) {
            console.log(data);
            this.addform.rule_id = data.id
            this.id = data.id
            console.log(this.addform.rule_id);
            this.selectTreeName = data.name;
            this.$nextTick(() => {
            })
        },
        // 选择节点
        mulCheck(allNode, selectNode) {
            console.log(allNode);
            console.log(selectNode.checkedNodes);
            this.menuList = selectNode.checkedNodes
            console.log(this.menuList);
        },
        setData(arr) {
            console.log(arr);
            // 存子ID
            let tempArr = [];
            // 存父ID的数组
            let fatherArr = [];
            let index = false;
            // 循环拿到的选中数组
            for (let i = 0; i < arr.length; i++) {
                console.log(i);
                // 判断父组件！=-1 你这个组件配置的 父选项也会被选中  数组里拿到一级目录的parentId=-1 把数组中父级给排除掉
                if (arr[i].haschild != 1) {
                    console.log(arr[i].haschild != 1);
                    // 子ID
                    let temp = {
                        id: arr[i].id
                    }
                    // 父id
                    let temp2 = {
                        id: arr[i].pid
                    }
                    // 子ID先存了
                    tempArr.push(temp)
                    index = false;
                    // 判断存父ID的数组有没有重复添加
                    for (let j = 0; j < fatherArr.length; j++) {
                        if (fatherArr[j].id == temp2.id) {
                            // index = true;说明存父ID的数组已经添加过了
                            index = true;
                        }
                    }
                    // index == false 说明上面的循环没找到temp2.id 说明父ID数组里没添加 给这一项push进去
                    if (index == false) {
                        fatherArr.push(temp2)
                    }
                }
            }
            // 最后把子id的数组和父ID的数组 拼接一下 返回 外面接收一下 就OK了
            tempArr = tempArr.concat(fatherArr);
            for (var i = 0; i < tempArr.length; i++) {
                console.log(tempArr[i].id);
                this.tempStrArr.push(tempArr[i].id)
            }
            let crr = [...new Set(this.tempStrArr)]
            let crrStr = crr + ''
            console.log(crrStr);
            return crrStr
        },

        // 全选
        handleSelectionChange(val) {
            this.multipleSelection = val;
            for (var i of this.multipleSelection) {
                this.arr.push(i.id)
                console.log(this.arr);
            }
            this.newArr = [...new Set(this.arr)];
            console.log(this.newArr);
        },
        // 删除选中
        async deleteData(data) {
            let reqData = {
                ids: this.newArr + '',
            }
            console.log(reqData);
            this.$confirm('确定要删除该数据吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await delData(reqData)
                if (res.data.code == 1) {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getData()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 搜索
        async searchBtnInfo() {
            let reqData = {
                search: this.input2
            }
            let res = await roleInfo(reqData)
            console.log(this.data);
            if (this.input2) {
                this.tableData = res.data.data
                this.data = res.data.data
            } else {
                this.getData()
            }
            this.input2 = ""
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.el-card__body {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    width: 1500px;
}

.el-card {
    border: none;
    border-radius: 0;
}

.el-card.is-always-shadow {
    box-shadow: none;
}

.box-card {
    display: flex;
    justify-content: space-between;
}

/deep/[data-v-255bf696] .el-card__body {
    display: flex;
    justify-content: space-between;

}

.card_div {
    display: flex;
    justify-content: space-around;
    text-align: right;

    p {
        padding: 0 10px;
    }
}

.block {
    text-align: right;
    line-height: 78px;
}

.card_top {
    display: flex;
    justify-content: space-between;

    p {
        margin: 0 20px;

        span {
            padding-right: 17px;
        }
    }
}

.card_bottom {
    display: flex;

    p {
        margin: 10px 25px;

        span {
            padding-right: 10px;
        }
    }
}

.name-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.dialog {
    height: 480px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    /deep/.el-input__inner {
        width: 220px;
    }
}

/deep/.el-dialog {
    width: 430px;
}

.dialog-footer,
.el-dialog__header {
    text-align: center;
}

/deep/.el-table .el-table__cell {
    z-index: 1000;
}
</style>