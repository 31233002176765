import { request } from "../../utils/request"
// 获取列表数据/contract/process
export function getProcessList(data) {
    return request({
        url: '/contract/processList',
        method: 'post',
        data,
    })
}
// 回款审核
export function shenheProcess(data) {
    return request({
        url: '/contract/process',
        method: 'post',
        data,
    })
}