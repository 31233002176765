import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Vuex from 'vuex'
Vue.use(Vuex)


import store from './store/index'

import axios from 'axios'
Vue.prototype.$axios = axios

import './plugins/element.js'
import {
  Message
} from 'element-ui'
Vue.prototype.$message = Message

import echarts from 'echarts'
Vue.prototype.$echarts = echarts

import 'default-passive-events'


Vue.config.productionTip = false
import '../src/css/global.css'

import '@/utils/rem.js';

window.addEventListener('popstate', function () {
  history.pushState(null, null, document.URL)
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
