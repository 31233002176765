<template>
    <div>
        暂无访问权限
    </div>
</template>

<script>
export default {
    name: 'XzzKongbai',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped></style>