// 这里是公用函数文件，一些公用函数都放这里

//时间戳转换成年月日时分秒  
export function timestampToTime(date) {
    var date = new Date(date);
    var YY = date.getFullYear() + '-';
    var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return YY + MM + DD + " " + hh + mm + ss;
}

// 获取今日本周本月日期格式
export function getTimeFormatDate(category, format) {
    format = format || 'date'
    let date = new Date(), startTime = ''
    let endTime = '';
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let today = date.getDate();

    switch (category) {
        case 'today':
            // 本周一的日期
            startTime = year + '-' + month + '-' + today;
            // 本周日的日期
            endTime = year + '-' + month + '-' + today;
            break;
        case 'week':
            // 本周日的日期
            let nowDayOfWeek = date.getDay() - 1, weekEndDate = new Date(year, date.getMonth(), today + (6 - nowDayOfWeek))
                , myyear = weekEndDate.getFullYear(), mymonth = weekEndDate.getMonth() + 1, myweekday = weekEndDate.getDate();
            if (mymonth < 10) {
                mymonth = "0" + mymonth;
            }
            if (myweekday < 10) {
                myweekday = "0" + myweekday;
            }
            endTime = myyear + "-" + mymonth + "-" + myweekday;

            date.setDate(date.getDate() - date.getDay() + 1);
            startTime = year + '-' + month + '-' + date.getDate();
            break;
        case 'month':
            console.log(month);
            startTime = new Date(year, month, 1)

            endTime = new Date(year, month, 0)
            startTime = year + '-' + month + '-' + startTime.getDate();

            if (month == 2) {
                if ((year % 4 == 0 && !(year % 100 == 0)) || year % 400 == 0) {
                    endTime = year + '-2' + '-29'
                } else {
                    endTime = year + '-2' + '-28'
                }
            } else {
                endTime = year + '-' + month + '-' + endTime.getDate();
            }
            break;
    }
    switch (format) {
        case 'date':
            return [startTime, endTime]
        case 'datetime':
            return [startTime + ' 00:00:00', endTime + ' 23:59:59']
    }
}
