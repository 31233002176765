<template>
  <div class="echarts_div">
    <div style="display: flex;justify-content: space-between;margin-bottom: 20px;">
      <el-card>
        <div class="card_top">
          <div style="font-size: 0.01rem;">签单总额与回款</div>
          <div style="display: flex;width: 280px;justify-content: flex-end;">
            <p v-for="(item, index) in month" @click="getBtnId(item.id, index)"
              :class="typeIndex == index ? 'type1' : 'type'">{{
                item.name
              }}</p>
          </div>
        </div>
        <div id="left">

        </div>
      </el-card>
      <el-card>
        <el-select v-model="value" placeholder="请选择" value-key="id" @change="getdistributeId">
          <el-option v-for="item in distribute" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <div id="center">
        </div>
      </el-card>
    </div>
    <el-card style="width: 538px;">
      <el-select v-model="value2" placeholder="请选择" value-key="id" @change="getreturnMoneyId">
        <el-option v-for="item in returnMoneyData" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <div id="right">
      </div>
    </el-card>

  </div>
</template>

<script>
import { dash_board } from '../../api/Ybp'
export default {
  name: 'CrmPcYibiaopan',

  data() {
    return {
      month2: '',
      value: "",
      value2: '',
      month: [
        {
          id: 'm',
          name: "本月"
        },
        {
          id: 'y',
          name: "全年"
        }
      ],
      distribute: [
        {
          id: 'm',
          name: "运营各地区本月派单数量"
        },
        {
          id: 'y',
          name: "运营全年派单数量"
        }
      ],
      returnMoneyData: [
        {
          id: 'm',
          name: "各地区本月回款额度"
        },
        {
          id: 'y',
          name: "各地区全年回款额度"
        }
      ],
      returnMoney: '',
      distributeData: [],
      newArr: [],
      distributeID: '',
      // getreturnMoneyId: '',
      getreturnMoneyIds: '',
      returnMoneyInfo: [],
      nameNewArr: [],
      countNewArr: [],
      returnMoneyNewnameArr: [],
      returnMoneyNewcountArr: [],
      typeIndex: 0
    };
  },

  mounted() {
    this.getdash_boardInfo()
  },
  created() {
    // this.getList();
    this.value = this.distribute[0].name;
    this.value2 = this.returnMoneyData[0].name;
  },
  methods: {
    myEcharts() {
      var chartDom = document.getElementById('left');
      const myCharts = this.$echarts.init(chartDom);
      var option;
      option = {
        title: {
          textStyle: {
            fontSize: 14
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: ['金额']
        },
        series: [
          {
            name: '签单额度',
            type: 'bar',
            data: [this.qdMonry]
          },
          {
            name: '回款额度',
            type: 'bar',
            data: [this.returnMoney],
          }
        ]
      };
      option && myCharts.setOption(option)
    },
    myEchart1() {
      var chartDom = document.getElementById('center');
      var myChart = this.$echarts.init(chartDom);
      var option;
      option = {
        xAxis: {
          type: 'category',
          data: this.nameNewArr
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.countNewArr,
            type: 'bar'
          }
        ]
      };
      console.log(this.newArr);
      option && myChart.setOption(option);
    },
    myEchart2() {
      var chartDom = document.getElementById('right');
      var myChart = this.$echarts.init(chartDom);
      var option;
      option = {
        xAxis: {
          type: 'category',
          data: this.returnMoneyNewnameArr
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.returnMoneyNewcountArr,
            type: 'bar'
          }
        ]
      };
      console.log(this.newArr);
      option && myChart.setOption(option);
    },
    // 获取id
    getBtnId(data, index) {
      console.log(data);
      this.month2 = data
      this.getdash_boardInfo()
      this.typeIndex = index
    },
    // 获取下拉框的id
    getdistributeId(id) {
      console.log(id);
      this.distributeID = id
      this.getdash_boardInfo()
    },
    getreturnMoneyId(id) {
      console.log(id);
      this.getreturnMoneyIds = id
      this.getdash_boardInfo()
    },
    // 获取仪表盘数据
    async getdash_boardInfo() {

      let reqData = {
        money: this.month2,
        distribute: this.distributeID,
        dis_returnmoney: this.getreturnMoneyIds
      }
      let res = await dash_board(reqData)
      console.log(res);
      if (res.data.code == 5) {
        this.$message({
          type: 'error',
          message: '暂无权限访问!'
        });
        this.$router.push({ path: "/Index/kongbai" });
      } else {
        // 回款额度
        this.returnMoney = res.data.data.return
        console.log(this.returnMoney);
        this.qdMonry = res.data.data.sign
        console.log(this.qdMonry);
        // 运营派单
        this.departmentCatchData = res.data.data.departmentCatch
        console.log(this.departmentCatchData);
        let nameArr = []
        let countArr = []
        for (var i = 0; i < this.departmentCatchData.length; i++) {
          nameArr.push(this.departmentCatchData[i].name)
          countArr.push(this.departmentCatchData[i].count)
        }
        this.nameNewArr = nameArr
        console.log(this.nameNewArr);
        this.countNewArr = countArr
        console.log(this.countNewArr);
        // 各地区回款
        this.returnMoneyInfo = res.data.data.return_money
        console.log(this.returnMoneyInfo);
        let returnMoneynameArr = []
        let returnMoneycountArr = []
        for (var i = 0; i < this.returnMoneyInfo.length; i++) {
          returnMoneynameArr.push(this.returnMoneyInfo[i].name)
          returnMoneycountArr.push(this.returnMoneyInfo[i].count)
        }
        this.returnMoneyNewnameArr = returnMoneynameArr
        this.returnMoneyNewcountArr = returnMoneycountArr
        this.myEcharts()
        this.myEchart1()
        this.myEchart2()
      }
    }
  },
};
</script>

<style lang="less" scoped>
.echarts_div {
  // display: flex;
  // justify-content: space-around;
  margin-top: 10px;
  margin-left: 10px;
}

#left {
  width: 500px;
  height: 250px;
  background-color: #fff;
}

#right {
  width: 500px;
  height: 250px;
  background-color: #fff;
}

#center {
  width: 500px;
  height: 250px;
  background-color: #fff;
}

.card_top {
  display: flex;
  justify-content: space-between;
}

.type {
  width: 24%;
  height: 100%;
  text-align: center;
  line-height: 40px;
  background-color: #ecf5ff;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 5px;
}

.type1 {
  background-color: #409EFF;
  width: 24%;
  height: 100%;
  text-align: center;
  line-height: 40px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  margin: 0 5px;
}

.card_center {
  width: 300px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

label {
  font-weight: bold;
}
</style>