import { request } from "../../utils/request"
// 地区部门数据列表
export function department(data) {
    return request({
        url: '/department/departmentList',
        method: 'post',
        data,
    })
}
// 添加部门
export function adddepartment(data) {
    return request({
        url: '/department/add',
        method: 'post',
        data,
    })
}
// 删除部门
export function deletedepartment(data) {
    return request({
        url: '/department/del',
        method: 'post',
        data,
    })
}
// 编辑部门
export function editdepartment(data) {
    return request({
        url: '/department/edit',
        method: 'post',
        data,
    })
}