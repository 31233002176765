import { request } from "../../utils/request"

export function getdashboard(data) {
    return request({
        url: '/operate/dashboard',
        method: 'post',
        data,
    })
}
// 获取右边部门数据
export function yykb_list(data) {
    return request({
        url: '/admin/yykb_list',
        method: 'post',
        data,
    })
}