<template>
    <div style="margin-right: 20px;">
        <el-card class="box-card">
            <div style="display: flex;">
                <p><el-input placeholder="请输入客户名称或联系方式" v-model="input2" class="input-with-select">
                        <el-button slot="append" icon="el-icon-search" @click="searchBtnInfo()"></el-button>
                    </el-input></p>
                <p><el-button type="primary" style="margin-left: 10px;" @click="resetInput()">重置</el-button></p>
            </div>
            <div class="card_div">
                <p><el-button type="primary" plain size="medium">导入</el-button></p>
                <p><el-button type="primary" plain size="medium">导出</el-button></p>
                <p><el-button plain size="medium" class="scxz" :disabled="multipleSelection.length == 0">删除选中</el-button>
                </p>
            </div>
        </el-card>
        <el-card>
            <div style="padding:10px;">
                <div class="card_top">
                    <p><span>客户筛选</span>
                        <el-select v-model="filter" placeholder="请选择" value-key="filter" @change="selectFilter">
                            <el-option v-for="item in options1" :key="item.filter" :label="item.name" :value="item.filter">
                            </el-option>
                        </el-select>
                    </p>
                    <p><span>选择状态</span>
                        <el-select v-model="stateValue" placeholder="请选择" value-key="id" @change="selectStateID">
                            <el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </p>
                    <p><span>项目类型</span>
                        <el-select v-model="project_type" placeholder="请选择" value-key="id" @change="selectproject_type">
                            <el-option v-for="item in options3" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </p>
                </div>
            </div>
        </el-card>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;"
            @selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }">
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column label="合同编号" width="90" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.contract_no }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.contract_no }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="客户名称" width="160" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.customer_name }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.customer_name }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="项目名称" width="160" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.project_name }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.project_name }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="项目类型" width="150" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.category_name }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.category_name }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="款期" width="120" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.installment }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.installment }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="回款金额" width="180" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.money }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.money }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>

            <el-table-column label="状态" width="160" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <!-- <p>{{ scope.row.state }}</p> -->
                        <p v-if="scope.row.state == 0">待审核</p>
                        <p v-if="scope.row.state == 1">已通过</p>
                        <p v-if="scope.row.state == 2">已拒绝</p>
                        <div slot="reference" class="name-wrapper" v-if="scope.row.state == 0" style="color: blue;">
                            待审核
                        </div>
                        <div slot="reference" class="name-wrapper" v-if="scope.row.state == 1" style="color: green;">
                            已通过
                        </div>
                        <div slot="reference" class="name-wrapper" v-if="scope.row.state == 2" style="color: red;">
                            已拒绝
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="回款时间" width="180" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.returntime }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.returntime }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" width="180" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>{{ scope.row.createtime }}</p>
                        <div slot="reference" class="name-wrapper">
                            {{ scope.row.createtime }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" @click="viewFJ(scope.row)">查看附件</el-button>
                    <el-button size="mini" @click="lookReasonInfo(scope.row)">查看原因</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[15, 30]" :page-size="15" layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
        </div>
        <!-- 查看原因弹框 -->
        <el-dialog title="拒绝原因" :visible.sync="lookReasoncenterDialogVisible" width="20%" center :append-to-body="true">
            <p style="text-align: center;">{{ reason }}</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="lookReasoncenterDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="lookReasoncenterDialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 查看弹出框 -->
        <el-dialog title="附件详情" :visible.sync="viewFJdialogFormVisible" append-to-body>
            <el-form :model="form">
                <el-upload action="https://crm.nanjingyunpeng.cn/api/v1/customer/uploads" list-type="picture-card"
                    :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :file-list="fileData" :headers="token">
                    <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="viewFJdialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="viewFJdialogFormVisible = false">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getReturn_log } from '../../api/Htgl/hkjl'
import { getproject, getcreator } from '../../api/common/common'
export default {
    data() {
        return {
            tableData: [],
            value1: '',
            value2: '',
            value3: '',
            input2: '',
            reason: '',
            filter: '',
            stateValue: '',
            project_type: '',
            totalCount: 0,
            selectedValue: [],
            fileData: '',
            multipleSelection: [],
            lookReasoncenterDialogVisible: false,
            viewFJdialogFormVisible: false,
            dialogImageUrl: '',
            dialogVisible: false,
            form: {

            },
            options1: [
                {
                    filter: 0,
                    name: "全部"
                },
                {
                    filter: 1,
                    name: "我负责"
                },
                {
                    filter: 2,
                    name: "我的下属负责"
                },
            ],
            options2: [
                {
                    id: 0,
                    name: '待审核'
                },
                {
                    id: 1,
                    name: '已审核'
                },
                {
                    id: 2,
                    name: '不通过'
                },
            ],
            options3: [],
            currentPage: 1
        }
    },
    mounted() {
        this.getData()
        this.getproject_typeData()
    },
    created() {
        let temp = window.localStorage.getItem("admin-token")
        this.token = {
            "token": temp
        }
        console.log(this.token)
    },
    methods: {
        // 分页
        handleSizeChange() {
            // 每页显示数量 也就是pagesize发生变化时 要重置数据，和重置页码
            this.tableData = []
            this.currentPage = 1;
            this.pageSize = 15
            this.getData()
        },
        handleCurrentChange(val) {
            // 当 页码发生变化时 重置数据
            this.tableData = []
            this.currentPage = val
            this.getData()
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 获取列表数据
        async getData() {
            let reqData = {
                page: this.currentPage,
                filter: this.filterId,
                project_type: this.project_typeID,
                state: this.stateID
            }
            let res = await getReturn_log(reqData)
            console.log(res);
            if (res.data.code == 5) {
                this.$message({
                    type: 'error',
                    message: '暂无权限访问!'
                });
                this.$router.push({ path: "/Index/kongbai" });
            }
            this.tableData = res.data.data.data
            this.totalCount = res.data.data.total
            console.log(this.reason);
        },
        // 查看原因
        lookReasonInfo(data) {
            console.log(data);
            this.lookReasoncenterDialogVisible = true
            this.reason = data.reason
        },
        // 全选
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 获取客户筛选的id
        selectFilter(id) {
            console.log(id);
            let obj = {};
            obj = this.options1.find((item) => {
                return item.filter === id; //筛选出匹配数据
            });
            console.log(obj);
            this.filterId = obj.filter
            console.log(this.filterId);
            this.getData()
        },
        // 获取客户项目类型
        async getproject_typeData() {
            let res = await getproject()
            console.log(res.data.data);
            this.options3 = res.data.data
        },
        // 项目类型id
        selectproject_type(id) {
            console.log(id);
            let obj = {};
            obj = this.options3.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            console.log(obj);
            this.project_typeID = obj.id
            console.log(this.project_typeID);
            this.getData()
        },
        // 获取状态值id
        selectStateID(id) {
            console.log(id);
            let obj = {};
            obj = this.options2.find((item) => {
                return item.id === id; //筛选出匹配数据
            });
            console.log(obj);
            this.stateID = obj.id
            console.log(this.stateID);
            this.getData()
        },
        // 查看
        viewFJ(data) {
            console.log(data);
            this.fileData = [{ url: data.file }]
            console.log(this.fileData);
            this.viewFJdialogFormVisible = true
        },
        // 搜索
        async searchBtnInfo() {
            let reqData = {
                page: this.currentPage,
                filter: this.filterId,
                project_type: this.project_typeID,
                state: this.stateID,
                search: this.input2
            }
            let res = await getReturn_log(reqData)
            if (this.input2) {
                this.tableData = res.data.data.data
                this.totalCount = res.data.data.total
            } else {
                this.getData()
            }
            this.input2 = ""
        },
        // 重置输入框
        async resetInput() {
            this.input2 = ""
            this.level = ""
            this.project_type = ""
            this.stateValue = ""
            let reqData = {
                page: this.currentPage,
                filter: this.filterId,
            }
            let res = await getReturn_log(reqData)
            console.log(res);
            this.tableData = res.data.data.data
            this.totalCount = res.data.data.total
        },
    }
}
</script>

<style lang="less" scoped>
/deep/.el-card__body {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    width: 1500px;
}

.box-card {
    display: flex;
    justify-content: space-between;
}

.el-card {
    border: none;
    border-radius: 0;
}

.el-card.is-always-shadow {
    box-shadow: none;
}

/deep/[data-v-255bf696] .el-card__body {
    display: flex;
    justify-content: space-between;

}

.card_div {
    display: flex;
    justify-content: space-around;
    text-align: right;

    p {
        padding: 0 10px;
    }
}

.block {
    text-align: right;
    line-height: 78px;
}

.card_top {
    display: flex;
    justify-content: space-between;

    p {
        margin: 0 20px;

        span {
            padding-right: 17px;
        }
    }
}

.card_bottom {
    display: flex;

    p {
        margin: 10px 25px;

        span {
            padding-right: 10px;
        }
    }
}

.name-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.el_btn {
    width: 250px;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
}
</style>