import { request } from "../../utils/request"

export function getpdlbData(data) {
    return request({
        url: '/business/order',
        method: 'post',
        data,
    })
}
// 修改用户
export function editInfo(data) {
    return request({
        url: '/operate/edit',
        method: 'post',
        data,
    })
}
// 查看记录
export function lookData(data) {
    return request({
        url: '/operate/appoint_log',
        method: 'post',
        data,
    })
}
// 删除
export function delData(data) {
    return request({
        url: '/business/order_del',
        method: 'post',
        data,
    })
}