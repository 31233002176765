// 属性配置设置
// let attr = {
//   id: 'id',
//   parendId: 'parendId',
//   name: 'name',
//   rootId: 1
// };
function toTreeData(data, attr) {
    // console.log(data, attr)
    let tree = [];
    let resData = data;
    for (let i = 0; i < resData.length; i++) {
        if (resData[i].parent === attr.rootId) {
            let obj = {
                id: resData[i][attr.id],
                text: resData[i][attr.text],
                parent: resData[i][attr.parent],
                state: resData[i][attr.state],
                children: [],
            };
            tree.push(obj);
            resData.splice(i, 1);
            i--;
        }
    }
    var run = function (treeArrs) {
        if (resData.length > 0) {
            for (let i = 0; i < treeArrs.length; i++) {
                for (let j = 0; j < resData.length; j++) {
                    if (treeArrs[i].id === resData[j][attr.parent]) {
                        let obj = {
                            id: resData[j][attr.id],
                            text: resData[j][attr.text],
                            parent: resData[j][attr.parent],
                            state: resData[j][attr.state],
                            children: [],
                        };
                        treeArrs[i].children.push(obj);
                        resData.splice(j, 1);
                        j--;
                    }
                }
                run(treeArrs[i].children);
            }
        }
    };
    run(tree);
    return tree;
}
// let arr = toTreeData(allDatas, attr);
export { toTreeData };
