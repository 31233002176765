import { request } from "../../utils/request"
// 获取项目类型
export function getproject(data) {
    return request({
        url: '/common/project',
        method: 'post',
        data,
    })
}
// 获取创建人
export function getcreator(data) {
    return request({
        url: '/operate/operateList',
        method: 'post',
        data,
    })
}
// 获取员工列表
export function getStaff(data) {
    return request({
        url: '/common/getStaff',
        method: 'post',
        data,
    })
}
//
export function getswpo(data) {
    return request({
        url: '/business/getswpo',
        method: 'post',
        data,
    })
}