import { request } from "../../utils/request"
// 地区部门数据列表
export function delData(data) {
    return request({
        url: '/business/del',
        method: 'post',
        data,
    })
}
// 获取商务列表
export function getstaffList(data) {
    return request({
        url: '/business/staffList',
        method: 'post',
        data,
    })
}
// 获取超级列表
export function order_chaoshi(data) {
    return request({
        url: '/business/order_chaoshi',
        method: 'post',
        data,
    })
}