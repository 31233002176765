import { request } from "../../utils/request"
// 获取列表数据
export function getData(data) {
    return request({
        url: '/business/sort',
        method: 'post',
        data,
    })
}
// 接单顺序管理
export function dragSort(data) {
    return request({
        url: '/business/dragSort',
        method: 'post',
        data,
    })
}
// 接单开关
export function switchChange(data) {
    return request({
        url: '/business/admin_switch',
        method: 'post',
        data,
    })
}