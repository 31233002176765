import { request } from "../../utils/request"
// 地区部门数据列表
export function getAreaPersonInfo(data) {
    return request({
        url: '/admin/list',
        method: 'post',
        data,
    })
}
// 搜索功能
export function searchBtn(data) {
    return request({
        url: 'persons/searchPersonInfo',
        method: 'post',
        data,
    })
}
// 添加人员信息
export function addPerson(data) {
    return request({
        url: '/admin/add',
        method: 'post',
        data,
    })
}
// 获取角色权限
export function getRole(data) {
    return request({
        url: '/group/index',
        method: 'post',
        data,
    })
}
// 获取岗位列表
export function getJob(data) {
    return request({
        url: 'persons/getJobInfoList',
        method: 'post',
        data,
    })
}
//编辑人员
export function editPerson(data) {
    return request({
        url: '/admin/edit',
        method: 'post',
        data,
    })
}
// 删除人员
export function delData(data) {
    return request({
        url: '/admin/del',
        method: 'post',
        data,
    })
}