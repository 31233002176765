<template>
	<div>
		<div class="top_box">
			<div class="left flex">
				<div class="title_box flex_c">
					<img src="https://crm.nanjingyunpeng.cn/uploads/20230802/7a7f793fa695290bb3867a68076dd8f3.jpg"
						alt="">
				</div>
				<!-- <div >后台管理系统</div> -->
				<div class="iconfont iconqiehuanzhanghao flex_c" @click="zoomLeft"></div>
			</div>
			<div class="right flex" ref="pageRight" @mousewheel="ONMouseWheel($event)">
				<div style="display: flex;">
					<div v-for="(item, index) in navData" :key="index" @click="jumpNav(item)" class="nav_item"
						:class="[pageSelect[0] === item.index[0] && pageSelect[1] === item.index[1] ? 'nav_item_select' : '']">
						<div class="circle"
							:class="[pageSelect[0] === item.index[0] && pageSelect[1] === item.index[1] ? 'show' : '']">
						</div>
						<span>{{ item.name }}</span>
						<div class="iconfont iconcancel-1-copy" @click.stop="removeNav(index)"
							:class="[pageSelect[0] === item.index[0] && pageSelect[1] === item.index[1] ? 'hide' : '']">
						</div>
					</div>
				</div>
				<div class="goout">
					<el-dropdown>
						<span class="el-dropdown-link" style="cursor: pointer;">
							{{ name }}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown" class="header-new-drop">
							<el-dropdown-item @click.native="setPasswordDialogShow=true">修改密码</el-dropdown-item>
							<el-dropdown-item @click.native="goOut()">退出</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>

		</div>
		<div class="main_box">
			<div class="left_box" :class="[isLeftHide == true ? 'left_box_hide' : '']"
				:style="{ 'height': screenHeight + 'px' }">
				<div class="left_item" v-for="(item, index) in leftItmeList" :key="index"
					@click="selectItem(item, index)"
					:style="[item.id == pageIndex && isOpen == true ? { height: ((item.child.length + 1) * 51) + 'px' } : {}]">
					<!-- :class="[item.id == pageIndex ? 'left_item_select' : '']" -->
					<!-- <div class="iconfont icon-wenjian"></div> -->
					<div class="title">
						<div class="iconfont iconicon_fabu"></div>
						<!-- <img
              src="https://img2.baidu.com/it/u=3202947311,1179654885&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1679245200&t=e3110fd1400e46110314953a84b346d7"
              alt=""> -->
						{{ item.item }}
						<div class="iconfont iconjiantou"
							:class="[item.id == pageIndex && isOpen == true ? 'jt_select' : '']"></div>
					</div>
					<div class="left_item2 flex_c" v-for="(item2, index2) in item.child" :key="index2"
						@click.stop="selectItem2(item.id, item2.id)"
						:class="[item2.id == pageIndex2 ? 'left_item_select2' : '']">
						<!-- <div class="iconfont icon-wenjian"></div> -->
						{{ item2.name }}
					</div>
				</div>
			</div>
			<!-- <div v-for="(item2,index2) in leftItmeList[item.id-1].child" :key="index2">{{item2.name}}</div> -->
			<!-- right_box_open -->
			<div class="right_box" style="overflow: auto;" :class="[isLeftHide == true ? 'right_box_open' : '']"
				:style="{ 'width': screenWidth - 200 + 'px', 'height': screenHeight + 'px' }">
				<router-view />
			</div>
		</div>
		<el-dialog title="修改密码" :visible.sync="setPasswordDialogShow" width="450px" center append-to-body>
			<el-form :model="form">
				<el-form-item label="请输入旧密码" label-width="200">
					<el-input v-model="form.old_pass" placeholder="请输入旧密码" type="password" clearable show-password></el-input>
				</el-form-item>
				<el-form-item label="请输入新密码" label-width="200">
					<el-input v-model="form.new_pass" placeholder="请输入新密码" type="password" clearable show-password></el-input>
				</el-form-item>
				<el-form-item label="请再次输入新密码" label-width="200">
					<el-input v-model="form.new_pass_ok" placeholder="请再次输入新密码" clearable type="password" show-password></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setPasswordDialogShow = false">取 消</el-button>
				<el-button type="primary" @click="setPasswordAction">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import '../plugins/flexible'
	import { updatepass } from '../api/login';
	export default {
		components: {},
		data() {
			return {
				formLabelWidth: '200px'
			}
		},
		created() {
			// let index = window.sessionStorage.getItem("tabIndex");
			// if (index) {
			//   this.pageIndex = index
			// } else {

			// }
			this.navData = JSON.parse(localStorage.getItem('navData')) || []
			this.pageSelect = JSON.parse(localStorage.getItem('pageSelect')) || [0, 0]

			this.pageIndex = this.pageSelect[0];
			this.pageIndex2 = this.pageSelect[1];
			this.setTabNav([0, 0]);
			this.jumpPage()
			// this.getMenuList();
			this.name = localStorage.getItem('user-name')
		},
		watch: {
			screenWidth(val) {
				this.screenWidth = val
			},
			screenHeight(val) {
				this.screenHeight = val
			}
		},
		mounted() {
			const that = this
			window.onresize = () => {
				return (() => {
					console.log();
					window.screenWidth = document.body.clientWidth;
					window.screenHeight = document.documentElement.clientHeight;
					that.screenWidth = window.screenWidth;
					that.screenHeight = window.screenHeight;
				})()
			}
		},
		data() {
			return {
				pageIndex: 0,
				pageIndex2: 0,
				pageSelect: [0, 0],
				pageTemp: null,
				screenWidth: document.body.clientWidth,
				screenHeight: document.documentElement.clientHeight,
				isLeftHide: false,
				navData: [],
				isOpen: true,
				openIndex: 0,
				name: '',
				setPasswordDialogShow:false,
				form:{
					old_pass:"",
					new_pass:"",
					new_pass_ok:"",
				},
				
				// 仪表盘- 仪表盘 
				// 人员管理 -部门人员列表
				// 运营管理-运营看板 待派客户 派单列表 月目标
				// 商务管理- 接单列表 接单超时记录 接单顺序管理 月目标
				// 客户管理 客户列表 公海客户
				// 合同管理 -合同列表 回款记录 回款审核
				// 权限管理- 角色权限
				leftItmeList: [{
						item: "仪表盘",
						id: 0,
						child: [{
							name: "仪表盘",
							id: 0
						}]
					},
					{
						item: "人员管理",
						id: 1,
						child: [{
							name: "部门人员列表",
							id: 0
						}, ]
					},
					{
						item: "运营管理",
						id: 2,
						child: [{
								name: "运营看板",
								id: 0
							},
							{
								name: "待派客户",
								id: 1
							},
							{
								name: "派单列表",
								id: 2
							},
							{
								name: "月目标",
								id: 3
							}
						]
					},
					{
						item: "商务管理",
						id: 3,
						child: [{
								name: "接单列表",
								id: 0
							},
							{
								name: "接单超时记录",
								id: 1
							},
							{
								name: "接单顺序管理",
								id: 2
							},
							{
								name: "月目标",
								id: 3
							},
						]
					},
					{
						item: "客户管理",
						id: 4,
						child: [{
								name: "客户列表",
								id: 0
							},
							{
								name: "公海客户",
								id: 1
							},
							{
								name: "自拓客户",
								id: 2
							}
						]
					},
					{
						item: "合同管理",
						id: 5,
						child: [{
								name: "合同列表",
								id: 0
							},
							{
								name: "回款审核",
								id: 1
							},
							{
								name: "回款记录",
								id: 2
							},
						]
					},
					{
						item: "权限管理",
						id: 6,
						child: [{
							name: "角色权限",
							id: 0
						}]
					},
				]
			};
		},
		methods: {
			jumpPage() {

				switch (this.pageSelect[0]) {
					case 0:
						switch (this.pageSelect[1]) {
							case 0:
								this.$router.push({
									path: "YBP"
								});
								break;
							default:
								break;
						}
						// /Index/BumenGuanLi
						break;
					case 1:
						switch (this.pageSelect[1]) {
							case 0:
								this.$router.push({
									path: "/Index/BumenGuanLi"
								});
								break;
							default:
								break;
						}
						break;
					case 2:
						switch (this.pageSelect[1]) {
							case 0:
								this.$router.push({
									path: "/Index/Yykb"
								});
								break;
							case 1:
								this.$router.push({
									path: "/Index/Dpkh"
								});
								break;
							case 2:
								this.$router.push({
									path: "/Index/Pdlb"
								});
								break;
							case 3:
								this.$router.push({
									path: "/Index/Ymb"
								});
								break;
							default:
								break;
						}
						break;
					case 3:
						switch (this.pageSelect[1]) {
							case 0:
								this.$router.push({
									path: "/Index/Jdlb"
								});
								break;
							case 1:
								this.$router.push({
									path: "/Index/Jdcsjl"
								});
								break;
							case 2:
								this.$router.push({
									path: "/Index/Jdsxgl"
								});
								break;
							case 3:
								this.$router.push({
									path: "/Index/Mmb"
								});
								break;
							default:
								break;
						}
						break;
					case 4:
						switch (this.pageSelect[1]) {
							case 0:
								this.$router.push({
									path: "/Index/Khlb"
								});
								break;
							case 1:
								this.$router.push({
									path: "/Index/Ghkh"
								});
								break;
							case 2:
								this.$router.push({
									path: "/Index/ztkh"
								});
								break;
							default:
								break;
						}
						break;
					case 5:
						switch (this.pageSelect[1]) {
							case 0:
								this.$router.push({
									path: "/Index/Htlb"
								});
								break;
							case 1:
								this.$router.push({
									path: "/Index/Hksh"
								});
								break;
							case 2:
								this.$router.push({
									path: "/Index/Hkjl"
								});
								break;
							default:
								break;
						}
						break;
					case 6:
						switch (this.pageSelect[1]) {
							case 0:
								this.$router.push({
									path: "/Index/Jsqx"
								});
								break;
							default:
								break;
						}
						break;

					default:
						break;
				}
			},
			jumpNav(val) {
				console.log(val)

				if (this.pageSelect[0] == val.index[0] && this.pageSelect[1] == val.index[1]) {

				} else {
					this.pageSelect[0] = val.index[0]
					this.pageSelect[1] = val.index[1]
					localStorage.setItem('pageSelect', JSON.stringify(this.pageSelect))

					this.pageIndex = val.index[0]
					this.pageIndex2 = val.index[1]
					this.jumpPage()
				}
			},
			removeNav(index) {
				this.navData.splice(index, 1)
				localStorage.setItem('navData', JSON.stringify(this.navData))
			},
			setTabNav(data) {
				let temp = {
					index: [data[0], data[1]],
					name: this.leftItmeList[data[0]].child[data[1]].name
				}
				for (let i = 0; i < this.navData.length; i++) {
					if (temp.index[0] == this.navData[i].index[0] && temp.index[1] == this.navData[i].index[1]) {
						return
					}

				}
				this.navData.push(temp)
				localStorage.setItem('navData', JSON.stringify(this.navData))
			},
			zoomLeft() {
				this.isLeftHide = this.isLeftHide == false ? true : false;
			},
			selectItem(item, index) {
				this.pageIndex = item.id
				console.log(index);

				if (this.pageIndex == this.pageSelect[0]) {
					this.pageIndex2 = this.pageTemp
				} else {
					this.pageIndex2 = null
				}
				console.log(this.pageSelect[0]);
				console.log(item.id);
				if (item.id == this.openIndex) {
					this.isOpen = this.isOpen == true ? false : true
				} else {
					this.openIndex = item.id
					this.isOpen = true
				}
			},
			selectItem2(index1, index2) {
				this.pageSelect[0] = index1;
				this.pageSelect[1] = index2;
				localStorage.setItem('pageSelect', JSON.stringify(this.pageSelect))
				this.pageIndex2 = index2
				this.pageTemp = index2
				this.setTabNav(this.pageSelect)
				this.jumpPage()

				//顶部的面包屑是个数组，点击侧边栏的时候，（顶部的面包屑没有的情况下）往数组尾部添加一个，
				// 当顶部的面包屑大于十个的时候，吧第一个给shift掉 就是吧数组从前面删掉一个 就是怕积攒多了 ui崩
				//然后啊 如果用户的电脑屏幕小，我还提供了滚动条 而且还添加的鼠标滚轮事件  这样 ui不会崩
				if (this.navData.length > 10) {
					this.navData.shift()
					localStorage.setItem('navData', JSON.stringify(this.navData))
				}
			},

			// 退出登录
			goOut() {
				localStorage.clear()
				this.$router.push({
					path: "/"
				});
			},
			//鼠标滚轮 事件
			ONMouseWheel(e) {
				let eventDelta = -e.wheelDelta || -e.deltaY * 40;
				let outsideWrap = this.$refs.pageRight;
				outsideWrap.scrollLeft = outsideWrap.scrollLeft + eventDelta / 2;
			},
			//设置密码
			async setPasswordAction(){
				if (this.form.old_pass == "") {
					this.$message({
					    type: 'error',
					    message: '请输入旧密码!'
					});
					return
				}
				if (this.form.new_pass == "") {
					this.$message({
					    type: 'error',
					    message: '请输入新密码!'
					});
					return
				}
				if (this.form.new_pass != this.form.new_pass_ok) {
					this.$message({
					    type: 'error',
					    message: '两次密码输入不一致!'
					});
					return
				}
				let res = await updatepass({old_pass:this.form.old_pass,new_pass:this.form.new_pass})
				if (res.data.code == 1) {
				    this.$message({
				        type: 'success',
				        message: '修改成功!'
				    });
					this.setPasswordDialogShow=false
				}else{
					this.$message({
					    type: 'error',
					    message: res.data.msg
					});
				}
			}
		}
	};
</script>
<style scoped>
	page {
		position: relative;
	}

	.top_box {
		width: 100%;
		height: 50px;
		background-color: #fff;
		position: fixed;
		top: 0;
		left: 0;
		display: flex;
		justify-content: space-between;
		z-index: 1;
		box-shadow: 0px 1px 7px 0px rgba(179, 179, 179, 0.75);
		-webkit-box-shadow: 0px 1px 7px 0px rgba(179, 179, 179, 0.75);
		-moz-box-shadow: 0px 1px 7px 0px rgba(179, 179, 179, 0.75);
	}

	.top_box .right {
		flex: 1;
		/* flex-direction: row; */
		/* direction: rtl; */
		overflow-x: auto;
		overflow-y: hidden;
		/* justify-content: flex-start; */
		display: flex;
		justify-content: space-between;
	}

	.nav_item {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		padding: 0 10px;
		border-left: 1px solid rgb(244, 244, 244);
		border-right: 1px solid rgb(244, 244, 244);
		cursor: pointer;
		flex-shrink: 0;
		position: sticky;
		right: 0;
		background-color: #fff;
	}

	.nav_item span {
		/* 我还给了个属性 文本不换行 屏幕宽度再压缩按钮不会变形 */

		white-space: normal;
	}

	.top_box .right::-webkit-scrollbar {
		width: 1px;
		height: 4px;
		background: transparent;
	}

	.top_box .right::-webkit-scrollbar-thumb {
		background: #42b983;
		border-radius: 1px;
	}

	.top_box .right {
		/*三角箭头的颜色*/
		scrollbar-arrow-color: #fff;
		/*滚动条滑块按钮的颜色*/
		scrollbar-face-color: #42b983;
		/*滚动条整体颜色*/
		scrollbar-highlight-color: #42b983;
		/*滚动条阴影*/
		scrollbar-shadow-color: #42b983;
		/*滚动条轨道颜色*/
		scrollbar-track-color: #0066ff;
		/*滚动条3d亮色阴影边框的外观颜色——左边和上边的阴影色*/
		scrollbar-3dlight-color: #42b983;
		/*滚动条3d暗色阴影边框的外观颜色——右边和下边的阴影色*/
		scrollbar-darkshadow-color: #42b983;
		/*滚动条基准颜色*/
		scrollbar-base-color: #42b983;
	}

	.top_box .title_box {
		width: 200px;
		height: 50px;
		background-color: #fff;
		color: #ffffff;
		font-size: 18px;
		font-weight: 700;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.top_box .title_box img {
		width: 112px;
		height: 32px;
		margin-top: -3px;
		margin-left: -9px;
	}

	.iconqiehuanzhanghao {
		color: rgb(122, 122, 122);
		font-size: 16px;
		box-sizing: border-box;
		padding: 10px 20px;
		box-shadow: 1px 0px 6px 0px rgba(200, 200, 200, 0.75);
		-webkit-box-shadow: 1px 0px 6px 0px rgba(200, 200, 200, 0.75);
		-moz-box-shadow: 1px 0px 6px 0px rgba(200, 200, 200, 0.75);
		cursor: pointer;
		background-color: #fff;
	}

	.main_box {
		width: 100%;
		display: flex;
		position: fixed;
		left: 0;
		top: 50px;
		background-color: #eeeeee;
	}

	.goout {
		/* width: 400px; */
		display: flex;
		align-items: center;
		margin-right: 15px;
		width: 70px;
	}

	.left_box {
		width: 200px;
		height: 1000px;
		background-color: #304156;
		transition: all 0.2s linear;
		flex-shrink: 0;
		position: relative;
		left: 0;
	}

	.left_box_hide {
		/* position: absolute; */
		left: -200px;
	}

	.left_item {
		height: 51px;
		overflow: hidden;
		color: #ffffff;
		font-size: 14px;
		box-sizing: border-box;
		transition: all 0.25s linear;
		/* padding: 16px 0; */
		/* border-bottom: 1px solid #fff; */
		cursor: pointer;
		/* background-color: #545c64; */
	}

	.left_item .title {
		padding: 16px 0;
		text-align: center;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding-left: 30px;
		position: relative;
		/* justify-content: center; */
	}

	.left_item2 {
		padding: 16px 0;
		text-align: center;
		background-color: #1e2c3c;
		color: #e5bb49;
	}

	.left_item_select {
		height: auto;
		/* transition:  宽高形变的时候 必须得知道具体数值; */
	}


	.right_box {
		width: 1920px;
		height: 2000px;
		flex-shrink: 0;
		background-color: #eeeeee;
		transition: all 0.2s linear;
		position: absolute;
		left: 200px;
		padding: 10px;
		box-sizing: border-box;
	}

	.right_box_open {
		width: 100% !important;
		left: 0px;
	}

	.title_item {
		color: #ffffff;
		padding: 0 15px;
		-moz-box-shadow: 2px -3px 3px #1871c4;
		-webkit-box-shadow: 2px -3px 3px #1871c4;
		box-shadow: 2px -3px 3px #1871c4;
		cursor: pointer;
	}

	.iconicon_fabu {
		margin-right: 10px;
	}

	.iconjiantou {
		font-size: 12px;
		/* margin-top: 2px; */
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translate(0, -48%) rotate(-90deg);
	}

	.jt_select {
		transform: translate(0, -15%) rotate(90deg);
		transition: all 0.1s linear;
		margin-top: -4px;
	}

	.left_item_select2 {
		background-color: #000;
		color: #e5bb49;
	}

	.nav_item .circle {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: #fff;
		margin-top: 1px;
		margin-right: 6px;
	}

	.iconcancel-1-copy {
		font-size: 10px;
		margin-top: 2px;
		margin-left: 8px;
	}

	.nav_item_select {
		background-color: #42b983;
		color: #fff;
	}

	.show {
		display: block;
	}

	.hide {
		display: none;
	}

	.header-new-drop {
		display: flex;
		flex-direction: column;
		text-align: center;
		justify-content: center;
		align-items: center;
	}
</style>